import {
    GET_SINGLE_ARTIST_FAILURE,
    GET_SINGLE_ARTIST_LOADING,
    GET_SINGLE_ARTIST_REQUEST,
    GET_SINGLE_ARTIST_SUCCESS,
    RESET_SINGLE_ARTIST,
  } from '../actions/actionTypes';
  
  const intialState = {
    isError: false,
    isLoading: false,
    details: [],
    error: undefined,
  };
  
  export const singleArtistReducer = (state = intialState, action) => {
    switch (action.type) {
      case GET_SINGLE_ARTIST_LOADING:
        return {
          ...state,
          isLoading: true,
          isError: false,
          error: undefined,
        };
      case GET_SINGLE_ARTIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          details: action.payload,
          error: undefined,
        };
      case GET_SINGLE_ARTIST_FAILURE: {
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };
      }
      case RESET_SINGLE_ARTIST: {
        return {
          ...state,
          isLoading: false,
          isError: false,
          details: [],
          error: undefined,
        };
      }
      default:
        return state;
    }
  };
  