import React, { useState } from "react";
import "./ParallaxPlan.css";
import "../../../styles/Fonts.scss";
import plans from "./ParallaxPlan.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg"; // Import the checkbox icon

import SMLToggleButton from "../../Shared/SMLToggleButton/SMLToggleButton";
import bestValue from "../../../assets/Images/bestValue.svg";

const ParallaxPlan = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleCardClick = (index) => {
        setSelectedPlan(index);
    };
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="parallaxPlan-container">
            <div className="parallaxPlan-main">
                <div className="mains-container">
                    <h1 className="heading pricing-title">We've got the right plan for you.</h1>
                    <div className="toggle-buttons" onClick={stopPropagation}>
                        <label className="title2">Monthly</label>
                        <SMLToggleButton value=" " />
                        <label className="title2">Yearly</label>
                        <span className="title2">Save 20% over monthly</span>
                    </div>
                    <div className="plan-cards-container">
                        {plans.map((plan, index) => (
                            <div
                                key={index}
                                className={`plan-card ${selectedPlan === index ? "selected-card" : ""}`}
                                onClick={() => handleCardClick(index)}
                            >
                                {plan.bestValue && (
                                    <img src={bestValue} alt="Best Value" className="best-value-image" />
                                )}
                                <h2 className="title1">{plan.name}</h2>
                                <div className="price-container">
                                    {plan.oldPrice && <p className="plan-old-price">{plan.oldPrice}</p>}
                                    <p className="plan-price">{plan.price}</p>
                                </div>
                                <div className="horizontal-line"></div>
                                <ul>
                                    {plan.features.map((feature, i) => (
                                        <li key={i} className="features-item">
                                            <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                                            <span className="feature-text">{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                <button
                                    className={`plan-button plan-button-${index}`}
                                    disabled={selectedPlan !== index}
                                >
                                    {plan.button}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParallaxPlan;
