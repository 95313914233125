import React, { useState } from "react";
import Styles from "./Header.module.css";
import search from "../../../assets/Images/Search.svg";
import notification from "../../../assets/Images/notifications.svg";
import upload from "../../../assets/Images/Upload.svg";
import dropdDown from "../../../assets/Images/dropDown.svg";
import ProfileDropdown from "./ProfileDropdown";
import UploadTrack from "../../Shared/UploadTrack/UploadTrack";
import { useDispatch } from "react-redux";
import { searchRequest } from "../../../redux/actions/searchActions";

export default function Header({ username, status }) {
  const [searchText, setSearchText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Only dispatch searchRequest if the input is not an empty string
    // if (value.trim() !== '') {
    setTypingTimeout(
      setTimeout(() => {
        dispatch(searchRequest(value, status));
      }, 1000)
    );
    // }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.leftContainer}>
        <input
          onChange={handleInputChange}
          value={searchText}
          placeholder="Search"
        />
        <img
          onClick={() => {
            if (searchText.trim() !== "") {
              dispatch(searchRequest(searchText));
            }
          }}
          src={search}
          alt="Search"
        />
      </div>
      <div className={Styles.rightContainer}>
        <button
          onClick={togglePopup}
          style={{ backgroundColor: "transparent" }}
        >
          <img src={upload} alt="Cloud Arrow Up" />
        </button>
        {isPopupOpen && <UploadTrack onPopupClose={togglePopup} />}
        <button style={{ backgroundColor: "transparent" }}>
          <img src={notification} alt="Bell" />
        </button>
        <ProfileDropdown name={username} />
      </div>
    </div>
  );
}
