import React from "react";
import Styles from "./StyledComponent.module.css";

export default function SMLTextWithLabel({ label, text, textImg }) {
  return (
    <div className={Styles.textWithLabel}>
      <h6>{label}</h6>
      <h4>
        {text}
        {textImg && <img src={textImg} alt={text}></img>}
      </h4>
    </div>
  );
}
