import { call, put, takeLatest } from "redux-saga/effects";
import {
  SEARCH_REQUEST,
  SEARCH_LOADING,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from "../actions/actionTypes";
import { searchApi } from "../../api/search/searchApi";

function* getSearch(query) {
  try {
    yield put({ type: SEARCH_LOADING });
    console.log("call hogi", query);
    const result = yield call (searchApi, query.payload.query, query.payload.status);
    yield put({ type: SEARCH_SUCCESS, result: result });
  } catch (error) {
    yield put({ type: SEARCH_FAILURE, error: error });
  }
}

function* searchSaga() {
  yield takeLatest(SEARCH_REQUEST, getSearch);
}
export default searchSaga;
