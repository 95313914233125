import React from "react";
import "./aboutTrackMusicCredits.css";
import "../../../styles/Fonts.scss";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import Backbutton from "../../Shared/BackButton/BackButton";

const AboutTrackStatus = ({
  onNextBtn,
  onBackBtn,
  onClose,
  track,
  fromArtistPortal,
}) => {
  return (
    <div className="aboutTrack">
      <div className="aboutTrack-Frame">
        <div className="aboutTrackHeadbar">
          {!fromArtistPortal && (
            <div className="aboutTrack-header Mainheader">About Track</div>
          )}
          {fromArtistPortal && (
            <div className="aboutTrack-header Mainheader">
              Track Submission
              <p>
                This is the information that our music supervisors can see when
                viewing your submission.
              </p>
            </div>
          )}
          <div className="aboutTrack-close-div" onClick={onClose}>
            <img src={closeIcon} alt="Close" />
          </div>
        </div>
        <div className="aboutTrack-rectangle">
          <div className="aboutTrack-content">
            <div className="aboutTrack-left">
              <div className="aboutTrack-input">
                <div className="title2">Track Status</div>
                <div className="body1">{track?.status?.label || "-"}</div>
              </div>
              <div className="aboutTrackStatus-input">
                <div className="title2">
                  Has the track been synced or pitched for sync before?
                </div>
                <div className="body1">
                  {(track?.synced_before ? "Yes" : "No") || "-"}
                </div>
              </div>
              <div className="aboutTrackStatus-input">
                <div className="title2">
                  Are there any samples used or purchased in this track?
                </div>
                <div className="body1">
                  {(track?.samples_used ? "Yes" : "No") || "-"}
                </div>
              </div>
            </div>
            <div className="aboutTrack-divider"></div>
            <div className="aboutTrack-right">
              <div className="aboutTrack-input">
                <div className="title2">Sample Sources</div>
                {track?.sample_sources ? (
                  track?.sample_sources.map((sample, index) => (
                    <div key={index} className="body1">
                      {sample || "-"}
                    </div>
                  ))
                ) : (
                  <>"-"</>
                )}
              </div>
              <div className="aboutTrack-input">
                <div className="title2">Feedback Needed</div>
                <div className="d-flex gap-1">
                  {track?.feedback_services.map((service, index) => (
                    <div key={index} className="body1">
                      {(service.label === "Unknown"
                        ? service.code
                        : service.label) || "-"}
                      ,
                    </div>
                  ))}
                </div>
              </div>
              <div className="aboutTrack-input">
                <div className="title2">
                  Targets for track placement opportunities
                </div>
                <div className="d-flex gap-1">
                  {console.log("sample", track?.feedback_services)}
                  {console.log("sample", track)}
                  {track?.targets.map((target, index) => (
                    <div key={index} className="body1">
                      {(target.label === "Unknown"
                        ? target.code
                        : target.label) || "-"}
                      ,
                    </div>
                  ))}
                </div>
              </div>
              <div className="aboutTrack-input">
                <div className="title2">
                  Is there anything else you'd like us to know?
                </div>
                <div className="body1">{track?.additional_info || "-"}</div>
              </div>
            </div>
          </div>
          {/* <div className='aboutTrackFeedback-Btn' onClick={onBackBtn}>
            <Backbutton />
          </div> */}
          {/* <div className="aboutTrack-button">
            <button
              className="SMLButton2 Button1 aboutTrack-btn"
              onClick={onNextBtn}
            >
              Next Page
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutTrackStatus;
