import { combineReducers } from "redux";
import submissionsReducer from "./submissionsReducer";
import reviewsReducer from "./reviewsReducer";
import authReducer from "./authReducer";
import socialPostReducer from "./socialPostReducers"; // Ensure this matches your file name
import { singleArtistReducer } from "./artistReducers"; // Ensure this matches your file name
import { searchReducer } from "./searchReducers";
import trackStatsReducer from "./trackStatsReducer";
import uploadTrackReducer from "./uploadTrackReducer";
import smlReducer from "./smlReducers";
import { assignSupervisorReducer } from "./assignSupervisorReducer";
import {
  getFeedbackReducer,
  postFeedbackReducer,
  putFeedbackMsReducer,
  putFinalFeedbackMsReducer,
} from "./postFeedbackReducer";
import { downloadTrackReducer } from "./downloadTrackReducers";
import { supervisorReducer } from "./supervisorReducers";

const rootReducer = combineReducers({
  submissions: submissionsReducer,
  reviews: reviewsReducer,
  auth: authReducer,
  search: searchReducer,
  trackStats: trackStatsReducer,
  // socialPost: socialPostReducer,
  artist: singleArtistReducer,
  sml: smlReducer,
  assignSupervisor: assignSupervisorReducer,
  getFeedback: getFeedbackReducer,
  postFeedback: postFeedbackReducer,
  putFeedbackMs: putFeedbackMsReducer,
  putFinalFeedbackMs: putFinalFeedbackMsReducer,
  downloadTrack: downloadTrackReducer,
  supervisor: supervisorReducer,
});

export default rootReducer;
