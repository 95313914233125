import React from "react";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "../SMLToggleButton/SMLToggleButton.css";

const SMLToggleButton = (props) => {
  const offValue = props.value === "Yes" ? "No" : "Off";

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <span className="slider round"></span>
      <span className="slider-text slider-text-on">{props.value}</span>
      <span className="slider-text slider-text-off">{offValue}</span>
    </label>
  );
};

export default SMLToggleButton;