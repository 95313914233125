import React, { useCallback, useEffect, useRef, useState } from "react";
import "./parallaxHome.css";

import Navbar from "./Navbar/Navbar";
import MusicPartner from "./parallaxMusicCredits/MusicPartner";
import DynamicText from "./parallaxDynamic/parallxDynamic";
import ParallaxVideo from "./parallaxVideoPage/parallaxVideo";
import ParallaxCertified from "./parallaxGetCertified/parallaxCertified";
import UploadYourTracks from "./parallaxUploadTracks/uploadYourTracks";
import GetFeedbackExpert from "./parallaxGetFeedbackExpert/getFeedbackExpert";
import ParallaxRetain from "./parallaxRetain/ParallaxRetain";
import ParallaxPlan from "./planParallax/ParallaxPlan";
import ParallaxForm from "./parallaxForm/parallaxForm";
import ParallaxLogin from "./parallaxLogin/parallaxLogin";
import ParallaxFaq from "./parallaxFaq/parallaxFaq";

// Import the LiveChat component
import TawkChat from "../LiveChat/tawkChat";

const Home = () => {
    const [scrollHeight, setScrollHeight] = useState(window.innerHeight);
    const [currentSection, setCurrentSection] = useState(0);
    const isScrolling = useRef(true);
    const startTouchY = useRef(0);
    const startTouchX = useRef(0);

    const scrollToSection = useCallback(
        (targetSection) => {
            if (targetSection < 0 || targetSection > 10 || isScrolling.current) return;
            isScrolling.current = true;
            const t = targetSection;
            const sections = [
                { id: "navbar", translateY: t === 0 ? "0" : "-20dvh", scale: 1 },
                { id: "musicPartner", translateY: "10dvh", scale: t < 2 ? 1 : 0 },
                {
                    id: "introContainer",
                    translateY: t === 0 ? "85dvh" : t === 1 ? "0" : t === 2 ? "-5dvh" : t === 3 ? "-90dvh" : "-195dvh",
                    scale: t === 1 || t === 2 ? 1 : 0.9,
                },
                {
                    id: "dynamicText",
                    translateY: t === 0 ? "100dvh" : t === 1 ? "0dvh" : t === 2 ? "5dvh" : "-100dvh",
                    scale: 1,
                },
                {
                    id: "videoSection",
                    translateY: t < 2 ? "100dvh" : t === 2 ? "0" : "-100dvh",
                    scale: t < 2 ? 0.5 : 1,
                },
                {
                    id: "uploadTracks",
                    translateY: t < 3 ? "100dvh" : t === 3 ? "10dvh" : t === 4 ? "-67dvh" : "-160dvh",
                    scale: t === 3 ? 1 : 0.9,
                },
                {
                    id: "getFeedback",
                    translateY: t < 3 ? "160dvh" : t === 3 ? "87dvh" : t === 4 ? "10dvh" : t === 5 ? "-67dvh" : "-160dvh",
                    scale: t === 4 ? 1 : 0.9,
                },
                {
                    id: "certified",
                    translateY: t < 4 ? "160dvh" : t === 4 ? "87dvh" : t === 5 ? "10dvh" : t === 6 ? "-67dvh" : "-160dvh",
                    scale: t === 5 ? 1 : 0.9,
                },
                { id: "retain", translateY: t < 6 ? "100dvh" : t === 6 ? "0" : "-100dvh", scale: 1 },
                {
                    id: "pricing",
                    translateY: t < 7 ? "100dvh" : t === 7 ? "0" : "5dvh",
                    scale: t < 8 ? 1 : 0.7,
                },
                {
                    id: "footer",
                    translateY: t < 7 ? "200dvh" : t === 7 ? "80dvh" : `-${t - 8}00dvh`,
                    scale: t < 8 ? 0.9 : 1,
                },
            ];

            // background transform
            document.getElementById("background").style.transform = `translateY(${t < 2 ? 0 : -t * 100}dvh)`;
            // Update each section's transform
            sections.forEach(({ id, translateY, scale }) => {
                const section = document.getElementById(id);
                if (section) {
                    section.style.transform = `translateY(${translateY}) scale(${scale})`;
                }
            });
            setCurrentSection(t);
            setTimeout(() => {
                isScrolling.current = false;
            }, 1600);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setCurrentSection, scrollHeight, isScrolling]
    );

    const onNavigate = useCallback(
        (section) => {
            switch (section) {
                case "about":
                    scrollToSection(2);
                    break;
                case "howto":
                    scrollToSection(3);
                    break;
                case "pricing":
                    scrollToSection(7);
                    break;
                case "faq":
                    scrollToSection(8);
                    break;
                case "footer":
                    scrollToSection(10);
                    break;
                default:
                    break;
            }
        },
        [scrollToSection]
    );

    const handleScroll = useCallback(
        (event) => {
            let delta;
            if (event.type === "touchmove") {
                const touch = event.touches[0];
                const deltaY = startTouchY.current - touch.clientY;
                const deltaX = startTouchX.current - touch.clientX;

                if (Math.abs(deltaY) < Math.abs(deltaX)) {
                    return;
                } else {
                    delta = deltaY;
                }
            } else {
                delta = event.deltaY;
            }

            event.preventDefault();
            const direction = Math.sign(delta);
            const nextSection = currentSection + direction;

            scrollToSection(nextSection);
        },
        [scrollToSection, currentSection]
    );

    const handleTouchStart = useCallback((event) => {
        const touch = event.touches[0];
        startTouchY.current = touch.clientY;
        startTouchX.current = touch.clientX;
    }, []);

    useEffect(() => {
        window.addEventListener("wheel", handleScroll, { passive: false });
        window.addEventListener("touchmove", handleScroll, { passive: false });
        window.addEventListener("touchstart", handleTouchStart, { passive: false });

        return () => {
            window.removeEventListener("wheel", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
            window.removeEventListener("touchstart", handleTouchStart);
        };
    }, [handleScroll, handleTouchStart]);

    useEffect(() => {
        setTimeout(() => {
            isScrolling.current = false;
        }, 1200);

        const handleResize = () => {
            setScrollHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="parallax-container">
            <div style={{ position: "absolute" }}>
                <div
                    id="background"
                    className="background"
                    style={{
                        position: "relative",
                    }}
                >
                    <div
                        className="noise-image"
                        style={{
                            width: "100vw",
                            height: "120dvh",
                            overflow: "hidden",
                            position: "absolute",
                            top: "0",
                            right: "0",
                        }}
                    />
                    <div
                        style={{
                            width: "100vw",
                            height: "120dvh",
                            overflow: "hidden",
                            position: "relative",
                        }}
                    >
                        <div className="image-effect" />
                    </div>
                    <div className="landing-background" style={{ width: "100vw", height: "900dvh" }} />
                </div>
            </div>
            <div
                id="navbar"
                className="landing-section"
                style={{
                    transform: "translateY(0)",
                    height: "100dvh",
                }}
            >
                <Navbar onNavigate={onNavigate} />
            </div>
            <div id="musicPartner" className="landing-section" style={{ transform: "translateY(10dvh) scale(1)" }}>
                <MusicPartner />
            </div>
            <div id="introContainer" className="landing-section">
                <div
                    id="intro-wrapper"
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgb(20,20,20)",
                        borderRadius: "30px",
                    }}
                />
            </div>
            <div
                id="dynamicText"
                className="landing-section"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    height: "100dvh",
                    transform: "translateY(100dvh) scale(1)",
                }}
            >
                <DynamicText />
            </div>
            <div
                id="videoSection"
                className="landing-section"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    height: "100dvh",
                    transform: "translateY(100dvh) scale(0.5)",
                }}
            >
                <ParallaxVideo />
            </div>
            <div
                id="uploadTracks"
                className="landing-section"
                style={{ width: "100vw", transform: "translateY(100dvh) scale(0.9)" }}
            >
                <UploadYourTracks />
            </div>
            <div
                id="getFeedback"
                className="landing-section"
                style={{ width: "100vw", transform: "translateY(100dvh) scale(0.9)" }}
            >
                <GetFeedbackExpert />
            </div>
            <div
                id="certified"
                className="landing-section"
                style={{ width: "100vw", transform: "translateY(100dvh) scale(0.9)" }}
            >
                <ParallaxCertified />
            </div>
            <div
                id="retain"
                className="landing-section"
                style={{
                    width: "100vw",
                    height: "100dvh",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translateY(100dvh) scale(0.9)",
                }}
            >
                <ParallaxRetain />
            </div>
            <div
                id="pricing"
                className="landing-section"
                style={{
                    width: "100vw",
                    height: "100dvh",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translateY(100dvh) scale(0.9)",
                }}
            >
                <ParallaxPlan />
            </div>
            <div id="footer" className="landing-section">
                <div className="footer-wrapper">
                    <div className="footer-section">
                        <ParallaxFaq />
                    </div>
                    <div className="footer-section">
                        <ParallaxForm />
                    </div>
                    <div className="footer-section">
                        <ParallaxLogin onNavigate={onNavigate} />
                    </div>
                </div>
            </div>
            
            <TawkChat />
        </div>
    );
};

export default Home;
