import React from 'react';
import './aboutTrackMusicCredits.css';
import '../../../styles/Fonts.scss';
import { MdOutlineClose } from 'react-icons/md';
import Backbutton from '../../Shared/BackButton/BackButton';

const AboutTrackFeedBack = ({ onBackBtn, onClose, track }) => {
  return (
    <div className="aboutTrack">
      <div className="aboutTrack-Frame">
        <div className="aboutTrack-close-div" onClick={onClose}>
          <MdOutlineClose />
        </div>
        <div className="aboutTrack-rectangle">
          <div className="aboutTrack-header Mainheader">About Track</div>
          <div className="aboutTrack-content">
            <div className="aboutTrack-input">
              <div className="title2">Feedback Needed</div>
              <div className="d-flex gap-1">
                {track?.feedback_services.map((service, index) => (
                  <div key={index} className="Body">
                    {service.label},
                  </div>
                ))}
              </div>
            </div>
            <div className="aboutTrack-input">
              <div className="title2">
                Targets for track placement opportunities
              </div>
              <div className="d-flex gap-1">
                {track?.targets.map((target, index) => (
                  <div key={index} className="Body">
                    {target.label},
                  </div>
                ))}
              </div>
            </div>
            <div className="aboutTrack-input">
              <div className="title2">
                Is there anything else you’d like us to know?
              </div>
              <div className="Body">{track?.additional_info}</div>
            </div>
          </div>
          <div className="aboutTrackFeedback-Btn" onClick={onBackBtn}>
            <Backbutton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTrackFeedBack;
