import React, { useState } from "react";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import "../../../styles/Fonts.scss";
import "../../SignUpPage/SignUpPage.css";
import SMLTextBox from "../../../components/Shared/SMLTextBox/SMLTextBox";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import SMLToggleButton from "../../../components/Shared/SMLToggleButton/SMLToggleButton";
import { Button } from "@mui/material";
import SMLTextArea from "../../../components/Shared/SMLTextArea/SMLTextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useBeforeUnload, useNavigate } from "react-router-dom";
import "./Welcome.css";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import SuccessCheckMark from "../../../assets/Images/SuccessCheckmark.svg";
import { MdOutlineClose } from "react-icons/md";
import { NavbarOffcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { artistSignupRemove, artistShowLogin } from "../../../redux/actions/authActions";
import closeIcon from "../../../assets/Images/closeIcon.svg"

const Welcome = (props) => {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const handleHomeClick = () => {
    props.closePopup()
    dispatch(artistSignupRemove())
  };
  const handleLoginClick = () => {
    dispatch(artistShowLogin())
    props.closePopup()
  };

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <div className="signUp-container">
      <div className="signup-card">
        <div className="signUp-groupby">
          <div className="signUp-progress-steps">
            <ProgressSteps step="3" />
          </div>
          <div className="signUp-close-div" onClick={props.closePopup}>
          <img
              src={closeIcon}
              style={{ cursor: "pointer" }}
              alt="Close"
            />
          </div>
        </div>
        <div className="signUp-Welcome-main">
          <div className="welcome-success-checkMark">
            <span className="sml-iconbtn fontcolorblack">
              <img src={SuccessCheckMark} alt="icon" />
            </span>
          </div>
          <div className="Welcome-label-div">
            <div className="Welcome-Label-text Mainheader">
              Welcome On Board!
            </div>
            <div className="Welcome-label-track subtitle3">
              You can now log in to your account and submit your first track{" "}
            </div>
            <div className="Welcome-btn-grp">
              <SMLButton
                style="SMLButton2 SMLButton-Orange"
                type="button"
                value="Login"
                handleClickEvent={handleLoginClick}
              />
              <button
                className="button1 welcome-home-btn"
                type="button"
                onClick={handleHomeClick}
              >
                Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
