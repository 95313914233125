import React, { useEffect, useState } from 'react';
import AboutYouComponent from './AboutYou/AboutYou';
import ContactInformationComponent from './ContactInformation/ContactInformation';
import WelcomeComponent from './Welcome/Welcome';
import { Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

function SignUpPage({ onClose }) {
  const navigate=useNavigate()
  const { artistSignupData,artistSignedUp,artistSocialSignedIn } = useSelector((state) => state.auth);
  const [currentComponent, setCurrentComponent] = useState('contact');
  const [contactInfo, setContactInfo] = useState({
    fullName: '',
    email: '',
    createPassword: '',
    verifyPassword: '',
    agreeConditions: '',
    profileType: 'artist',
    // role: [],
    // experienceWithSync: '',
    // smlLeadInfo: '',
    // personalLinks: [],
    // musicExperienceInfo: '',
    // guidanceNeededInfo: '',
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [experienceWithSync, setExperienceWithSync] = useState(false);
  const [smlLeadInfo, setSmlLeadInfo] = useState('');
  const [personalLinks, setPersonalLinks] = useState([]);
  const [musicExperienceInfo, setMusicExperienceInfo] = useState('');
  const [guidanceNeededInfo, setGuidanceNeededInfo] = useState('');
  const handleRoleOptions = (roles) => {
    setRoleOptions(roles);
  };
  const handleExperienceWithSync = () => {
    setExperienceWithSync(!experienceWithSync);
  };
  const handleSmlLeadInfo = (data) => {
    setSmlLeadInfo(data);
  };
  const handlePersonalLinks = (links) => {
    setPersonalLinks(links);
  };
  const handleMusicExp = (data) => {
    setMusicExperienceInfo(data);
  };
  const handleGuidanceInfo = (data) => {
    setGuidanceNeededInfo(data);
  };
  useEffect(() => {
    console.log('music exp', personalLinks);
    console.log('guidance exp', guidanceNeededInfo);
  }, [personalLinks]);
  const handleContactInformationClick = (data) => {
    
    setContactInfo(data);
    setCurrentComponent('about');
  };

  const handleAboutYouClick = () => {
    if(artistSignedUp||artistSignupData?.is_new_user){
      setCurrentComponent('welcome');
    }else if(!(artistSignupData?.is_new_user)){
      navigate('/dashboard')
    }
  };

  const onBackClick = () => {
    setCurrentComponent('contact');
  };

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevent click event from bubbling up to parent
  };

  return (
    <Modal open={true}>
      <div onClick={handleContainerClick}>
        {currentComponent === 'contact' && (
          <ContactInformationComponent
            contactData={contactInfo}
            onContactInformationClick={handleContactInformationClick}
            closePopup={onClose}
          />
        )}
        {currentComponent === 'about' && (
          <AboutYouComponent
            roleOptions={roleOptions}
            handleRoleOptions={handleRoleOptions}
            contactInfo={contactInfo}
            onAboutClick={handleAboutYouClick}
            onBackClick={onBackClick}
            closePopup={onClose}
            experienceWithSync={experienceWithSync}
            handleExperienceWithSync={handleExperienceWithSync}
            smlLeadInfo={smlLeadInfo}
            handleSmlLeadInfo={handleSmlLeadInfo}
            personalLinks={personalLinks}
            handlePersonalLinks={handlePersonalLinks}
            musicExperienceInfo={musicExperienceInfo}
            handleMusicExp={handleMusicExp}
            guidanceNeededInfo={guidanceNeededInfo}
            handleGuidanceInfo={handleGuidanceInfo}
          />
        )}
        {currentComponent === 'welcome' && (
          <WelcomeComponent onBackClick={onBackClick} closePopup={onClose} />
        )}
      </div>
    </Modal>
  );
}

export default SignUpPage;
