import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
  GET_TRACK_REQUEST,
  POST_TRACK_REQUEST,
  UPDATE_TRACK_REQUEST,
  DELETE_TRACK_REQUEST,
  GET_SINGLE_TRACK_REQUEST,
  GET_ALL_TRACK_FETCH,
  GET_ALL_TRACK_FAILURE,
  GET_ALL_TRACK_SUCCESS,
} from '../actions/actionTypes';
import {
  getTrackSuccess,
  getTrackFailure,
  postTrackSuccess,
  postTrackFailure,
  updateTrackSuccess,
  updateTrackFailure,
  deleteTrackSuccess,
  deleteTrackFailure,
  getSingleTrackFailure,
  getSingleTrackSuccess,
  getAllTrackFailure,
  getAllTrackSuccess,
} from '../actions/uploadTrackActions';

import { postTrackApi } from '../../api/track/postTrackApi';
import { deleteTrackApi } from '../../api/track/deleteTrack';
import { getSingleTrackApi } from '../../api/track/getSingleTrack';
import { updateTrackApi } from '../../api/track/updateTrack';
import { getTrack } from '../../api/track/getTracks';
import { GET_ALL_TRACK_LOADING } from '../actions/smlActions';

function* getAllTrackSaga(action) {
  try {
    yield put({ type: GET_ALL_TRACK_LOADING });
    const trackStatus = action.payload.trackStatus;
    const type = action.payload.type;
    console.log(trackStatus, type);
    const tracks = yield call(getTrack, { trackStatus, type });
    yield put({ type: GET_ALL_TRACK_SUCCESS, tracks: tracks });
  } catch (e) {
    yield put({ type: GET_ALL_TRACK_FAILURE, error: e });
  }
}
function* getSingleTrackSaga(action) {
  try {
    const trackId = action.payload;
    const track = yield call(getSingleTrackApi, trackId);
    yield put(getSingleTrackSuccess(track));
  } catch (error) {
    yield put(getSingleTrackFailure(error.message));
  }
}

function* postTrackSaga(action) {
  try {
    const track = yield call(postTrackApi, action.payload);
    console.log('Track response:', track); // Debugging line
    yield put(postTrackSuccess(track));
  } catch (error) {
    console.error('Post track error:', error); // Debugging line
    yield put(postTrackFailure(error.message));
  }
}

function* updateTrackSaga(action) {
  try {
    const track = yield call(updateTrackApi, action.payload);
    yield put(updateTrackSuccess(track));
  } catch (error) {
    yield put(updateTrackFailure(error.message));
  }
}

function* deleteTrackSaga(action) {
  try {
    const trackId = yield call(deleteTrackApi, action.payload);
    yield put(deleteTrackSuccess(trackId));
  } catch (error) {
    yield put(deleteTrackFailure(error.message));
  }
}

export default function* watchUploadTrack() {
  yield all([
    takeLatest(GET_SINGLE_TRACK_REQUEST, getSingleTrackSaga),
    takeLatest(POST_TRACK_REQUEST, postTrackSaga),
    takeLatest(UPDATE_TRACK_REQUEST, updateTrackSaga),
    takeLatest(DELETE_TRACK_REQUEST, deleteTrackSaga),
    takeLatest(GET_ALL_TRACK_FETCH, getAllTrackSaga),
  ]);
}
