
import api from "../api";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

const isTokenExpired = () => {
  const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");
  return !tokenExpiryTime || Date.now() > tokenExpiryTime;
};

const getValidToken = async () => {
  if (isTokenExpired()) {
    throw new Error("Token expired. Please log in again.");
  }
  return localStorage.getItem("accessToken");
};

export const updateTrackApi = async ({ trackId, updatedData }) => {
  try {
    const token = await getValidToken();
    const response = await api.put(
      `${BASE_URL}/track/${trackId}`, // Use trackId in the URL for updating
      updatedData, // Send the updated data as the request body
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token here
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating track",
      error.response ? error.response.data : error.message
    );
    if (error.message.includes("Token expired")) {
      // Optionally handle token expiration (e.g., redirect to login)
    }
    throw error;
  }
};
