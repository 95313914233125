// actions.js
import {
  GET_TRACK_STATS,
  GET_TRACK_STATS_SUCCESS,
  GET_TRACK_STATS_FAILURE,
} from "./actionTypes";

//  STATS
export const getTrackStats = (artistId) => {
  return {
    type: GET_TRACK_STATS,
    payload: artistId,
  };
};

export const getTrackStatsSuccess = (stats) => ({
  type: GET_TRACK_STATS_SUCCESS,
  payload: stats,
});

export const getTrackStatsFailure = (error) => ({
  type: GET_TRACK_STATS_FAILURE,
  payload: error,
});
