import React, { useEffect, useRef, useState } from "react";
import SMLCard from "../styled-components/SMLCard";
import SMLTextWithLabel from "../styled-components/SMLTextWithLabel";
import Styles from "./TracksViewStyles.module.css";
import UnderReviewIcon from "../../../assets/Icons/under-review.svg";
import CertifiedIcon from "../../../assets/Icons/certified.svg";
import FeedbackRecievedIcon from "../../../assets/Icons/feedback-recieved.svg";
import NotSubmittedIcon from "../../../assets/Icons/not-submitted.svg";
import DefaultTrackImg from "../../../assets/Images/TrackDefaultImg.svg";
import PlayIcon from "../../../assets/Icons/play.svg";
import PauseIcon from "../../../assets/Icons/pause.svg";
import TrackSeeker from "./TrackSeeker";
import { useNavigate } from "react-router-dom";

const refreshPeriod = 10;

export default function TrackCard({ track, onPlay, isStopped }) {
  const trackLengthInMilliSeconds = 10000;
  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsedSeconds, setElaspsedSeconds] = useState(0);
  const musicTimeoutRef = useRef(null);
  const musicStoppedFromParent = useRef(false);
  const audioRef = useRef(new Audio(track.file_url));
  const navigate = useNavigate();
  const trackId = track._id;
  const status = track.status.code;
  const handleCardClick = (trackId) => {
    console.log(status, "status");
    console.log(track.status?.label, "status");
    navigate(`/TrackDetails/${trackId}`, {
      state: { status },
    });
    // navigate(`/TrackFeedback/${trackId}`, {
    //   state: { fromUserPortal: true, status },
    // });
  };
  // useEffect(() => {
  //   if (isPlaying) {
  //     audioRef.current = new Audio(track.file_url);
  //     return () => {
  //       clearInterval(musicTimeoutRef.current);
  //       if (audioRef.current) {
  //         audioRef.current.pause();
  //       }
  //     };
  //   }
  // }, [track.file_url, isPlaying]);

  // const stopPlayingAudio = () => {
  //   setIsPlaying(false);
  //   clearInterval(musicTimeoutRef.current);
  //   audioRef.current.pause();
  // };

  // const startPlayingAudio = (lengthInSeconds) => {
  //   setElaspsedSeconds(0);
  //   console.log(track.file_url);
  //   clearTimeout(musicTimeoutRef.current);
  //   audioRef.current.play();
  //   musicTimeoutRef.current = setInterval(() => {
  //     if (musicStoppedFromParent.current) {
  //       stopPlayingAudio();
  //     } else {
  //       setElaspsedSeconds((elapsedSeconds) => {
  //         let newElapsed = elapsedSeconds + refreshPeriod;
  //         if (newElapsed > lengthInSeconds) {
  //           stopPlayingAudio();
  //           return newElapsed;
  //         }
  //         return newElapsed;
  //       });
  //     }
  //   }, refreshPeriod);
  // };

  // const onPlayPause = (e) => {
  //   if (isPlaying) {
  //     stopPlayingAudio();
  //   } else {
  //     setIsPlaying(true);
  //     startPlayingAudio(trackLengthInMilliSeconds);
  //     onPlay();
  //   }
  // };

  // let progress = (elapsedSeconds / trackLengthInMilliSeconds) * 100;
  // let backgroundImg = DefaultTrackImg;

  // useEffect(() => {
  //   if (isStopped) {
  //     musicStoppedFromParent.current = true;
  //     stopPlayingAudio();
  //   } else {
  //     musicStoppedFromParent.current = false;
  //   }
  // }, [isStopped]);

  // useEffect(() => {
  //   return () => {
  //     clearInterval(musicTimeoutRef.current);
  //     audioRef.current.pause();
  //   };
  // }, []);
  return (
    <SMLCard
      className={Styles.trackCard}
      onClick={() => {
        handleCardClick(trackId);
      }}
    >
      {/* <div className={Styles.trackImage} onClick={onPlayPause}> */}
      <div className={Styles.trackImage}>
        <img
          src={DefaultTrackImg}
          alt="track imge"
          height={"100%"}
          width={"100%"}
        />
        {isPlaying ? (
          <img
            src={PauseIcon}
            alt="play"
            className={Styles.playPauseControllbutton}
          />
        ) : (
          <img
            src={PlayIcon}
            alt="pause"
            className={Styles.playPauseControllbutton}
          />
        )}
      </div>
      <div className={Styles.trackNameAndLabelContaienr}>
        <h3>{track.title}</h3>
        <SMLTextWithLabel label={"Genre"} text={track.genre} />
      </div>
      <div className={Styles.trackLengthAndStatusContainer}>
        <h4>{track.length}</h4>
        <SMLTextWithLabel
          label={"Status"}
          text={track.status?.label} //hardcoded right now needs to be changed once we get it in response from get all tracks api
          textImg={getStatusImage(track.status?.label)}
        />
      </div>
      {/* {isPlaying && <TrackSeeker progress={progress} />} */}
      
    </SMLCard>
  );
}

const getStatusImage = (status) => {
  switch (status) {
    case "Submitted":
      return UnderReviewIcon;
    case "In Review":
      return UnderReviewIcon; //clock
    case "Certified":
      return CertifiedIcon; //downloaded
    case "Final Feedback Submitted":
      return FeedbackRecievedIcon; //feedback
    case "Not Submitted":
      return NotSubmittedIcon;
    default:
      return undefined;
  }
};
