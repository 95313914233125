import React, { useState } from 'react';
import "../../styles/Fonts.scss";
import SMLButton from '../../components/Shared/SMLButton/SMLButton';
import { useNavigate } from 'react-router-dom';
import './SuccessPage.css'
import SuccessCheckMark from '../../assets/Images/SuccessCheckmark.svg';
import { supShowLogin, supSignupRemove } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';

const Success = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleHomeClick = () => {
        dispatch(supSignupRemove())
        navigate('/');
        handlecloseButton()
    };
    const handleLoginClick = () => {
        dispatch(supShowLogin())
        navigate("/");
        handlecloseButton()
    }
    const handlecloseButton = () => {
        props.onClose();
        // window.location.reload(true);
      };

    return (

        <div className='success-signUp-container'>
            <div className='success-signup-card'>

                <div className="success-signUp-Welcome-main">
                    <div className='success-welcome-success-checkMark'>
                        <span className='sml-iconbtn fontcolorblack'>
                            <img src={SuccessCheckMark} alt="icon" />
                        </span>
                    </div>
                    <div className='success-Welcome-label-div'>
                        <div className='success-Welcome-Label-text Mainheader'>You Have Successfully Signed Up</div>
                        <div className='success-Welcome-label-track subtitle3'>You can now log in to your account and submit your first track </div>
                        <div className='success-Welcome-btn-grp'>
                            <SMLButton style="SMLButton2 SMLButton-Orange" type="button" value="Login" handleClickEvent={handleLoginClick} />
                            <button className='button1 welcome-home-btn' type="button" onClick={handleHomeClick}>Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success;