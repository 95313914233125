import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import SMLVideoPlayer from "./components/Shared/SMLVideoPlayer/SMLVideoPlayer";
import UploadTrack from "./components/Shared/UploadTrack/UploadTrack";
import AboutTrack from "./components/UI/aboutTrack/aboutTrack";
import ArtistProfile from "./components/UI/artistProfile/artistProfile";
import ViewSubmission from "./components/UI/viewSubmission/viewSubmission";
import FeedbackForum from "./pages/Feedback/FeedbackForum";
import FeedbackReceived from "./pages/Feedback/FeedbackReceived";
import Login from "./pages/LoginPage/LoginPage";
import Logintest from "./pages/LoginPage/LoginTest";
import MyProfile from "./pages/MyProfilePage/MyProfile";
import ViewPlan from "./pages/MyProfilePage/ViewPlan/ViewPlan";
import Review from "./pages/Reviews/Reviews";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import UploadedTrackPage from "./pages/UploadedTrackPage/UploadedTrackPage";
// import Dashboard from './pages/dashboard/dashboard';
import SupervisorLogin from "./SupervisorPage/SupervisorLogin/SupervisorLogin";
//import SupervisiorPage from "./SupervisorPage/SupervisorPage";
import SupervisorDasboard from "./pages/Supervisor-Dashboard/SupDashboard";
import TrackCheck from "./pages/TrackCheck/TrackCheck";
import TrackEvaluation from "./SupervisorPage/TrackEvaluationPage/TrackEvaluation";
import TrackEvaluationResults from "./SupervisorPage/TrackEvaluationPage/TrackEvaluatedResults";
import Payment from "./pages/SignUpPage/Payment/Payment";
import SubmitTrackPopup from "./SupervisorPage/SubmitTrackPopups/SubmitTrackPopup";
import Dashboard from "./pages/dashboard/Dasboard";
import TrackUnderReview from "./pages/Reviews/TrackUnderReview";
import TrackFeedback from "./pages/Supervisor-Dashboard/TrackFeedback";
import SupervisiorPage from "./SupervisorPage/SupervisorPage";
import SignUpPlanPage from "./SignUpPlanPages/SignUpPlanPages";
import PlanPayment from "./SignUpPlanPages/PlanPayment/PlanPayment";

import ParallaxCertified from "./components/Parallax/parallaxGetCertified/parallaxCertified";
import GetFeedbackExpert from "./components/Parallax/parallaxGetFeedbackExpert/getFeedbackExpert";
import ParallaxLogin from "./components/Parallax/parallaxLogin/parallaxLogin";
import UploadYourTracks from "./components/Parallax/parallaxUploadTracks/uploadYourTracks";
import DynamicText from "./components/Parallax/parallaxDynamic/parallxDynamic";
import Home from "./components/Parallax/parallaxHome";
import TermsCondition from "./components/UI/TermsAndConditions/TermsAndCondition";
import ParallaxVideo from "./components/Parallax/parallaxVideoPage/parallaxVideo";
import ParallaxPlan from "./components/Parallax/planParallax/ParallaxPlan";
import ProtectedArtistRoutes from "./protectedRoutes/ProtectedArtistRoutes";
import ProtectedSupervisorRoutes from "./protectedRoutes/ProtectedSupervisorRoutes";
import TrackEvaluatedArtist from "./SupervisorPage/TrackEvaluationPage/TrackEvaluationArtist";
import TrackDetail from "./pages/Supervisor-Dashboard/TrackDetail";

import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import { useEffect } from "react";

function App() {

  // useEffect(() => {
  //   const handleTabClose = () => {
  //     localStorage.clear(); // Remove specific key
  //     // Or use localStorage.clear() to remove all localStorage data
  //   };
  //   window.addEventListener('beforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);
  const INACTIVITY_LIMIT = 60 * 60 * 1000; 
  
  let logoutTimer;
  const logout = () => {
    localStorage.clear();
    window.location.href="http://localhost:3000/"//base url
  };
  
  const handleVisibilityChange = () => {
    
    if (document.visibilityState === "hidden") {
      logoutTimer = setTimeout(logout, INACTIVITY_LIMIT);
    } else if (document.visibilityState === "visible") {
      clearTimeout(logoutTimer);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  
  return (
    <div>
      <Router>
        <Routes>
          {/* login routes */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/logintest" element={<Logintest />} />
          <Route exact path="/SMLVideoPlayer" element={<SMLVideoPlayer />} />
          <Route exact path="/login" element={<Login title={"login"} />} />
          <Route exact path="/signUp" element={<SignUpPage />} />

          <Route
            exact
            path="/Supervisor/Login"
            element={<SupervisorLogin title={"Supervisor Login"} />}
          />
          <Route exact path="/SupervisorPage" element={<SupervisiorPage />} />
          {/* artist protected routes */}
          <Route element={<ProtectedArtistRoutes />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/viewPlan" element={<ViewPlan />} />
            <Route exact path="/myProfile" element={<MyProfile />} />
            <Route exact path="/uploadedtrack" element={<UploadedTrackPage />} />
            <Route exact path="/review" element={<Review />} />
            <Route exact path="/feedbackreceived" element={<FeedbackReceived />} />
            <Route exact path="/TrackCheck" element={<TrackCheck />} />
            <Route exact path="/TrackUnderReview" element={<TrackUnderReview />} />
            <Route exact path="/TrackEvaluated/:trackId" element={<TrackEvaluatedArtist />} />
          </Route>


          {/* protected routes for supervisor */}
          <Route element={<ProtectedSupervisorRoutes />}>
            <Route exact path="/TrackPage/:trackId" element={<TrackFeedback />} />
            <Route exact path="/TrackEvaluationPage/:trackId" element={<TrackEvaluation />} />
            <Route exact path="/TrackEvaluatedPage/:trackId" element={<TrackEvaluationResults />} />
            <Route exact path="/supervisorDashboard" element={<SupervisorDasboard />} />
          </Route>
          
          <Route exact path="/TrackFeedback/:trackId" element={<TrackFeedback />} />
          <Route exact path="/TrackDetails/:trackId" element={<TrackDetail />} />

          {/* not protected routes which may be used for testing the component */}
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/feedbackforum" element={<FeedbackForum />} />
          <Route exact path="/uploadtrack" element={<UploadTrack />} />
          <Route exact path="/viewSubmission" element={<ViewSubmission />} />
          <Route exact path="/aboutTrack" element={<AboutTrack />} />
          <Route exact path="/artistProfile" element={<ArtistProfile />} />
          <Route
            exact
            path="/SubmitTrackPopup"
            element={<SubmitTrackPopup />}
          />
          <Route exact path="/signUpPlanPages" element={<SignUpPlanPage />} />
          <Route exact path="/PlanPayment" element={<PlanPayment />} />
          <Route exact path="/DynamicText" element={<DynamicText />} />
          <Route exact path="/ParallaxLogin" element={<ParallaxLogin />} />
          <Route
            exact
            path="/ParallaxCertified"
            element={<ParallaxCertified />}
          />
          <Route
            exact
            path="/GetFeedbackExpert"
            element={<GetFeedbackExpert />}
          />
          <Route
            exact
            path="/UploadYourTracks"
            element={<UploadYourTracks />}
          />
          <Route exact path="/ParallaxVideo" element={<ParallaxVideo />} />
          <Route exact path="/ParallaxPlan" element={<ParallaxPlan />} />

          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
