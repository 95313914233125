import {
  GET_SUPERVISOR_FAILURE,
  GET_SUPERVISOR_REQUEST,
  GET_SUPERVISOR_SUCCESS,
} from "./actionTypes";

export const getAllSupervisorRequest = () => {
  return {
    type: GET_SUPERVISOR_REQUEST,
  };
};
export const getAllSupervisorSuccess = (supervisors) => {
  return {
    type: GET_SUPERVISOR_SUCCESS,
    payload: { supervisors },
  };
};
export const getALLSupervisorFailure = (error) => {
  return {
    type: GET_SUPERVISOR_FAILURE,
    payload: error,
  };
};
