import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles'; 


const steps = [
  'Choose Plan',
  'Sign Up',
  'About You',
  'Success'
];


const CustomStepper = styled(Stepper)({
  "& .MuiStepIcon-root.Mui-active": {
    color: "#23f0c7 ", 
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#23f0c7 ", 
}});

export default function HorizontalLinearAlternativeLabelStepper(props) {
  return (
    <Box sx={{ width: '100%' }}>
      <CustomStepper activeStep={parseInt(props.step)} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </CustomStepper>
    </Box>
  );
}
