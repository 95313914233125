import store from '../store/configureStore';
import { refreshTokenRequest } from '../actions/authActions';
import {jwtDecode} from 'jwt-decode';

class TokenManager {
  constructor() {
    this.refreshTimeoutId = null;
  }

  setRefreshTimer(token) {
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId);
    }
    
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    const timeUntilExpiry = expirationTime - currentTime;
    const refreshTime = Math.max(timeUntilExpiry - 5 * 60 * 1000, 0);

    this.refreshTimeoutId = setTimeout(() => {
      store.dispatch(refreshTokenRequest());
    }, refreshTime);
  }

  clearRefreshTimer() {
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId);
      this.refreshTimeoutId = null;
    }
  }
}

export const tokenManager = new TokenManager();