import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Backbutton from "../../../components/Shared/BackButton/BackButton";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import "../../../components/Shared/SMLButton/SMLButton.css";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLOptionQuestion from "../../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLTextArea from "../../../components/Shared/SMLTextArea/SMLTextArea";
import SMLTextBox from "../../../components/Shared/SMLTextBox/SMLTextBox";
import SMLToggleButton from "../../../components/Shared/SMLToggleButton/SMLToggleButton";
import "../../../styles/Fonts.scss";
import "../../SignUpPage/SignUpPage.css";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  signupRequest,
  socialSignupRequest,
} from "../../../redux/actions/authActions";
import { Bars } from "react-loader-spinner";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import SMLErrorBox from "../../../components/Shared/SMLErrorBox/SMLErrorBox";

const AboutYou = ({
  contactInfo,
  onAboutClick,
  onBackClick,
  closePopup,
  roleOptions,
  handleRoleOptions,
  experienceWithSync,
  handleExperienceWithSync,
  smlLeadInfo,
  handleSmlLeadInfo,
  personalLinks,
  handlePersonalLinks,
  musicExperienceInfo,
  handleMusicExp,
  guidanceNeededInfo,
  handleGuidanceInfo,
}) => {
  // const [optionSelected, setoptionSelected] = React.useState(false)
  // const [optionSelected1, setoptionSelected1] = React.useState(false)
  // const [optionSelected2, setoptionSelected2] = React.useState(false)
  // const [optionSelected3, setoptionSelected3] = React.useState(false)
  const { loading, artistSocialSignedIn, error, artistSignedUp } = useSelector(
    (state) => state.auth
  );
  const options = [
    { id: "option1", label: "Vocalist", value: "option1" },
    { id: "option2", label: "Musician", value: "option2" },
    { id: "option3", label: "Songwriter", value: "option3" },
    { id: "option4", label: "Producer", value: "option4" },
    { id: "option5", label: "Composer", value: "option5" },
    { id: "option6", label: "Lyricists", value: "option6" },
  ];
  const [selectedName, setSelectedName] = useState("");
  const [expWithSync, setExpWithSync] = useState(experienceWithSync);
  const [loginError, setLoginError] = useState(false);
  const [personalLinks1, setPersonalLinks1] = useState(
    personalLinks.length > 0 ? personalLinks : [""]
  );

  // const [selectedOptions, setSelectedOptions] = useState([])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (artistSignedUp?.is_new_user === false) {
      navigate("/Dashboard");
    }
  }, [artistSignedUp]);
  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);
  useEffect(() => {
    setLoginError(false);
  }, []);
  useEffect(() => {
    if (auth.artistSignedUp && !auth.loading && !auth.error) {
      onAboutClick();
    }
  }, [auth.user, auth.loading, auth.error]);
  const handleAbout = (e) => {
    e.preventDefault();

    console.log("About you ...");

    // Combine contactInfo and additionalInfo
    if (contactInfo.credential) {
      const profile = {
        role: roleOptions,
        experience_with_sync: expWithSync,
        sml_lead_info: smlLeadInfo,
        personal_links: personalLinks1,
        music_experience_info: musicExperienceInfo,
        guidance_needed_info: guidanceNeededInfo,
      };
      console.log("contant", contactInfo.credential);
      dispatch(
        socialSignupRequest(
          contactInfo.credential,
          contactInfo.provider,
          contactInfo.profile_type,
          profile
        )
      );
    } else {
      // Dispat.ch the signup request
      dispatch(
        signupRequest(
          contactInfo.fullName,
          contactInfo.email,
          contactInfo.createPassword,
          contactInfo.profileType,
          roleOptions,
          experienceWithSync,
          smlLeadInfo,
          personalLinks,
          musicExperienceInfo,
          guidanceNeededInfo
        )
      );
    }
    // console.log(optionSelected, optionSelected1, optionSelected2)
    // Proceed to the next step
  };
  useEffect(() => {
    if (artistSocialSignedIn) {
      onAboutClick();
    }
  }, [artistSocialSignedIn]);
  const handleClickOption = (label) => {
    const newFeedbackOptions = roleOptions.includes(label)
      ? roleOptions.filter((option) => option !== label)
      : [...roleOptions, label];
    handleRoleOptions(newFeedbackOptions);
  };
  const handleExpWithSync = () => {
    setExpWithSync(!expWithSync);
    handleExperienceWithSync();
  };
  const handleSmlLead = (data) => {
    handleSmlLeadInfo(data);
  };
  const handleAddLink = () => {
    const newPersonalLinks1 = [...personalLinks1, ""];
    setPersonalLinks1(newPersonalLinks1);
    handlePersonalLinks(newPersonalLinks1);
  };

  const handleLinkChange = (index, newValue) => {
    const updatedLinks = personalLinks1.map((link, i) =>
      i === index ? newValue : link
    );
    setPersonalLinks1(updatedLinks);
    handlePersonalLinks(updatedLinks);
  };

  const handleRemoveLink = (index) => {
    if (personalLinks1.length > 1) {
      const updatedLinks = personalLinks1.filter((_, i) => i !== index);
      setPersonalLinks1(updatedLinks);
      handlePersonalLinks(updatedLinks);
    }
  };

  const handleMusicExperience = (data) => {
    handleMusicExp(data);
  };
  const handleGuidanceNeededInfo = (data) => {
    handleGuidanceInfo(data);
  };
  // const handleClickOption = (option) => {
  //   if (option === 'option') {
  //     setoptionSelected(!optionSelected)
  //   }
  //   if (option === 'option1') {
  //     setoptionSelected1(!optionSelected1)
  //   }
  //   if (option === 'option2') {
  //     setoptionSelected2(!optionSelected2)
  //   }
  //   if (option === 'option3') {
  //     setoptionSelected3(!optionSelected3)
  //   }
  // }

  const handleBackClick = () => {
    onBackClick("contact");
    //navigate('/login')
  };

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <div className="signUp-container">
      <div className="signup-card">
        {loading && (
          <div className="spinner-overlay">
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        <div className="signUp-progress-steps">
          <ProgressSteps step="2" />
        </div>
        {/* <div className='Login-close-div'>
               
                </div> */}
        {auth.loading && (
          <div className="spinner-overlay">
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        <div className="signUp-close-div" onClick={closePopup}>
          <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
        </div>

        <div className="signUp-main">
          <div className="signUp-label-div">
            <SMLLabel value="Tell Us About Yourself" style="Mainheader" />
          </div>
          <form className="signUp-grid" onSubmit={handleAbout}>
            <div className="signUp-content">
              <div className="signUp-controls">
                <div className="signUp-fields">
                  <SMLLabel value="Who are you?" style="title2"></SMLLabel>
                  <div className="signUp-options-container">
                    {options.map((option) => (
                      <div key={option.value}>
                        <SMLOptionQuestion
                          key={option.id}
                          value={option.label}
                          type="button"
                          style={
                            roleOptions.includes(option.label)
                              ? "SMLSecondaryButtonSelected"
                              : "SMLSecondaryButton"
                          }
                          handleClickEvent={() =>
                            handleClickOption(option.label)
                          } // Ensure proper handling
                        />
                      </div>
                    ))}
                  </div>
                  {/* <SMLOptionQuestion
                      type="button"
                      style={
                        optionSelected1
                          ? 'SMLSecondaryButtonSelected'
                          : 'SMLSecondaryButton'
                      }
                      handleClickEvent={() => handleClickOption('option1')}
                      value="Songwriter"
                    />
                    <SMLOptionQuestion
                      type="button"
                      style={
                        optionSelected2
                          ? 'SMLSecondaryButtonSelected'
                          : 'SMLSecondaryButton'
                      }
                      handleClickEvent={() => handleClickOption('option2')}
                      value="Producer"
                    />
                    <SMLOptionQuestion
                      type="button"
                      style={
                        optionSelected3
                          ? 'SMLSecondaryButtonSelected'
                          : 'SMLSecondaryButton'
                      }
                      handleClickEvent={() => handleClickOption('option3')}
                      value="Composer"
                    /> */}
                </div>
                <div
                  className="signUp-fields"
                  style={{ marginTop: "30px", marginBottom: "0px" }}
                >
                  <div className="signUp-groupby">
                    <SMLToggleButton
                      value="Yes"
                      checked={expWithSync}
                      onChange={handleExpWithSync}
                    />
                    <SMLLabel
                      value="Do you have experience with sync?"
                      style="title2"
                    ></SMLLabel>
                  </div>
                </div>
                <div className="signUp-fields">
                  <SMLLabel
                    value="How did you hear about Sync Music Lab?"
                    style="title2"
                  ></SMLLabel>
                  <div>
                    <div
                      className="SMLDropdownWrapper"
                      style={{ width: "80%" }}
                    >
                      <select
                        value={selectedName}
                        onChange={(e) => {
                          setSelectedName(e.target.value);
                        }}
                        className="form-control SMLDropdown"
                        style={{ width: "40%" }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="google">Google</option>
                        <option value="facebook">Facebook Group</option>
                        <option value="social_media">Social Media</option>
                        <option value="friends">Friends</option>
                        <option value="others">Others</option>
                      </select>
                      {/* <div className="custom-dropdown">
                            <option value="Movie">Movie</option>
                            <option value="TV">TV</option>
                          </div> */}
                    </div>

                    {selectedName === "others" && (
                      <SMLTextBox
                        value={smlLeadInfo}
                        onChange={(e) => {
                          handleSmlLead(e.target.value);
                        }}
                        width={"65%"}
                        left={"35%"}
                      />
                    )}
                  </div>
                </div>
                <div className="signUp-fields">
                  <SMLLabel
                    value="Add Personal Links"
                    style="title2"
                  ></SMLLabel>
                  <div className="link_scrollbar">
                    {personalLinks1.map((link, index) => (
                      <div key={index} className="feedback-groupby">
                        <SMLTextBox
                          placeHolder="Link"
                          value={link}
                          onChange={(e) =>
                            handleLinkChange(index, e.target.value)
                          }
                          imgSrc={index > 0 ? closeIcon : null} // Only show remove icon for boxes after the first one
                          onImgClick={() => handleRemoveLink(index)}
                          width={"98%"}
                          widthInput={"100%"}
                        />
                      </div>
                    ))}
                  </div>
                  <b onClick={handleAddLink} className="orange">
                    +
                  </b>
                </div>
              </div>
              <div onClick={handleBackClick}>
                <Backbutton />
              </div>
            </div>
            <div className="signUp-content">
              <div className="signUp-controls">
                <SMLLabel
                  value="Tell us about your music experience."
                  style="title2"
                ></SMLLabel>
                <SMLTextArea
                  value={musicExperienceInfo}
                  onChange={(e) => {
                    handleMusicExperience(e.target.value);
                  }}
                />
                <SMLLabel
                  value="What kind of guidance are you looking for?"
                  style="title2"
                ></SMLLabel>
                <SMLTextArea
                  value={guidanceNeededInfo}
                  onChange={(e) => {
                    handleGuidanceNeededInfo(e.target.value);
                  }}
                />
              </div>
              <div
                className="signUp-footer"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                }}
              >
                <div className="continue-btn">
                  <SMLButton
                    style="SMLButton2 SMLButton-Orange"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
            {loginError && (
              <SMLErrorBox message={error} style="signup"></SMLErrorBox>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AboutYou;
