import { jwtDecode } from 'jwt-decode';  // Correct import statement for jwt-decode
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const ProtectedArtistRoutes = ({ children }) => {
  
  const { user, artistSignupData } = useSelector((state) => state.auth)
  const isAllowed=()=>{
    const token = localStorage.getItem('token');
    const profile_type=localStorage.getItem("profile_type")
    if (token && profile_type==="artist") {
        return true
    } else if (user) {
      return true
    } else if (artistSignupData?.is_new_user) {
      return true
    }else {
      return false
    }
  }
  // useEffect(() => {
  //   // const accessToken = Cookies.get('token');
  //   const token = localStorage.getItem('token');
  //   debugger;
  //   const profile_type=localStorage.getItem("profile_type")
  //   if (token && profile_type==="artist") {
  //       setIsAllowed(true);
  //   } else if (user) {
  //     setIsAllowed(true);
  //   } else if (artistSignupData?.is_new_user) {
  //     setIsAllowed(true);
  //   }else {
  //     setIsAllowed(false);
  //   }
  // }, []);


  return isAllowed()?<Outlet />:<Navigate to="/" />
};

export default ProtectedArtistRoutes;
