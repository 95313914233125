import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../Supervisor-Dashboard/SupDashboard.css";
import "../../styles/consistent-theme.scss";
import audioSrc from "../../assets/audio/audio-test.mp3";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import TrackSection from "../../components/Shared/TrackSection/TrackSection";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTracksFetch } from "../../redux/actions/smlActions";
import SupAssignPopup from "./SupAssignPopup";
import { getTrackStats } from "../../redux/actions";
import { Bars } from "react-loader-spinner";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_GET_SINGLE_TRACK,
} from "../../redux/actions/actionTypes";

const SupDashboard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [trackId, setTrackId] = useState("");
  const isMaster = localStorage.getItem("isMaster");
  console.log("mater", isMaster);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const mediaRef = useRef(null);

  const [btnStyle, setBtnStyle] = useState("SMLToggleBtn-white");
  const [showWrapper, setShowWrapper] = useState(true);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("submitted");
  const handleButtonClick = () => {
    setBtnStyle("SMLToggleBtn-orange");
    setShowWrapper(false);
  };

  // State to manage which section to display
  const [selectedSection, setSelectedSection] = useState("Waiting"); // Default selected section
  const [labelText, setLabelText] = useState("Submitted Tracks");
  // Function to handle button click and update selected section
  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };
  const tracks = useSelector(
    (state) => state.sml.allTracksFetched?.tracks || state.uploadTrack?.tracks
  );
  const loading = useSelector((state) => state.sml.allTracksFetched.isLoading);
  console.log(loading);

  const handleShowAssignPopup = (trackID) => {
    console.log("triggering", trackID);
    setTrackId(trackID);
    setShowAssignPopup(!showAssignPopup);
  };
  const stats = useSelector((state) => state.trackStats?.statsFetched?.stats);
  useEffect(() => {
    console.log("tracks", tracks);
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    dispatch(getTrackStats("supervisor"));
    console.log("stats", stats);
    if (selectedSection === "Waiting") {
      setLabelText("Submitted Tracks");
      dispatch(getAllTracksFetch("submitted", "supervisor_id"));
    } else if (selectedSection === "Assigned") {
      setLabelText("Assigned Tracks");
      dispatch(getAllTracksFetch("Assigned", "supervisor_id"));
    } else if (selectedSection === "UnderReview") {
      setLabelText("Under Review Tracks");
      dispatch(getAllTracksFetch("in_review", "supervisor_id"));
    } else if (selectedSection === "UnderMasterReview") {
      setLabelText("Under Master Review Tracks");
      dispatch(getAllTracksFetch("feedback_submitted", "supervisor_id"));
    } else if (selectedSection === "Pending") {
      setLabelText("Finalised Tracks");
      dispatch(getAllTracksFetch("Pending", "supervisor_id"));
    } else if (selectedSection === "Reviewed") {
      setLabelText("Reviewed Tracks");
      dispatch(getAllTracksFetch("final_feedback_submitted", "supervisor_id"));
    }
    console.log(tracks);
  }, [selectedSection, dispatch]);

  const handleTogglePlay = () => {
    const video = mediaRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const media = mediaRef.current;
    const progressPercent = (media.currentTime / media.duration) * 100;
    setProgress(progressPercent);
  };

  const handleTrackFeedback = (trackID, status) => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    console.log("Navigating to track feedback for track id:", trackID, status);
    setCurrentStatus(status);
    navigate(`/TrackFeedback/${trackID}`, {
      state: { fromUserPortal: false, status },
    });
  };
  const handleCloseAssignPopup = () => {
    window.location.reload();
    setShowAssignPopup(false);
  };
  const handleStatus = () => {
    if (currentStatus === "submitted") {
      setCurrentStatus("in_review");
    }
  };
  return (
    <>
      {showAssignPopup && (
        <SupAssignPopup
          trackId={trackId}
          onClose={handleCloseAssignPopup}
          handleTrackFeedback={handleTrackFeedback}
          currentStatus={currentStatus}
          handleStatus={handleStatus}
        />
      )}
      <div
        className="vh-100 px-3"
        style={{ background: "white", color: "black", overflowY: "auto" }}
      >
        <SUPHeader />
        <div className=" padding-0">
          {/* <Container className='bg-main-div mt-3 ' > */}
          <div className="bg-main-div mt-3 ">
            <Row className="mb-3 supervisor-dashboard-container ">
              <Col
                className="d-flex justify-content-center align-items-center py-3"
                style={{ borderRight: "3px solid #FFFFFF" }}
              >
                <div className="box d-flex justify-content-between align-items-center">
                  <div>
                    <SMLLabel
                      style="title4 marginRight"
                      value={stats?.submitted}
                    ></SMLLabel>
                  </div>
                  <div>
                    <SMLLabel
                      style="title"
                      value="Track Waiting for Reviews"
                    ></SMLLabel>
                  </div>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center"
                style={{ borderRight: "3px solid #FFFFFF" }}
              >
                <div className="box d-flex justify-content-between align-items-center">
                  <div>
                    <SMLLabel
                      style="title4 marginRight"
                      value={stats?.under_review}
                    ></SMLLabel>
                  </div>
                  <div>
                    <SMLLabel
                      style="title"
                      value="Track Under Reviews"
                    ></SMLLabel>
                  </div>
                </div>
              </Col>

              <Col className="d-flex justify-content-center align-items-center">
                <div className="box d-flex justify-content-between align-items-center">
                  <div>
                    <SMLLabel
                      style="title4 marginRight"
                      value={stats?.feedback_complete}
                    ></SMLLabel>
                  </div>
                  <div>
                    <SMLLabel
                      style="title"
                      value="Feedback Submited"
                    ></SMLLabel>
                  </div>
                </div>
              </Col>
            </Row>
            {/* </Container> */}
          </div>

          {/* <Container className='bg-main-div mt-3 mb-3' style={{ backgroundColor: '#f8f9fa',padding: '0px 50px' }}> */}
          <div
            className="bg-main-div mt-3 mb-3"
            style={{ backgroundColor: "#f8f9fa", padding: "0px 50px" }}
          >
            <Row className="mb-3">
              <Col>
                <div className="d-flex justify-content-between align-items-center p-4">
                  {loading && (
                    <div className="spinner-overlay">
                      <Bars
                        height="80"
                        width="80"
                        color="#23f0c7"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  )}
                  <SMLLabel style="title1" value={labelText} />
                  <div className="d-flex">
                    <button
                      className={
                        selectedSection === "Waiting"
                          ? "SMLToggleBtn-orange"
                          : "SMLToggleBtn-white"
                      }
                      onClick={() => handleSectionChange("Waiting")}
                    >
                      Submited
                    </button>
                    {isMaster === "true" ? (
                      <button
                        className={
                          selectedSection === "Assigned"
                            ? "SMLToggleBtn-orange"
                            : "SMLToggleBtn-white"
                        }
                        onClick={() => handleSectionChange("Assigned")}
                      >
                        Assigned
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      className={
                        selectedSection === "UnderReview"
                          ? "SMLToggleBtn-orange ml-2 mr-2"
                          : "SMLToggleBtn-white ml-2 mr-2"
                      }
                      onClick={() => handleSectionChange("UnderReview")}
                    >
                      Under Review
                    </button>
                    {isMaster === "false" && (
                      <button
                        className={
                          selectedSection === "UnderMasterReview"
                            ? "SMLToggleBtn-orange ml-2 mr-2"
                            : "SMLToggleBtn-white ml-2 mr-2"
                        }
                        onClick={() => handleSectionChange("UnderMasterReview")}
                      >
                        Under Master Review
                      </button>
                    )}
                    {isMaster === "true" ? (
                      <button
                        className={
                          selectedSection === "Pending"
                            ? "SMLToggleBtn-orange ml-2 mr-2"
                            : "SMLToggleBtn-white ml-2 mr-2"
                        }
                        onClick={() => handleSectionChange("Pending")}
                      >
                        Finalised
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      className={
                        selectedSection === "Reviewed"
                          ? "SMLToggleBtn-orange"
                          : "SMLToggleBtn-white"
                      }
                      onClick={() => handleSectionChange("Reviewed")}
                    >
                      Reviewed
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            {selectedSection === "Waiting" && (
              <div className="Waiting">
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                        onClick={handleShowAssignPopup}
                        handleShowAssignPopup={handleShowAssignPopup}
                      />
                      <div style={{ marginBottom: "20px" }} />{" "}
                      {/* Add margin between repetitions */}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div> // You can display a message or a spinner here
                )}
              </div>
            )}
            {selectedSection === "Assigned" && (
              <div className="Assigned">
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                        onClick={handleShowAssignPopup}
                        handleShowAssignPopup={handleShowAssignPopup}
                      />
                      <div style={{ marginBottom: "20px" }} />{" "}
                      {/* Add margin between repetitions */}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div> // You can display a message or a spinner here
                )}
              </div>
            )}
            {selectedSection === "UnderReview" && (
              <div className="UnderReview" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />{" "}
                      {/* Add margin between repetitions */}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div> // You can display a message or a spinner here
                )}
              </div>
            )}
            {selectedSection === "UnderMasterReview" && (
              <div className="UnderMasterReview" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />{" "}
                      {/* Add margin between repetitions */}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div> // You can display a message or a spinner here
                )}
              </div>
            )}
            {selectedSection === "Pending" && (
              <div className="Pending" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />{" "}
                      {/* Add margin between repetitions */}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div> // You can display a message or a spinner here
                )}
              </div>
            )}
            {selectedSection === "Reviewed" && (
              <div className="Reviewed" onClick={handleTrackFeedback}>
                {tracks && Array.isArray(tracks) && tracks.length > 0 ? (
                  tracks.map((track, index) => (
                    <React.Fragment key={index}>
                      <TrackSection
                        track={track}
                        handleTrackFeedback={handleTrackFeedback}
                        trackImg={trackImg}
                        audioSrc={audioSrc}
                        mediaRef={mediaRef}
                        handleTimeUpdate={handleTimeUpdate}
                        handleTogglePlay={handleTogglePlay}
                        isPlaying={isPlaying}
                        progress={progress}
                      />
                      <div style={{ marginBottom: "20px" }} />{" "}
                      {/* Add margin between repetitions */}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ textAlign: "center", padding: "0  0 20px 0" }}>
                    No tracks available
                  </div> // You can display a message or a spinner here
                )}
              </div>
            )}
            {/* </Container> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupDashboard;
