import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import api from '../api'
const BASE_URL = 'https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev' // Use the proxy base URL

// Function to fetch artist data
export const getSingleArtistApi = async (artistId) => {
  try {
    // Retrieve the access token from cookies
     // const accessToken = Cookies.get('token');
     const accessToken = localStorage.getItem('token');
    // const accessToken = Cookies.get('token')
    // const decodedToken = jwtDecode(accessToken)

    // const artistData = JSON.stringify(updates);
    // console.log(artistData);

    // Define the API endpoint for fetching artists
    const url = `${BASE_URL}/profiles/artists/${artistId}`
    console.log("artistId", artistId)
    const config = {
    //   method: 'get',
    //   maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`, //
        'Content-Type': 'application/json',
      },
    }

    // Make the API request
    const response = await api.get( url, config )

    console.log('Response Data:', response.data) // Log the artist's data
    return response?.data // Return artist's data
  } catch (error) {
    // Log and throw error
    console.error(
      'Error updating',
      error.response ? error.response.data : error.message
    )
    throw error
  }
}
