
// import Cookies from 'js-cookie';
import api from '../api'
const BASE_URL = 'https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev';

// const isTokenExpired = () => {

//   const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");
//   return !tokenExpiryTime || Date.now() > tokenExpiryTime;
// };

export const downloadTrackApi = async (trackId) => {
  try {
    // const accessToken = Cookies.get('token');
    const accessToken = localStorage.getItem('token');
    console.log(trackId);
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    trackId = trackId.trackId;
    const response = await api.get(
      `${BASE_URL}/tracks/${trackId}/download`,
      config
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(
      'Error downloading single track',
      error.response ? error.response.data : error.message
    );
    if (error.message.includes('Token expired')) {
      // Optionally handle token expiration (e.g., redirect to login)
    }
    throw error;
  }
};
