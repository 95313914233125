import {
  DOWNLOAD_TRACK_FAILURE,
  DOWNLOAD_TRACK_LOADING,
  DOWNLOAD_TRACK_SUCCESS,
  RESET_DOWNLOAD_TRACK,
} from '../actions/actionTypes';

const intialState = {
  isError: false,
  isLoading: false,
  downloadLink: '',
  error: undefined,
};

export const downloadTrackReducer = (state = intialState, action) => {
  switch (action.type) {
    case DOWNLOAD_TRACK_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    case DOWNLOAD_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        downloadLink: action.payload,
        error: undefined,
      };
    case DOWNLOAD_TRACK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    }
    case RESET_DOWNLOAD_TRACK: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        downloadLink: '',
        error: undefined,
      };
    }
    default:
      return state;
  }
};
