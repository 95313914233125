import React, { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "./UploadTrack.css";
import SMLLabel from "../SMLLabel/SMLLabel";
import { useNavigate } from "react-router-dom";
import SMLTextBox from "../SMLTextBox/SMLTextBox";
import SMLButton from "../SMLButton/SMLButton";
import trackIcon from "../../../assets/Images/trackIcon.svg";
import trash from "../../../assets/Images/trash.svg";
import UploadIcon from "../../../assets/Images/UploadIcon.svg";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import FeedbackForum from "../../../pages/Feedback/FeedbackForum";

const UploadTrack = ({ onPopupClose }) => {
  const [trackName, setTrackName] = useState("");
  const [genreName, setGenreName] = useState("");
  const [audio, setAudio] = useState(null);
  const [fileName, setFileName] = useState("No selected file");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const [fileError, setFileError] = useState("");
  const inputRef = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "audio/mpeg": [".mp3"],
      "audio/wav": [".wav"],
      "audio/aiff": [".aiff", ".aif"],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFileName(file.name);
        handleUpload(file);
      }
    },
    onDragOver: (e) => e.preventDefault(),
    onDropRejected: () =>
      setFileError(
        "Invalid file type. Please upload an MP3, WAV, or AIFF file."
      ),
  });

  const handleUpload = (file) => {
    console.log("FILE", file);
    setIsUploadComplete(true);
    setAudio(file);
  };

  const validate = () => {
    const newErrors = {};
    if (!trackName) newErrors.trackName = "Track name is required";
    if (!genreName) newErrors.genreName = "Genre name is required";
    if (!audio) newErrors.audio = "Audio file is required";
    return newErrors;
  };

  const handleSaveClick = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowFeedbackPopup(false);
    setShowPopup(false);
    onPopupClose();
    window.location.reload();
  };

  return (
    <div className="upload-container">
      <div className="upload-card">
        <div className="upload-close-div">
          <img
            src={closeIcon}
            onClick={onPopupClose}
            style={{ cursor: "pointer" }}
            alt="Close"
          />
        </div>
        <div className="upload-main">
          <div className="upload-label-div">
            <SMLLabel value="Upload New Track" style="Mainheader" />
          </div>
          <div
            className="upload-content-container"
            style={{ marginTop: "20px" }}
          >
            {isUploadComplete ? (
              <div className="upload-box-complete">
                <div className="upload-grid">
                  <div className="upload-grid-left-content">
                    <img
                      src={UploadIcon}
                      className="uploadImg-inner-frame"
                      alt="Upload Icon"
                    />
                  </div>
                  <div className="upload-grid-right-content">
                    <div className="upload-controls">
                      <div className="upload-fields">
                        <SMLLabel value="Track Name" style="title2" />
                        <SMLTextBox
                          value={trackName}
                          onChange={(e) => setTrackName(e.target.value)}
                        />
                        {errors.trackName && (
                          <p className="error-text">{errors.trackName}</p>
                        )}
                      </div>
                      <div className="upload-fields">
                        <SMLLabel value="Genre" style="title2" />
                        <div className="SMLDropdownWrapper">
                          <select
                            value={genreName}
                            onChange={(e) => setGenreName(e.target.value)}
                            className="form-control SMLDropdown"
                          >
                            <option value="" disabled>
                              Select Genre
                            </option>
                            <option value="rock">Rock</option>
                            <option value="pop">Pop</option>
                            <option value="hiphop_rap">Hip Hop/Rap</option>
                            <option value="electronic">Electronic</option>
                            <option value="jazz">Jazz</option>
                            <option value="blues">Blues</option>
                            <option value="country">Country</option>
                            <option value="rb_soul">R&B/Soul</option>
                            <option value="metal">Metal</option>
                            <option value="neo_classical">Neo-Classical</option>
                            <option value="world_music">World Music</option>
                            <option value="folk">Folk</option>
                            <option value="experimental">Experimental</option>
                            <option value="trailer_music">Trailer Music</option>
                            <option value="other">Other</option>
                          </select>

                          {/* <div className="custom-dropdown">
                            <option value="Movie">Movie</option>
                            <option value="TV">TV</option>
                          </div> */}
                        </div>

                        {errors.genreName && (
                          <p
                            className="error-text"
                            style={{ marginTop: "45px" }}
                          >
                            {errors.genreName}
                          </p>
                        )}
                      </div>
                      <div className="upload-btn-Bottom-right d-flex">
                        <SMLButton
                          style="SMLCancelUpload"
                          valueStyle="Button4"
                          value="Cancel Upload"
                          handleClickEvent={onPopupClose}
                        />
                        <SMLButton
                          style="SMLSendFeedback"
                          value="Upload & Continue"
                          handleClickEvent={handleSaveClick}
                        />
                        {showPopup && (
                          <div className="popup">
                            <FeedbackForum
                              audio={audio}
                              trackName={trackName}
                              genreName={genreName}
                              onClose={handleClosePopup}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                {...getRootProps({ className: "upload-box" })}
                className="upload-box"
              >
                <input
                  {...getInputProps()}
                  type="file"
                  className="input-field"
                  hidden
                />
                <>
                  <img src={UploadIcon} alt="Upload Icon" width={58} />
                  <p className="title2">Drop your track here</p>
                  <p>Upload from device</p>
                  <p className="sub-subtitle2">Max file size - 0.0MB</p>
                  {errors.audio && <p className="error-text">{errors.audio}</p>}
                  {fileError && <p className="error-text">{fileError}</p>}
                </>
              </div>
            )}
          </div>
          {uploadProgress > 0 && !isUploadComplete && (
            <div className="progressbar-div">
              <div className="progressbar-content">
                <div className="progressbar-content">
                  <img
                    src={trackIcon}
                    alt={fileName}
                    onClick={() => {
                      setFileName("No selected File");
                      setAudio(null);
                    }}
                  />
                  <SMLLabel value={fileName} />
                </div>
                <img
                  src={trash}
                  alt="Trash Icon"
                  style={{ float: "right" }}
                  onClick={() => {
                    setFileName("No selected File");
                    setAudio(null);
                  }}
                />
              </div>
              <progress
                value={uploadProgress}
                max="100"
                className="progressbar-width"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadTrack;
