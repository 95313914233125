import HomeLayout from '../../components/Layout/HomeLayout';
import React, { useEffect } from 'react';
import TracksView from '../../components/common/tracks-view/TracksView';
import FeedbackReceivedIcon from '../../assets/Icons/feedback-recieved.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTracksFetch } from '../../redux/actions/smlActions';
import { Bars } from 'react-loader-spinner';
import { RESET_SEARCH } from '../../redux/actions/actionTypes';

export default function FeedbackReceived() {
  const dispatch = useDispatch();
  const tracksfromsearch = useSelector(
    (store) => store.search.searchedResult.result
  );
  const searchText = useSelector((store) => store.search.searchText);
  const tracks = useSelector(
    (state) => state.sml.allTracksFetched?.tracks || state.uploadTrack?.tracks
  );
  const loading = useSelector((store) => store.uploadTrack?.isLoading);

  useEffect(() => {
    dispatch({ type: RESET_SEARCH });
    dispatch(getAllTracksFetch('final_feedback_submitted', 'artist_id'));
  }, [dispatch]);

  const hasSearchResults = tracksfromsearch && tracksfromsearch?.length > 0;
  const hasSearched = searchText && searchText.trim() !== ''; // Check if the search box is not empty

  return (
    <HomeLayout status="final_feedback_submitted">
      <TracksView
        headerLabel={'Feedback Received'}
        headerLabelIcon={FeedbackReceivedIcon}
        tracks={hasSearchResults ? tracksfromsearch : hasSearched ? [] : tracks}
        noTracksMessage="No tracks found."
      />
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </HomeLayout>
  );
}
