import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLButtonWithIcon from "../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon";
import SMLCheckbox from "../../components/Shared/SMLCheckbox/SMLCheckbox";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";

import "../../styles/Fonts.scss";
import "./Register.css";
import Backbutton from "../../components/Shared/BackButton/BackButton";
import TermsAndConditions from "../../components/UI/TermsAndConditions/TermsAndCondition"; // Add this import
import { MdOutlineClose, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { socialLoginRequest } from "../../redux/actions";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { APPCONFIG } from "../../config/APPConfig";
import closeIcon from "../../assets/Images/closeIcon.svg";
import SupervisionLogin from "../SupervisorLogin/SupervisorLogin";

const Register = (props) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [agreeConditions, setAgreeConditions] = useState(false); // Changed to boolean
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [createPasswordError, setCreatePasswordError] = useState("");
  const [verifyPasswordError, setVerifyPasswordError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false); // For password visibility toggle
  const [showVerifyPassword, setShowVerifyPassword] = useState(false); // For password visibility toggle
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleFacebookSignup = (credentialResponse) => {
    // Dispatch action with Google credential
    console.log(credentialResponse.credential);
    const token = {
      credential: credentialResponse.credential,
      provider: "facebook",
      profile_type: "artist",
    };
    if (token) {
      props.onRegisterClick(token);
    }
  };
  const handleGoogleSignup = (credentialResponse) => {
    console.log(credentialResponse.credential);
    const token = {
      credential: credentialResponse.credential,
      provider: "google",
      profile_type: "supervisor",
    };
    if (token) {
      props.onRegisterClick(token);
    }
  };
  const handleRegister = (e) => {
    e.preventDefault();

    // Validate Full Name
    if (!fullName.trim()) {
      setFullNameError("Full Name is required");
      return;
    }

    // Validate email
    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    }

    // Validate passwords
    if (!createPassword.trim()) {
      setCreatePasswordError("Password is required");
      return;
    }
    if (createPassword !== verifyPassword) {
      setVerifyPasswordError("Passwords do not match");
      return;
    }

    // Prepare the data with camelCase
    const registrationData = {
      email,
      password: createPassword,
      fullName, // camelCase
      profileType: "supervisor", // camelCase (if applicable)
    };

    // Call the parent function with the formatted data
    props.onRegisterClick(registrationData);
  };

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return showLogin ? (
    <SupervisionLogin />
  ) : (
    <div className="register-signUp-container" tabindex="-1">
      <div className="register-signup-card">
        <div className="register-signUp-groupby">
          <div className="register-signUp-main">
            <div className="register-close-div" onClick={props.onClose}>
              <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
            </div>
            <form className="register-signUp-grid" onSubmit={handleRegister}>
              <div className="register-signUp-content">
                <div className="register-signUp-controls">
                  <div className="register-signUp-fields">
                    <SMLLabel value="Full Name" style="title2" />
                    <SMLTextBox
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    {fullNameError && (
                      <span className="error">{fullNameError}</span>
                    )}
                  </div>
                  <div className="register-signUp-fields">
                    <SMLLabel value="Email" style="title2" />
                    <SMLTextBox
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && <span className="error">{emailError}</span>}
                  </div>
                  <div className="register-signUp-fields">
                    <SMLLabel value="Create Password" style="title2" />
                    <div className="password-container">
                      <SMLTextBox
                        type={showCreatePassword ? "text" : "password"}
                        value={createPassword}
                        onChange={(e) => setCreatePassword(e.target.value)}
                      />
                      <span
                        className="toggle-password-visibility"
                        onClick={() =>
                          setShowCreatePassword(!showCreatePassword)
                        }
                      >
                        {showCreatePassword ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </span>
                    </div>
                    {createPasswordError && (
                      <span className="error">{createPasswordError}</span>
                    )}
                  </div>
                  <div className="register-signUp-fields">
                    <SMLLabel value="Verify Password" style="title2" />
                    <div className="password-container">
                      <SMLTextBox
                        type={showVerifyPassword ? "text" : "password"}
                        value={verifyPassword}
                        onChange={(e) => setVerifyPassword(e.target.value)}
                      />
                      <span
                        className="toggle-password-visibility"
                        onClick={() =>
                          setShowVerifyPassword(!showVerifyPassword)
                        }
                      >
                        {showCreatePassword ? (
                          <MdVisibilityOff />
                        ) : (
                          <MdVisibility />
                        )}
                      </span>
                    </div>
                    {verifyPasswordError && (
                      <span className="error">{verifyPasswordError}</span>
                    )}
                  </div>
                  <div className="register-signUp-fields">
                    <div className="signUp-groupby">
                      <SMLCheckbox
                        checked={agreeConditions}
                        onChange={(e) => setAgreeConditions(e.target.checked)}
                      />
                      <div className="title2" style={{ margin: "0px" }}>
                        I accept all{" "}
                        <a className="link" onClick={openModal}>
                          terms and conditions
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="register-signUp-content">
                <div className="register-signUp-controls">
                  <div className="divider"></div>
                  <span>or</span>
                  <div className="divider"></div>
                </div>
              </div>
              <div className="register-signUp-right-content">
                <div className="register-signUp-controls">
                  <div className="register-signUp-fields">
                    {/* <SMLButtonWithIcon
                      type="button"
                      value="Sign up with Google"
                      icon="Google"
                    /> */}
                    <GoogleLogin
                      onSuccess={(codeResponse) => {
                        handleGoogleSignup(codeResponse);
                      }}
                      onError={() => console.log("Login Failed")}
                      setOneTap={false}
                      text="signup_with"
                      size="large"
                      width="232"
                    />
                  </div>
                  <div className="register-signUp-fields">
                    {/* <SMLButtonWithIcon
                      type="button"
                      value="Sign up with Facebook"
                      icon="Facebook"
                    /> */}
                    <FacebookLogin
                      appId={APPCONFIG.FACEBOOK_APP_ID}
                      fields="name,email,picture"
                      callback={handleFacebookSignup}
                      render={(renderProps) => (
                        <SMLButtonWithIcon
                          type="button"
                          value="Sign up with Facebook"
                          icon="Facebook"
                          onClick={() => {
                            renderProps.onClick();
                          }}
                          gap="gap"
                        />
                      )}
                    />
                  </div>
                  <div className="register-signUp-fields">
                    <div className="register-signUp-groupby">
                      <SMLLabel value="Already a member?" style="title2" />
                      <button
                        type="button"
                        className="btn-no-margin"
                        onClick={handleLoginClick}
                      >
                        Log in
                      </button>
                    </div>
                  </div>
                </div>
                <div className="register-signUp-footer">
                  <SMLButton
                    style={`SMLButton2 ${agreeConditions ? "" : "disabled"}`}
                    value="Continue"
                    handleDisable={!agreeConditions} // Disable button based on checkbox
                  />
                </div>
              </div>
            </form>
            <TermsAndConditions
              isOpen={isModalOpen}
              onClose={closeModal}
              title="Terms and Conditions"
            >
              {/* Modal content */}
            </TermsAndConditions>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
