import React, { useEffect, useState } from "react";
import RegisterComponent from "./Register/Register";
import ContactInfoComponent from "./ContactInfo/ContactInfo";
import SuccessPageComponent from "./SuccessPage/SuccessPage";
import { useDispatch, useSelector } from "react-redux";
import { supSignupRequest } from "../redux/actions";
import {
  signupRequest,
  socialSignupRequest,
  supSocialSignupRequest,
} from "../redux/actions/authActions";
import { useNavigate } from "react-router-dom";

function SupervisorPage({ onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState("register");
  const [formData, setFormData] = useState({});
  const {
    error,
    supSignedUp,
    supSocialSignedIn,
    supSignupData,
    artistSignedUp,
    artistSocialSignedIn
  } = useSelector((state) => state.auth);
  const handleRegisterSubmit = (registerData) => {
    console.log(
      "handleRegisterSubmit Received data in SupervisorPage:",
      registerData
    ); // Add this line
    setFormData(registerData);
    console.log("form data", formData);

    setCurrentStep("contact");
  };
  useEffect(() => {
    if (artistSignedUp?.is_new_user === false) {
      navigate("/supervisorDashboard");
    }
  }, [artistSignedUp]);
  useEffect(() => {
    if (artistSocialSignedIn||supSignedUp||artistSignedUp?.is_new_user) {
      setCurrentStep("success");
    }
  }, [artistSocialSignedIn,supSignedUp,artistSignedUp]);
  const handleContactInfoSubmit = (contactData) => {
    if (formData.credential) {
      dispatch(
        supSocialSignupRequest(
          formData.credential,
          formData.provider,
          formData.profile_type,
          contactData
        )
      );
    } else {
      const completeData = {
        ...formData,
        ...contactData,
        profileType: "supervisor",
      };
      dispatch(supSignupRequest(completeData));
    }
  };

  const handleBackClick = () => {
    setCurrentStep("register");
  };

  return (
    <div tabindex="-1">
      {currentStep === "register" && (
        <RegisterComponent
          onRegisterClick={handleRegisterSubmit}
          onClose={onClose}
        />
      )}
      {currentStep === "contact" && (
        <ContactInfoComponent
          onContactInformationClick={handleContactInfoSubmit}
          onBackClick={handleBackClick}
          onClose={onClose}
        />
      )}
      {currentStep === "success" && <SuccessPageComponent onClose={onClose} />}
    </div>
  );
}

export default SupervisorPage;
