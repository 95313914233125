import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import redBgIcon from '../../assets/Images/red-Bg-Icon.svg';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-page">
      <nav className="privacy-navbar">
        <Link to="/" className="privacy-navbar-logo">
          <img src={redBgIcon} alt="Sync Music Lab Icon" />
        </Link>
        <Link to="/" className="privacy-nav-return">
          Back to SML
        </Link>
      </nav>

      <div className="privacy-container">
        <h1 className="privacy-header">Privacy Policy</h1>
        <div className="privacy-content">
          <p className="privacy-text">
            Effective Date: September 6, 2024
          </p>

          <p className="privacy-text">
            At Sync Music Lab, your privacy is of utmost importance. This Privacy Policy outlines how we collect, use, and protect your information when you use our platform and services. By accessing or using Sync Music Lab, you agree to the terms outlined in this policy.
          </p>

          <div className="privacy-section">
            <h2 className="privacy-section-title">1. Information We Collect</h2>
            <p className="privacy-text">
              We collect the following types of information to provide and improve our services:
            </p>
            <ul className="privacy-list">
              <li className="privacy-list-item"><strong>Personal Information:</strong> When you create an account, subscribe to our services, or submit a track for review, we collect personal information such as your name, email address, payment information, and any other details you provide.</li>
              <li className="privacy-list-item"><strong>Track Submissions:</strong> As part of our service, we collect information related to the music tracks you submit for review, including the audio files, associated metadata, and feedback provided by our music supervisors.</li>
              <li className="privacy-list-item"><strong>Usage Data:</strong> We collect data on how you interact with our platform, including log files, IP addresses, device information, and browsing patterns.</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2 className="privacy-section-title">2. How We Use Your Information</h2>
            <p className="privacy-text">
              We use the collected information for the following purposes:
            </p>
            <ul className="privacy-list">
              <li className="privacy-list-item">To provide and maintain our services, including processing payments and delivering feedback on submitted tracks.</li>
              <li className="privacy-list-item">To improve and personalize your experience on our platform.</li>
              <li className="privacy-list-item">To communicate with you about your account, services, and updates.</li>
              <li className="privacy-list-item">To analyze usage patterns and improve our platform's functionality.</li>
              <li className="privacy-list-item">To detect, prevent, and address technical issues or fraudulent activities.</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2 className="privacy-section-title">3. Data Security</h2>
            <p className="privacy-text">
              We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </div>

          <div className="privacy-section">
            <h2 className="privacy-section-title">4. Sharing of Information</h2>
            <p className="privacy-text">
              We do not sell your personal information to third parties. We may share your information in the following circumstances:
            </p>
            <ul className="privacy-list">
              <li className="privacy-list-item">With music supervisors and industry professionals for the purpose of reviewing and providing feedback on your submitted tracks.</li>
              <li className="privacy-list-item">With service providers who assist us in operating our platform and conducting our business.</li>
              <li className="privacy-list-item">When required by law or to protect our rights and the rights of our users.</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2 className="privacy-section-title">5. Your Rights and Choices</h2>
            <p className="privacy-text">
              You have certain rights regarding your personal information:
            </p>
            <ul className="privacy-list">
              <li className="privacy-list-item">Access and update your personal information through your account settings.</li>
              <li className="privacy-list-item">Request deletion of your personal data, subject to any legal obligations we may have to retain certain information.</li>
              <li className="privacy-list-item">Opt-out of marketing communications while still receiving essential service-related messages.</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2 className="privacy-section-title">6. Changes to This Privacy Policy</h2>
            <p className="privacy-text">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy.
            </p>
          </div>

          <div className="privacy-section">
            <h2 className="privacy-section-title">7. Contact Us</h2>
            <p className="privacy-text">
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <p className="privacy-text">
              Sync Music Lab<br />
              2080 Empire Avenue, STE1168<br />
              Burbank, CA 91504<br />
              Email: privacy@syncmusiclab.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;