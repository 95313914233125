import React from 'react'
import '../../../styles/Fonts.scss'
import { MdOutlineClose } from "react-icons/md";
import './trackSubmission.css'

const TrackSubmission = ({ onNext, onPopupClose }) => {
    return (
        <div className='trackSubmission'>
            <div className=''>
                <div className='trackSubmission-close-div' onClick={onPopupClose}>
                    <MdOutlineClose  onClick={onPopupClose} />
                </div>
                <div className='trackSubmission-rectangle'>
                    <div className='trackSubmission-header Mainheader'>
                        Track Submission
                    </div>
                    <div className='trackSubmission-text sub-subtitle2'>This is the information that our music supervisors can see when viewing your submission.</div>
                    <div className='trackSubmission-content'>
                        <div className='trackSubmission-input'>
                            <div className='title2'>Music Credits</div>
                            <div className='Body'>Inputted text goes here.</div>
                        </div>
                        <div className='trackSubmission-input'>
                            <div className='title2'>Lyrics Credits</div>
                            <div className='Body'>Inputted text goes here.</div>
                        </div>
                        <div className='trackSubmission-input'>
                            <div className='title2'>Vocals Credits</div>
                            <div className='Body'>Inputted text goes here.</div>
                        </div>
                        <div className='trackSubmission-input'>
                            <div className='title2'>Producer Credits</div>
                            <div className='Body'>Inputted text goes here.</div>
                        </div>
                        <div className='trackSubmission-input'>
                            <div className='title2'>Streaming Links</div>
                            <div className='Body'>Inputted text goes here.</div>
                            <div className='Body'>Inputted text goes here.</div>
                        </div>
                    </div>
                    <div className='trackSubmission-button'>
                        <button className='SMLButton2 Button1 trackSubmission-btn' onClick={onNext}>Next Page</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackSubmission
