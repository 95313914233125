import { jwtDecode } from 'jwt-decode';  // Correct import statement for jwt-decode
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const ProtectedSupervisorRoutes = ({ children }) => {
  const { user, supSignupData } = useSelector((state) => state.auth)
  const isAllowed = () => {
    const token = localStorage.getItem('token');
    const profile_type = localStorage.getItem("profile_type")
    if (token && profile_type === "supervisor") {
      return true
    } else if (user) {
      return true
    } else if (supSignupData?.is_new_user) {
      return true
    } else {
      return false
    }
  }

  return isAllowed() ? <Outlet /> : <Navigate to="/" />
};

export default ProtectedSupervisorRoutes;
