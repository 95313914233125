import React from "react";
import "./parallaxVideo.css";
import ReactPlayer from "react-player";

import video from "../../../assets/video/ShannonIntro12.mp4";

const parallaxVideo = () => {
    return (
        <div className="parallax-videoContainer">
            <h1>SML in a nutshell!</h1>
            <div className="parallax-screen">
                <ReactPlayer url={video} className="react-player" width="100%" height="100%" controls />
                <div className="react-player__controls"></div>
            </div>
        </div>
    );
};

export default parallaxVideo;
