import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SMLButton from '../../components/Shared/SMLButton/SMLButton';
import SMLLabel from '../../components/Shared/SMLLabel/SMLLabel';
import SMLOptionQuestion from '../../components/Shared/SMLOptionQuestion/SMLOptionQuestion';
import closeIcon from '../../assets/Images/closeIcon.svg';
import '../../styles/Fonts.scss';
import '../../pages/Feedback/feedback.css';
import '../../pages/LoginPage/LoginPage.css';
import Backbutton from '../../components/Shared/BackButton/BackButton';
import SMLTextArea from '../../components/Shared/SMLTextArea/SMLTextArea';
// import { useDispatch, useSelector } from "react-redux";
// import { postTrackRequest } from "../../redux/actions/uploadTrackActions";
const FeedbackOptions = [
  { id: 'option1', label: 'Certified Feedback', value: 'certified_feedback' },
  {
    id: 'option2',
    label: '30min Expert Live Consultation',
    value: 'expert_live_consultation',
  },
];
const TargetOptions = [
  { id: 'option1', label: 'TV', value: 'tv' },
  { id: 'option2', label: 'Film', value: 'film' },
  { id: 'option3', label: 'Trailers & Film/TV Marketing', value: 'trailers' },
  // { id: 'option4', label: 'Artist Pitch', value: 'artist_pitch' },
  { id: 'option4', label: 'Commercials', value: 'commercials' },
  // { id: 'option6', label: 'Libraries/Publishers', value: 'libraries' },
  { id: 'option5', label: 'Video Game', value: 'video_game' },
  { id: 'option6', label: 'Indie Label', value: 'indie_label' },
];
const FeedbackStageThree = (props) => {
  const [feedbackOptionsSelected, setFeedbackOptionsSelected] = useState({
    option: false,
    option1: false,
  });

  const [targetOptionsSelected, setTargetOptionsSelected] = useState({
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false,
    option7: false,
    option8: false,
    option9: false,
  });
  const {
    feedbackOptions,
    targetOptions,
    handleFeedbackOptions,
    handleTargetOptions,
  } = props;
  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  // const dispatch = useDispatch();
  // const trackState = useSelector((state) => state.uploadTrack);

  // useEffect(() => {
  //   if (
  //     trackState.tracks.length > 0 &&
  //     !trackState.loading &&
  //     !trackState.error
  //   ) {
  //     // debugger;
  //     props.onSaveClick();
  //   }
  // }, [trackState.tracks, trackState.loading, trackState.error, props]);
  // useEffect(() => {
  //   console.log("selected options", selectedFeedbackOptions);
  // }, [selectedFeedbackOptions]);
  // useEffect(() => {
  //   const anyFeedbackOptionSelected = Object.values(
  //     feedbackOptionsSelected
  //   ).some((option) => option);
  //   const anyTargetOptionSelected = Object.values(targetOptionsSelected).some(
  //     (option) => option
  //   );
  //   setIsContinueEnabled(anyFeedbackOptionSelected && anyTargetOptionSelected);
  // }, [feedbackOptionsSelected, targetOptionsSelected]);

  // const handleClickOption = (option, section) => {
  //   if (section === "feedback") {
  //     setFeedbackOptionsSelected((prevState) => ({
  //       ...prevState,
  //       [option]: !prevState[option],
  //     }));
  //   } else if (section === "target") {
  //     setTargetOptionsSelected((prevState) => ({
  //       ...prevState,
  //       [option]: !prevState[option],
  //     }));
  //   }
  // };
  useEffect(() => {
    const anyFeedbackOptionSelected = feedbackOptions.length > 0;
    const anyTargetOptionSelected = targetOptions.length > 0;
    setIsContinueEnabled(anyFeedbackOptionSelected && anyTargetOptionSelected);
  }, [feedbackOptions, targetOptions]);
  const handleClickOptionFeedback = (label) => {
    const newFeedbackOptions = feedbackOptions.includes(label)
      ? feedbackOptions.filter((option) => option !== label)
      : [...feedbackOptions, label];
    handleFeedbackOptions(newFeedbackOptions);
  };
  const handleClickOptionTarget = (label) => {
    const newTargetOptions = targetOptions.includes(label)
      ? targetOptions.filter((option) => option !== label)
      : [...targetOptions, label];
    handleTargetOptions(newTargetOptions);
  };
  const handleClick = (e) => {
    props.onFeedbackClick();
    console.log('Continue button clicked');
    // props.onFeedbackClick();
  };

  // const handleSaveButtonClick = () => {
  //   // debugger;
  //   dispatch(postTrackRequest(props.audio, props.trackName, props.genreName));
  // };

  const handleContinueClick = () => {
    props.onFeedbackClick();
    console.log('Continue button clicked');
  };

  return (
    <div className="feedback-container">
      <div className="feedback-card">
        <div className="feedback-close-div">
          <img src={closeIcon} alt="Close" onClick={props.onCloseTrack} />
        </div>
        <div className="feedback-main">
          <div className="feedback-label-div">
            <SMLLabel value="Submit Track For Feedback" style="Mainheader" />
          </div>
          <form className="feedback-content" onSubmit={handleClick}>
            <div>
              <div className="feedback-fields">
                <SMLLabel value="Feedback Needed" style="title2" />
                <div className="form-group col-12">
                  {FeedbackOptions.map((item) => (
                    <SMLOptionQuestion
                      key={item.id}
                      type="button"
                      style={
                        feedbackOptions.includes(item.label)
                          ? 'SMLSecondaryButtonSelected'
                          : 'SMLSecondaryButton'
                      }
                      handleClickEvent={() =>
                        handleClickOptionFeedback(item.label)
                      }
                      value={item.label}
                    />
                  ))}
                </div>
                {/* <div className="form-group col-12">
                  <SMLOptionQuestion
                    type="button"
                    style={
                      feedbackOptionsSelected.option1
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() =>
                      handleClickOption("option1", "feedback")
                    }
                    value="30min Expert Live Consultation"
                  />
                </div> */}
              </div>

              <div className="feedback-fields">
                <SMLLabel
                  value="Target for tracks placement opportunities"
                  style="title2"
                />
                <div className="form-group row-4 me-2">
                  {TargetOptions.map((item) => (
                    <SMLOptionQuestion
                      key={item.id}
                      type="button"
                      style={
                        targetOptions.includes(item.label)
                          ? 'SMLSecondaryButtonSelected mt-1 me-2'
                          : 'SMLSecondaryButton mt-1 me-2'
                      }
                      handleClickEvent={() =>
                        handleClickOptionTarget(item.label)
                      }
                      value={item.label}
                    />
                  ))}
                  {/* Add more options for this section */}
                </div>
                {/* Add more sections as needed */}
              </div>
              <div>
                <SMLLabel
                  value="Is there anything else you’d like us to know?"
                  style="title2 mb-2"
                />
                <SMLTextArea
                  value={props.additionalInfo}
                  onChange={(e) => {
                    props.handleAdditionalInfo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="feedback-btn-container">
              <div
                className="feedback-btn-Bottom-left"
                onClick={props.handleBackClick}
              >
                <Backbutton />
              </div>
              <div className="feedback-btn-Bottom-right">
                {/* <SMLButton
                  style="SMLButton3"
                  valueStyle="Button2"
                  value="Save"
                  handleClickEvent={handleSaveButtonClick}
                /> */}
                <SMLButton
                  style={`SMLButton2 SMLButton-Orange ${
                    isContinueEnabled ? '' : 'disabled'
                  }`}
                  value="Continue"
                  handleClickEvent={handleContinueClick}
                  disabled={!isContinueEnabled}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbackStageThree;
