import {
  GET_TRACK_STATS,
  GET_TRACK_STATS_SUCCESS,
  GET_TRACK_STATS_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  statsFetched: {
    isLoading: false,
    isError: false,
    stats: [],
    error: null,
  },
};

const trackStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRACK_STATS:
      return {
        ...state,
        statsFetched: {
          isLoading: true,
          isError: false,
          error: null, // Reset error when starting a new request
        },
      };

    case GET_TRACK_STATS_SUCCESS:
      return {
        ...state,
        statsFetched: {
          isLoading: false,
          stats: action.stats, // Correctly update stats here
        },
      };

    case GET_TRACK_STATS_FAILURE:
      return {
        ...state,
        statsFetched: {
          isLoading: false,
          isError: true,
          error: action.payload, // Store the error message or object
        },
      };

    default:
      return state;
  }
};

export default trackStatsReducer;
