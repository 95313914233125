import {
  GET_FEEDBACK_FAILURE,
  GET_FEEDBACK_LOADING,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE,
  POST_FEEDBACK_LOADING,
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  PUT_FEEDBACK_MS_FAILURE,
  PUT_FEEDBACK_MS_LOADING,
  PUT_FEEDBACK_MS_REQUEST,
  PUT_FEEDBACK_MS_SUCCESS,
  PUT_FINAL_FEEDBACK_MS_FAILURE,
  PUT_FINAL_FEEDBACK_MS_LOADING,
  PUT_FINAL_FEEDBACK_MS_REQUEST,
  PUT_FINAL_FEEDBACK_MS_SUCCESS,
} from './actionTypes';

export const getFeedbackRequest = (feedbackId) => {
  return {
    type: GET_FEEDBACK_REQUEST,
    payload: feedbackId,
  };
};
export const getFeedbackSuccess = (feedback) => {
  return {
    type: GET_FEEDBACK_SUCCESS,
    payload: feedback,
  };
};
export const getFeedbackFailure = (error) => {
  return {
    type: GET_FEEDBACK_FAILURE,
    payload: error,
  };
};
export const getFeedbackLoading = () => {
  return {
    type: GET_FEEDBACK_LOADING,
  };
};
export const postFeedbackRequest = (feedbackData) => {
  return {
    type: POST_FEEDBACK_REQUEST,
    payload: feedbackData,
  };
};
export const postFeedbackSuccess = (result) => {
  return {
    type: POST_FEEDBACK_SUCCESS,
    payload: result,
  };
};
export const postFeedbackFailure = (error) => {
  return {
    type: POST_FEEDBACK_FAILURE,
    payload: error,
  };
};
export const postFeedbackLoading = () => {
  return {
    type: POST_FEEDBACK_LOADING,
    // payload: data,
  };
};

export const putFeedbackMsRequest = (feedbackData) => {
  return {
    type: PUT_FEEDBACK_MS_REQUEST,
    payload: feedbackData,
  };
};
export const putFeedbackMsSuccess = (result) => {
  return {
    type: PUT_FEEDBACK_MS_SUCCESS,
    payload: result,
  };
};
export const putFeedbackMsFailure = (error) => {
  return {
    type: PUT_FEEDBACK_MS_FAILURE,
    payload: error,
  };
};
export const putFeedbackMsLoading = () => {
  return {
    type: PUT_FEEDBACK_MS_LOADING,
  };
};

export const putFinalFeedbackMsRequest = (feedbackData) => {
  return {
    type: PUT_FINAL_FEEDBACK_MS_REQUEST,
    payload: feedbackData,
  };
};
export const putFinalFeedbackMsSuccess = (result) => {
  return {
    type: PUT_FINAL_FEEDBACK_MS_SUCCESS,
    payload: result,
  };
};
export const putFinalFeedbackMsFailure = (error) => {
  return {
    type: PUT_FINAL_FEEDBACK_MS_FAILURE,
    payload: error,
  };
};
export const putFinalFeedbackMsLoading = () => {
  return {
    type: PUT_FINAL_FEEDBACK_MS_LOADING,
  };
};
