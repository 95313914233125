import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLToggleButton from "../../components/Shared/SMLToggleButton/SMLToggleButton";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import Backbutton from "../../components/Shared/BackButton/BackButton";
import closeIcon from "../../assets/Images/closeIcon.svg";
import addIcon from "../../assets/Images/addIcon.svg";
import "../../styles/Fonts.scss";
import "../../pages/Feedback/feedback.css";
import "../../pages/LoginPage/LoginPage.css";

const FeedbackStageTwo = (props) => {
  const navigate = useNavigate();

  const [isInProgress, setIsInProgress] = useState(props.isInProgress);
  const [isCompleted, setIsCompleted] = useState(props.isCompleted);
  const [sampleSource, setSampleSource] = useState("");
  const [errors, setErrors] = useState({});
  const [isSynced, setIsSynced] = useState(props.syncedBefore);
  const [samplesUsed, setSamplesUsed] = useState(props.samplesUsed);

  // Initialize sampleSources as an array of strings
  const [sampleSources, setSampleSources] = useState(props.sampleSources || []);
  const [askSample, setAskSample] = useState(props.askSamples);
  useEffect(() => {
    // validateForm();
    console.log("Current sampleSources:", sampleSources);
  }, [sampleSources, askSample]);

  const handleContinueClick = () => {
    // Only validate on Continue button click
    if (validateForm()) {
      props.onFeedbackClick();
      console.log("Continue button clicked");
    }
  };
  const handleWorkInProgressClick = () => {
    setIsInProgress(true);
    setIsCompleted(false);
    props.handleWorkStatus("work_in_progress");
  };

  const handleCompletedClick = () => {
    setIsInProgress(false);
    setIsCompleted(true);
    props.handleWorkStatus("completed");
  };

  const handleClick = (e) => {
    e.preventDefault();
    // if (validateForm()) {
    console.log("Form submitted successfully");
    // }
  };

  const validateForm = () => {
    const newErrors = {};
    // if (!isInProgress && !isCompleted) {
    //   newErrors.trackStatus = "Please select a track status";
    // }
    if (askSample) {
      // Check if any sampleSources exist and ensure no empty strings
      if (
        sampleSources.length === 0 ||
        sampleSources.some((source) => source.trim() === "")
      ) {
        newErrors.sampleSource =
          "Please add at least one valid sample source link.";
      }
    }
    setErrors(newErrors);
    console.log(errors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSynced = () => {
    setIsSynced(!isSynced);
    props.handleSyncedBefore();
  };

  const handleSamples = () => {
    // Toggling askSample and clearing sampleSources only when user decides not to ask for samples
    setAskSample(!askSample);
    if (!askSample) {
      setSampleSources([]); // Reset the links when toggling off "Ask Sample"
      setErrors((prevErrors) => ({ ...prevErrors, sampleSource: "" })); // Clear sampleSource error when toggled off
    }
    props.handleAskSamples();
    setSamplesUsed(!samplesUsed);
    props.handleSamplesUsed();
  };
  const handleAddLink = () => {
    const newSampleSources = [...sampleSources, ""]; // Adding a new empty input
    setSampleSources(newSampleSources);
    props.handleSampleSources(newSampleSources);
  
    // Don't run validation when adding a new link
    setErrors((prevErrors) => ({ ...prevErrors, sampleSource: "" }));
  };

  const handleLinkChange = (index, newValue) => {
    const updatedSources = sampleSources.map((source, i) =>
      i === index ? newValue : source
    );
    setSampleSources(updatedSources);
    props.handleSampleSources(updatedSources);
  };
  const handleRemoveLink = (index) => {
    const updatedSources = sampleSources.filter((_, i) => i !== index);
    setSampleSources(updatedSources);
    props.handleSampleSources(updatedSources);
  };
  return (
    <div className="feedback-container">
      <div className="feedback-card">
        <div className="feedback-close-div">
          <img src={closeIcon} onClick={props.onCloseTrack} alt="Close" />
        </div>
        <div className="feedback-main">
          <div className="feedback-label-div">
            <SMLLabel value="Submit Track For Feedback" style="Mainheader" />
          </div>
          <form className="feedback-content" onSubmit={handleClick}>
            <div className="feedback-fields">
              <SMLLabel value="Track Status" style="title2" />
              <div className="feedback-groupby">
                <SMLButton
                  style={
                    isInProgress ? "SMLToggleBtn-orange" : "SMLToggleBtn-white"
                  }
                  handleClickEvent={handleWorkInProgressClick}
                  value="Work in progress"
                  textColor={isInProgress ? "white" : "black"} // Set text color conditionally
                />

                <SMLButton
                  style={
                    isCompleted ? "SMLToggleBtn-orange" : "SMLToggleBtn-white"
                  }
                  handleClickEvent={handleCompletedClick}
                  value="Completed"
                  textColor={isCompleted ? "white" : "black"} // Set text color conditionally
                />
              </div>
              <div className="feedback-groupby">
                <SMLToggleButton
                  value="Yes"
                  checked={isSynced}
                  onChange={handleSynced}
                />
                <SMLLabel
                  value="Has the track been synced or pitched for sync before?"
                  style="title2"
                />
              </div>

              <div className="feedback-groupby">
                <SMLToggleButton
                  value="Yes"
                  checked={askSample}
                  onChange={handleSamples}
                />
                <SMLLabel
                  value="Are any purchased beats or unauthorized samples contained in this track?"
                  style="title2"
                />
              </div>
            </div>

            {askSample && (
              <div className="feedback-fields">
                <SMLLabel value="Sample Sources" style="title2" />
                <div className="feedback-fields-link">
                  {sampleSources.map((source, index) => (
                    <div key={index} className="feedback-groupby">
                      <SMLTextBox
                        placeHolder=""
                        value={source}
                        onChange={(e) =>
                          handleLinkChange(index, e.target.value)
                        }
                        imgSrc={closeIcon}
                        onImgClick={() => handleRemoveLink(index)}
                      />
                    </div>
                  ))}
                </div>
                <b
                  className="orange-color link-btn-icon"
                  onClick={handleAddLink}
                >
                  <img src={addIcon} alt="Add" />
                </b>
              </div>
            )}
            {askSample && <SMLLabel
              style="error"
              value={errors.sampleSource && errors.sampleSource}
            />}
            <div className="feedback-btn-container">
              <div
                className="feedback-btn-Bottom-left"
                onClick={props.handleBackClick}
              >
                <Backbutton />
              </div>
              <div className="feedback-btn-Bottom-right">
                <SMLButton
                  style="SMLButton2 SMLButton-Orange"
                  value="Continue"
                  handleClickEvent={handleContinueClick}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbackStageTwo;
