import axios from 'axios';
import store from '../redux/store/configureStore';
import { refreshTokenRequest, logoutRequest } from '../redux/actions/authActions';

const api = axios.create({
  baseURL: 'https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev',
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};


api.interceptors.response.use(
  (response) => response,  // If response is successful, return it
  async (error) => {       // If there is an error in the response
    console.log("the error iis here b",error);
    debugger
    const originalRequest = error.config;

    if ((error.response.status === 401||error.response.status===403) && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return api(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        // Refresh the token using the store's dispatch method
        await store.dispatch(refreshTokenRequest());
        const access_token= localStorage.getItem("token")
        // storeTokens(access_token, refresh_token);

        // Update headers for future requests
        api.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        originalRequest.headers['Authorization'] = 'Bearer ' + access_token;

        // Resolve the failed requests in the queue with the new token
        processQueue(null, access_token);

        // Retry the original request with the new token
        return api(originalRequest);
      } catch (err) {
        // Handle refresh token failure
        processQueue(err, null);
        store.dispatch(logoutRequest());
        return Promise.reject(err);
      } finally {
        // Reset the isRefreshing flag
        isRefreshing = false;
      }
    }

    // Reject the error if not a 401 or retry condition
    return Promise.reject(error);
  }
);

export default api;