
import api from '../api';
// import Cookies from 'js-cookie';

const BASE_URL = 'https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev';

// const isTokenExpired = () => {

//   const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");
//   return !tokenExpiryTime || Date.now() > tokenExpiryTime;
// };

export const getSingleTrackApi = async (trackId) => {
  try {
     // const accessToken = Cookies.get('token');
     const accessToken = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await api.get(`${BASE_URL}/tracks/${trackId}`, config);
    return response.data;
  } catch (error) {
    console.error(
      'Error fetching single track',
      error.response ? error.response.data : error.message
    );
    if (error.message.includes('Token expired')) {
      // Optionally handle token expiration (e.g., redirect to login)
    }
    throw error;
  }
};
