
import React from 'react';
import './TermsAndConditions.css';
import { MdOutlineClose } from 'react-icons/md';

const TermsAndConditions = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="modal-close" onClick={onClose}><MdOutlineClose /></button>
        {children}
      </div>
    </div>
  );
};

export default TermsAndConditions;
