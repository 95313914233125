import React, { useState } from 'react'
import AboutTrackFeedBack from './aboutTrackFeedback'
import AboutTrackMusicCredits from './aboutTrackMusicCredits'
import AboutTrackStatus from './aboutTrackStatus'


const AboutTrack = ({onAboutTrackClose, track, fromArtistPortal}) => {

    const [page, setPage] = useState('aboutTrackStatus');

    const handleNext = () => {
        switch (page) {
            // case 'aboutTrackMusicCredits':
            //     setPage('aboutTrackStatus');
            //     break;
            case 'aboutTrackStatus':
                setPage('aboutTrackFeedback');
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        switch (page) {
            case 'aboutTrackStatus':
                setPage('aboutTrackMusicCredits');
                break;
            case 'aboutTrackFeedback':
                setPage('aboutTrackStatus');
                break;
            default:
                break;
        }
    };
    return (
        <div>
            {/* {page === 'aboutTrackMusicCredits' && (
                <AboutTrackMusicCredits onNextBtn={handleNext} onClose = {onAboutTrackClose} />
            )} */}
            {page === 'aboutTrackStatus' && (
                <AboutTrackStatus onBackBtn={handleBack} onNextBtn={handleNext} onClose = {onAboutTrackClose} track = {track}  fromArtistPortal={fromArtistPortal}/>
            )}
            {page === 'aboutTrackFeedback' && (
                <AboutTrackFeedBack onBackBtn={handleBack} onClose={onAboutTrackClose} track={track} />
            )}

        </div>
    )
}

export default AboutTrack
