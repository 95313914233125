import React, { useState } from 'react'
import TrackSubmission from './trackSubmission'
import TrackSubmissionStatus from './trackSubmissionStatus'
import TrackSubmissionFeedback from './trackSubmissionFeedback'


const ViewSubmission = ({onClose}) => {

    const [page, setPage] = useState('trackSubmission');

    const handleNext = () => {
        switch (page) {
            case 'trackSubmission':
                setPage('trackSubmissionStatus');
                break;
            case 'trackSubmissionStatus':
                setPage('trackSubmissionFeedback');
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        switch (page) {
            case 'trackSubmissionStatus':
                setPage('trackSubmission');
                break;
            case 'trackSubmissionFeedback':
                setPage('trackSubmissionStatus');
                break;
            default:
                break;
        }
    };

    return (
        <div>
            {page === 'trackSubmission' && <TrackSubmission onNext={handleNext} onPopupClose = {onClose} />}
            {page === 'trackSubmissionStatus' && (
                <TrackSubmissionStatus onBack={handleBack} onNext={handleNext} onPopupClose = {onClose}  />
            )}
            {page === 'trackSubmissionFeedback' && (
                <TrackSubmissionFeedback onBack={handleBack} onPopupClose = {onClose}  />
            )}

        </div>
    )
}

export default ViewSubmission
