import React, { useState } from 'react';
// import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import SMLButton from '../../components/Shared/SMLButton/SMLButton';

import SMLButtonWithIcon from '../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon';
import SMLCheckbox from '../../components/Shared/SMLCheckbox/SMLCheckbox';
import SMLLabel from '../../components/Shared/SMLLabel/SMLLabel';
import SMLTextBox from '../../components/Shared/SMLTextBox/SMLTextBox';
import "../../styles/Fonts.scss";
import "../PlanContactInfo/PlanContactInfo.css"

import Steps from '../PlanSteps/PlanSteps';

import { MdOutlineClose } from "react-icons/md";
import Backbutton from '../../components/Shared/BackButton/BackButton';


const ContactInformation = (props) => {
    debugger;
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [createPassword, setCreatePassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [agreeConditions, setAgreeConditions] = useState('');
    const [fullNameError, setFullNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [createPasswordError, setCreatePasswordError] = useState('');
    const [verifyPasswordError, setVerifyPasswordError] = useState('');
    const navigate = useNavigate();

    const handleContactInformation = (e) => {
        e.preventDefault();

        // Validate Full Name
        if (!fullName.trim()) {
            setFullNameError('Full Name is required');
            return;
        }
        else {
            setFullNameError("")
        }


        // Validate email
        if (!email.trim()) {
            setEmailError('Email is required');
            return;
        }
        else {
            setEmailError("")
        }

        // Validate create password
        if (!createPassword.trim()) {
            setCreatePasswordError('Create Password is required');
            return;
        }
        else {
            setCreatePasswordError("")
        }

        // Validate verify password
        if (!verifyPassword.trim()) {
            setVerifyPasswordError('Verify Password is required');
            return;
        }
        else {
            setVerifyPasswordError("")
        }

        // Your signUp logic here
        console.log('Contact Information ...');
        console.log(props);
        debugger;
        props.onContactInformationClick();

    };

    const handleLoginClick = () => {
        navigate('/login')
    };
    const handleBackClick = () => {
        navigate('/login')
    }


    return (
        <div className='plan-signUp-container'>
            <div className='plan-signup-card'>
                <div className="plan-signUp-groupby">
                    <div className="plan-signUp-progress-steps">
                        <Steps step="3" />
                    </div>
                    <div className='plan-signUp-close-div'>
                        <MdOutlineClose />
                    </div>
                </div>
                <div className="plan-signUp-main">
                    <div className='plan-signUp-label-div'>
                        <SMLLabel value="Contact Information" style="Mainheader" />
                    </div>
                    <form className="plan-signUp-grid" onSubmit={handleContactInformation} >
                        <div className='plan-signUp-content'>
                            <div className='plan-signUp-controls'>
                                <div className='plan-signUp-fields'>
                                    <SMLLabel value="Full Name" style="title2"></SMLLabel>
                                    <SMLTextBox value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                    {fullNameError && <span className="button1 error">{fullNameError}</span>}
                                </div>
                                <div className='plan-signUp-fields'>
                                    <SMLLabel value="Email" style="title2"></SMLLabel>
                                    <SMLTextBox value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {emailError && <span className="button1 error">{emailError}</span>}
                                </div>
                                <div className='plan-signUp-fields'>
                                    <SMLLabel value="Create Password" style="title2"></SMLLabel>
                                    <SMLTextBox value={createPassword} onChange={(e) => setCreatePassword(e.target.value)} />
                                    {createPasswordError && <span className="button1 error">{createPasswordError}</span>}
                                </div>
                                <div className='plan-signUp-fields'>
                                    <SMLLabel value="Verify Password" style="title2"></SMLLabel>
                                    <SMLTextBox value={email} onChange={(e) => setVerifyPassword(e.target.value)} />
                                    {verifyPasswordError && <span className="button1 error">{verifyPasswordError}</span>}
                                </div>
                                <div className='plan-signUp-fields'>
                                    <div className="plan-signUp-groupby">
                                        <SMLCheckbox value={agreeConditions} onChange={(e) => setAgreeConditions(e.target.value)} />
                                        <div className="title2">
                                            I accept all <a className='link'>terms and conditions</a>
                                        </div>
                                    </div>

                                </div>
                                <div onClick={handleBackClick}><Backbutton /></div>

                            </div>
                        </div>
                        <div className="plan-signUp-content">
                            <div className="plan-signUp-controls">
                                <div className='plan-divider'></div>
                                <span>or</span>
                                <div className='plan-divider'></div>
                            </div>
                        </div>
                        <div className='plan-signUp-right-content'>
                            <div className='plan-signUp-controls'>
                                <div className='plan-signUp-fields'>
                                    <SMLButtonWithIcon type="button" value="Sign up with Google" icon="Google" />
                                </div>
                                <div className='plan-signUp-fields'>
                                    <SMLButtonWithIcon type="button" value="Sign up with Facebook" icon="Facebook" />
                                </div>
                                <div className='plan-signUp-fields'>
                                    <div className="plan-signUp-groupby">
                                        <SMLLabel value="Already a member?" style="title2"></SMLLabel>
                                        <button type="button" className='btn-no-margin button1' onClick={handleLoginClick}>Log in</button>
                                    </div>
                                </div>
                            </div>
                            <div className="plan-signUp-footer">
                                <div style={{ marginLeft: '220px' }}>
                                    <SMLButton style="SMLButton2 SMLButton-Orange" value="Continue" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>

    )
}

export default ContactInformation;