import React, { useCallback, useEffect, useState } from "react";
import Styles from "./TrackDetails.module.css";
import PlayIcon from "../../../assets/Icons/play.svg";
import PauseIcon from "../../../assets/Icons/pause.svg";
import SpeakerIcon from "../../../assets/Icons/speaker.svg";
import PreviousTrackIcon from "../../../assets/Icons/previous-track.svg";
import NextTrackIcon from "../../../assets/Icons/next-track.svg";
import { formatTime } from "../../../pages/Reviews/TrackUnderReview";
import { IoReload } from "react-icons/io5";
const TrackPlayer = ({ track, isPlaying, wavesurfer }) => {
  const [volume, setVolumne] = useState(0.75);

  const handleVolumneChange = (event) => {
    setVolumne(parseFloat(event.target.value));
  };

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause();
  }, [wavesurfer]);

  const onVolumeChange = useCallback(
    (value) => {
      wavesurfer && wavesurfer.setVolume(value);
    },
    [wavesurfer]
  );

  useEffect(() => {
    onVolumeChange(volume);
  }, [volume, onVolumeChange]);
  const onReload = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.stop();
      wavesurfer.on("error", (e) => {
        console.error("Error loading track:", e); // Log any errors loading the track
      });
    }
  }, [wavesurfer, track.url, volume]);
  return (
    <div className={Styles.trackPlayer}>
      <div className={Styles.trackInfo}>
        <h3>{track.title}</h3>
        <h6>{wavesurfer ? formatTime(wavesurfer.getDuration()) : "0:00"}</h6>
      </div>
      <div className={Styles.trackControls}>
        {/* <button onClick={onReload}>
          <IoReload
            height="24px"
            color="#a4a4a4"
            fontWeight="100"
            width="24px"
          />
        </button> */}
        <button onClick={onReload}>
          <img
            src={PreviousTrackIcon}
            alt="previous track"
            height="24px"
            width="24px"
          />
        </button>
        <button onClick={onPlayPause}>
          <img
            src={isPlaying ? PauseIcon : PlayIcon}
            alt="play"
            height="40px"
            width="40px"
          />
        </button>
        <button>
          <img
            src={NextTrackIcon}
            alt="next track"
            height="24px"
            width="24px"
          />
        </button>
      </div>
      <div className={Styles.additionalControls}>
        <img src={SpeakerIcon} alt="next track" height="16px" width="16px" />
        <div className={Styles.rangeContainer}>
          <input
            type="range"
            id="customRangeInput"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumneChange}
            className={Styles.customRange}
            style={{
              background: `linear-gradient(to right, #FF4405 0%, #FF4405 ${
                volume * 100
              }%, #57130A ${volume * 100}%, #57130A 100%)`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackPlayer;
