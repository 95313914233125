import React from 'react';
import '../../../styles/Fonts.scss';
import '../../../styles/HTMLControls.scss';
import './SMLOptionQuestion.css';

const SMLOptionQuestion = (props) => {
  return (
    <button
      className={props.style ? props.style : 'SMLSecondaryButton'}
      type={props.type ? props.type : 'Submit'}
      onClick={props.handleClickEvent}
      disabled={props.disabled}
    >
      <span
        className={props.valueStyle ? props.valueStyle : 'SMLSecondaryLabel'}
      >
        {props.value}
      </span>
    </button>
  );
};

export default SMLOptionQuestion;
