import React, { useEffect } from 'react';
import HomeLayout from '../../components/Layout/HomeLayout';
import TracksView from '../../components/common/tracks-view/TracksView';
import { useDispatch, useSelector } from 'react-redux';
import { Bars } from 'react-loader-spinner';
import { getAllTracksFetch } from '../../redux/actions/smlActions';
import { RESET_SEARCH } from '../../redux/actions/actionTypes';

export default function UploadedTracksPage() {
  const dispatch = useDispatch();
  const sml = useSelector((store) => store.sml);
  const tracksfromsearch = useSelector(
    (store) => store.search.searchedResult.result
  );
  const searchText = useSelector((store) => store.search.searchText); // Assuming you store the search text in Redux
  const tracks = useSelector(
    (state) => state.sml.allTracksFetched?.tracks || state.uploadTrack?.tracks
  );
  const loading = useSelector((store) => store.uploadTrack.isLoading);

  useEffect(() => {
    dispatch({ type: RESET_SEARCH });
    dispatch(getAllTracksFetch('submitted', 'artist_id'));
  }, [dispatch]);

  // const submittedTracks = Array.isArray(tracks)
  //   ? tracks.filter((track) => track.status.code === "submitted")
  //   : [];
  const hasSearchResults = tracksfromsearch && tracksfromsearch?.length > 0;
  const hasSearched = searchText && searchText.trim() !== ''; // Check if the search box is not empty

  return (
    <HomeLayout status="submitted">
      <TracksView
        headerLabel="Uploaded Tracks"
        tracks={hasSearchResults ? tracksfromsearch : hasSearched ? [] : tracks}
        noTracksMessage="No tracks found."
      />
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </HomeLayout>
  );
}
