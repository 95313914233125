
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // Corrected import
import api from "../api";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const putFinalFeedbackMsApi = async (data) => {
  try {
    // Extract properties from data
    // const {
    //   trackId, // Ensure trackId is a single string
    //   ratings,
    //   syncPotential,
    //   suggestedTargets,
    //   notes,
    //   downloadTrack, // Changed to match naming convention
    // } = data;

    // console.log('Data received:', data);
    console.log("data", data);
    // console.log(object)

    const trackId = data.track_id;
    console.log("track id", trackId);

    // const accessToken = Cookies.get('token');
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }

    // Decode the token to get any necessary information (if needed)
    const decodedToken = jwtDecode(accessToken);

    const url = `${BASE_URL}/feedback/${trackId}/master-supervisor/final`;

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      url: url,
      data: data,
    };

    const response = await api.request(config);

    console.log("Response data:", response);

    if (response.status !== 200) {
      console.error("Failed to post feedback. Status code:", response.status);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);
    throw error;
  }
};
