import React from "react";
import TracksViewHeader from "./TracksViewHeader";
import TracksViewList from "./TracksViewList";
import Styles from "./TracksViewStyles.module.css";

export default function TracksView({ headerLabel, tracks, headerLabelIcon }) {
  return (
    <div className={Styles.tracksViewContainer}>
      <TracksViewHeader
        headerLabel={headerLabel}
        tracks={tracks}
        headerLabelIcon={headerLabelIcon}
      />
      <TracksViewList tracks={tracks} />
    </div>
  );
}
