import axios from "axios";
// import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { refreshToken } from "./refreshToken";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const login = async ({ email, password, profile_type }) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, {
      email,
      password,
      profile_type,
    });

    const token = response.data.access_token;
    const refresh_token = response.data.refresh_token;
    const expiresIn = `${response.data.expires_in}`;
    const isMaster = response.data.profile.is_master;
    // const expirationTime = new Date();
    // expirationTime.setTime(expirationTime.getTime() + +expiresIn * 1000);
    // Cookies.set("token", token, { expires: expirationTime, secure: true });
    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refresh_token);
    const expirationTime = new Date().getTime() + expiresIn * 1000;
    localStorage.setItem("isMaster", isMaster);
    localStorage.setItem("token_expiration", expirationTime);
    localStorage.setItem("profile_type", response.data.profile_type);
    if (response.status === 200) return response.data;
    throw new Error(response.data.message || "Login issue");
  } catch (error) {
    // Handle other errors if needed
    console.log(error);
    throw error;
  }
};
