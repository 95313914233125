import React, { useEffect, useState } from 'react'
import closeIcon from '../../assets/Images/closeIcon.svg'
import SMLLabel from '../../components/Shared/SMLLabel/SMLLabel'
import SMLButton from '../../components/Shared/SMLButton/SMLButton'
import SMLCheckBox from '../../components/Shared/SMLCheckbox/SMLCheckbox'
import '../../styles/Fonts.scss'
import '../../pages/Feedback/feedback.css'
import '../../pages/LoginPage/LoginPage.css'

const Feedback = (props) => {
  const options = [
    { id: 'option1', label: 'Songwriter', value: 'songwriter' },
    { id: 'option2', label: 'Producer', value: 'producer' },
    { id: 'option3', label: 'Artist', value: 'artist' },
    { id: 'option4', label: 'Manager', value: 'manager' },
  ]

  const [selectedOptions, setSelectedOptions] = useState(props.selectedRoles)
  const [continueEnabled, setContinueEnabled] = useState(false)

  useEffect(() => {
    setContinueEnabled(props.selectedRoles.length > 0)
  }, [])

  const handleOptionChange = (value) => {
    let updatedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value]

    setSelectedOptions(updatedOptions)
    props.onSelectedRolesChange(updatedOptions)
    setContinueEnabled(updatedOptions.length > 0)
  }

  const handleContinueClick = () => {
    console.log(selectedOptions)
    console.log('selectedroles', props.selectedRoles)
    props.onFeedbackClick()
    console.log('Continue button clicked')
  }

  return (
    <div className="feedback-container">
      <div className="feedback-card">
        <div className="feedback-close-div" onClick={props.onCloseTrack}>
          <img src={closeIcon} style={{ cursor: 'pointer' }} alt="Close icon" />
        </div>
        <div className="feedback-main">
          <div className="feedback-label-div">
            <SMLLabel value="Submit Track For Feedback" style="Mainheader" />
          </div>
          <form className="feedback-content">
            <div className="feedback-fields">
              <SMLLabel
                value="What is your role in this track?"
                style="title2"
              />
              <SMLLabel
                value="Check all that apply"
                style="sub-subtitle2"
              />
              <div className="feedback-top-margin">
                {options.map((option) => (
                  <div 
                    className="feedback-groupby checkbox-wrapper" 
                    key={option.id}
                    onClick={() => handleOptionChange(option.label)}
                  >
                    <SMLCheckBox
                      id={option.id}
                      value={option.value}
                      checked={selectedOptions.includes(option.label)}
                      onChange={() => {}}
                      label="" // Remove the label from SMLCheckBox
                    />
                    <span className="checkbox-label">{option.label}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="feedback-btn-Bottom-right">
              <SMLButton
                style={`SMLButton2 SMLButton-Orange ${continueEnabled ? '' : 'disabled'}`}
                value="Continue"
                handleClickEvent={handleContinueClick}
                handleDisable={!continueEnabled}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Feedback