import React from "react";
import "./ParallaxRetain.css";
import apps from "../../../assets/Images/apps.svg";

const ParallaxRetain = () => {
    return (
        <div className="banner">
            <div className="text-container">
                <h1>Retain 100% ownership of your music.</h1>
                <p>
                    Our network of producers and music supervisors span all forms of media including these major players
                    and many more.
                </p>
            </div>
            <div className="scroll-container">
                <div className="logos">
                    <img src={apps} alt="frame" />
                    <img src={apps} alt="frame" />
                    <img src={apps} alt="frame" />
                    <img src={apps} alt="frame" />
                    <img src={apps} alt="frame" />
                    <img src={apps} alt="frame" />
                </div>
            </div>
        </div>
    );
};

export default ParallaxRetain;
