import React, { useEffect, useState } from "react";
import "./navbar.css";
import profile from "../../../assets/Images/red-Bg-Icon.svg";
import Login from "../../../pages/LoginPage/LoginPage";
import SignUpPage from "../../../pages/SignUpPage/SignUpPage";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, List, ListItem, ListItemText, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { artistSignupRemove } from "../../../redux/actions/authActions";
import SupervisionLogin from "../../../SupervisorPage/SupervisorLogin/SupervisorLogin";
import { Navigate, useNavigate } from "react-router-dom";

const Navbar = ({ onNavigate }) => {
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { artistSignedUp, artistSocialSignedIn } = useSelector((state) => state.auth)
    const [showDashboard, setShowDashboard] = useState(false)
    const showLoggedin = useSelector((state) => state.auth.artistShowLogin)
    const dispatch = useDispatch()
    //supervisor login temprovary button
    const [showSuperVisorLogin, setShowSuperVisorLogin] = useState(false);
    const openSuperVisorLogin = () => setShowSuperVisorLogin(true);
    const closeSuperVisorLogin = () => setShowSuperVisorLogin(false);
    const navigate = useNavigate()
    const showDashboardButton = () => {
        setShowDashboard(true)
    }
    const hideDashboardButton = () => {
        setShowDashboard(false)
    }
    useEffect(() => {
        const token = localStorage.getItem("token");
        const profile_type=localStorage.getItem("profile_type")
        if (token && profile_type==="artist") {
            showDashboardButton()
        } else {
            hideDashboardButton()
        }
    }, [])
    useEffect(() => {
        if (artistSignedUp || artistSocialSignedIn) {
            setShowLogin(true)
        }
    }, [showLoggedin])

    const openLoginModal = () => {
        setShowLogin(true);
    };

    const closeLoginModal = () => {
        setShowLogin(false);
    };

    const openSignUpModal = () => {
        setShowSignup(true);
        dispatch(artistSignupRemove())
    };

    const closeSignUpModal = () => {
        setShowSignup(false);
    };

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        {
            text: "About",
            onClick: () => {
                onNavigate("about");
                toggleDrawer(false)();
            },
        },
        {
            text: "How It Works",
            onClick: () => {
                onNavigate("howto");
                toggleDrawer(false)();
            },
        },
        {
            text: "Pricing",
            onClick: () => {
                onNavigate("pricing");
                toggleDrawer(false)();
            },
        },

        {
            text: "FAQ",
            onClick: () => {
                onNavigate("faq");
                toggleDrawer(false)();
            },
        },

        {
            text: "Terms",
            onClick: () => {
                onNavigate("footer");
                toggleDrawer(false)();
            },
        },

        {
            text: "Policy",
            onClick: () => {
                onNavigate("footer");
                toggleDrawer(false)();
            },
        },
    ];

    return (
        <>
            <header className="header">
                <div className="logo">
                    <img src={profile} alt="Company Logo" className="company-logo" />
                    <nav className="nav-buttons">
                        <div onClick={() => onNavigate("about")}>About</div>
                        <div onClick={() => onNavigate("howto")}>How It Works</div>
                        <div onClick={() => onNavigate("pricing")}>Pricing</div>
                        <div onClick={() => onNavigate("footer")}>Supervisor</div>
                    </nav>
                </div>
                <div className="auth-buttons">
                    {
                        showDashboard ? (
                            <button className="Login-Button" onClick={() => navigate("/dashboard")} style={{ background: "transparent" }}>
                                Dasboard
                            </button>
                        ) : (
                            <>
                                <button className="Login-Button" onClick={openLoginModal} style={{ background: "transparent" }}>
                                    Log In
                                </button>
                                <button className="signupbtn" onClick={openSignUpModal}>
                                    Sign Up
                                </button>
                            </>
                        )
                    }
                    {/* <button className="parallax-btn" style={{margin:"0px"}} onClick={openSuperVisorLogin}>
                        Supervisor Login
                    </button> */}
                </div>
                <div className="menu-icon" onClick={toggleDrawer(true)}>
                    <MenuIcon
                        sx={{
                            width: 40,
                            height: 40,
                        }}
                    />
                </div>
            </header>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    "& .MuiDrawer-paper": {
                        backgroundColor: "black",
                        color: "white",
                        width: 200,
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                    },
                }}
            >
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton onClick={toggleDrawer(false)} sx={{ color: "white" }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <List sx={{ flexGrow: 1 }}>
                    {menuItems.map((item, index) => (
                        <ListItem
                            key={index}
                            onClick={item.onClick}
                            sx={{
                                "&:hover": { backgroundColor: "#333" },
                                marginTop: index === 0 ? 2 : 0,
                            }}
                        >
                            <ListItemText primary={item.text} sx={{ color: "white" }} />
                        </ListItem>
                    ))}
                </List>
                <div>
                    <List>
                        <ListItem
                            onClick={() => {
                                openLoginModal();
                                toggleDrawer(false)();
                            }}
                            sx={{ "&:hover": { backgroundColor: "#333" } }}
                        >
                            <ListItemText primary="Log In" sx={{ color: "white" }} />
                        </ListItem>
                        <ListItem
                            onClick={() => {
                                openSignUpModal();
                                toggleDrawer(false)();
                            }}
                            sx={{ "&:hover": { backgroundColor: "#333" } }}
                        >
                            <ListItemText primary="Sign Up" sx={{ color: "white" }} />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            {showLogin && <Login onClose={closeLoginModal} />}
            {showSignup && <SignUpPage onClose={closeSignUpModal} />}
            {showSuperVisorLogin && <SupervisionLogin onClose={closeSuperVisorLogin} margin={"0"} />}

        </>
    );
};

export default Navbar;
