import React, { useEffect, useState } from 'react';
import FeedbackComponent from './Feedback';
import FeedbackStageTwoComponent from './FeedbackStageTwo';
import FeedbackStageThreeComponent from './FeedbackStageThree';
import FeedbackStageFourComponent from './FeedbackStageFour';
import FeedbackStageFiveComponent from './FeedbackStageFive';

function FeedbackForum({ onClose, audio, trackName, genreName }) {
  const [currentComponent, setCurrentComponent] = useState('feedback');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [workStatus, setWorkStatus] = useState('work_in_progress');
  const [isInProgress, setIsInProgress] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [syncedBefore, setSyncedBefore] = useState(false);
  const [samplesUsed, setSamplesUsed] = useState(false);
  const [sampleSources, setSampleSources] = useState([]);
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [targetOptions, setTargetOptions] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [askSamples, setAskSamples] = useState(false)
  const handlFeedbackClick = () => {
    setCurrentComponent('feedbackstagetwo');
  };
  // const handleSaveClickInFeedback = () => {
  //   setCurrentComponent("feedbackstagefive");
  // };
  const handleFeedbackStageTwoClick = () => {
    setCurrentComponent('feedbackstagethree');
  };
  // const handleSaveClickInFeedbackStageTwo = () => {
  //   setCurrentComponent("feedbackstagefive");
  // };
  const handleFeedbackStageThreeClick = () => {
    setCurrentComponent('feedbackstagefour');
  };
  // const handleSaveClickInFeedbackStageThree = () => {
  //   setCurrentComponent("feedbackstagefive");
  // };
  const handleFeedbackStageFourClick = () => {
    setCurrentComponent('feedbackstagefive');
  };

  const handleBack1 = () => {
    setCurrentComponent('feedback');
  };

  const handleBack2 = () => {
    setCurrentComponent('feedbackstagetwo');
  };

  const handleBack3 = () => {
    setCurrentComponent('feedbackstagethree');
  };
  const handleSelectedRolesChange = (roles) => {
    setSelectedRoles(roles);
  };
  const handleWorkStatus = (status) => {
    if (status === 'completed') {
      setWorkStatus('completed');
      setIsInProgress(false);
      setIsCompleted(true);
    } else if (status === 'work_in_progress') {
      setWorkStatus('work_in_progress');
      setIsInProgress(true);
      setIsCompleted(false);
    }
  };
  const handleSyncedBefore = () => {
    setSyncedBefore(!syncedBefore);
  };
  const handleSamplesUsed = () => {
    setSamplesUsed(!samplesUsed);
  };
  const handleSampleSources = (links) => {
    setSampleSources(links);
  };
  const handleFeedbackOptions = (options) => {
    setFeedbackOptions(options);
    console.log('feedback options', feedbackOptions);
  };
  const handleTargetOptions = (options) => {
    setTargetOptions(options);
    console.log('target options', targetOptions);
  };
  const handleAdditionalInfo = (info) => {
    setAdditionalInfo(info);
  };
  useEffect(() => {
    // sampleSources.forEach((item) => {
    console.log('value', additionalInfo);
    // });
  }, [additionalInfo]);
  const handleAskSamples = () => {
    setAskSamples(!askSamples)
  }
  // useEffect(() => {
  //   console.log("syncedBefore updated:", syncedBefore);
  // }, [syncedBefore]);
  // useEffect(() => {
  //   console.log("samplesused updated:", samplesUsed);
  // }, [samplesUsed]);
  return (
    <div>
      {currentComponent === 'feedback' && (
        <FeedbackComponent
          onFeedbackClick={handlFeedbackClick}
          // onSaveClick={handleSaveClickInFeedback}
          selectedRoles={selectedRoles}
          onCloseTrack={onClose}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          onSelectedRolesChange={handleSelectedRolesChange}
        />
      )}
      {currentComponent === 'feedbackstagetwo' && (
        <FeedbackStageTwoComponent
          onFeedbackClick={handleFeedbackStageTwoClick}
          // onSaveClick={handleSaveClickInFeedbackStageTwo}
          handleBackClick={handleBack1}
          onCloseTrack={onClose}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          isInProgress={isInProgress}
          isCompleted={isCompleted}
          handleWorkStatus={handleWorkStatus}
          syncedBefore={syncedBefore}
          samplesUsed={samplesUsed}
          handleSyncedBefore={handleSyncedBefore}
          handleSamplesUsed={handleSamplesUsed}
          handleSampleSources={handleSampleSources}
          askSamples = {askSamples}
          sampleSources={sampleSources}
          handleAskSamples = {handleAskSamples}
        />
      )}
      {currentComponent === 'feedbackstagethree' && (
        <FeedbackStageThreeComponent
          onFeedbackClick={handleFeedbackStageThreeClick}
          // onSaveClick={handleSaveClickInFeedbackStageThree}
          onCloseTrack={onClose}
          handleBackClick={handleBack2}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          feedbackOptions={feedbackOptions}
          targetOptions={targetOptions}
          handleFeedbackOptions={handleFeedbackOptions}
          handleTargetOptions={handleTargetOptions}
          additionalInfo={additionalInfo}
          handleAdditionalInfo={handleAdditionalInfo}
        />
      )}
      {currentComponent === 'feedbackstagefour' && (
        <FeedbackStageFourComponent
          onFeedbackClick={handleFeedbackStageFourClick}
          onCloseTrack={onClose}
          handleBackClick={handleBack3}
          audio={audio}
          trackName={trackName}
          genreName={genreName}
          selectedRoles={selectedRoles}
          workStatus={workStatus}
          syncedBefore={syncedBefore}
          samplesUsed={samplesUsed}
          sampleSources={sampleSources}
          feedbackOptions={feedbackOptions}
          targetOptions={targetOptions}
          additionalInfo = {additionalInfo}
        />
      )}
      {currentComponent === 'feedbackstagefive' && (
        <FeedbackStageFiveComponent onCloseTrack={onClose} />
      )}
    </div>
  );
}

export default FeedbackForum;
