import React from "react";
import ArrowLeft from "../../../assets/Icons/arrow-left.svg";
import Styles from "./TrackDetails.module.css";
import ArrowRight from "../../../assets/Icons/Vector-Arrow-Right.svg";

export default function TrackDetailsNavigation({ text, textIconSrc }) {
    if (text && textIconSrc) {
        return (
            <button className={Styles.trackDetailsNavigation}>
                <img src={ArrowLeft} alt="Go Back" />
                <span>{text}</span>
                {textIconSrc && <img src={textIconSrc} alt={text} />}
            </button>
        );
    } else {
        return (
            <button className={Styles.trackDetailsNavigation}>
                <img src={ArrowRight} alt="Go Back" />
            </button>
        );
    }
}
