import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import playIcon from "../../../assets/Images/playIcon.svg";
import pauseIcon from "../../../assets/Images/pauseIcon.svg";
import "./TrackSection.css";
import { useDispatch, useSelector } from "react-redux";
import { assignSuperVisorRequest } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";
import { RESET_ASSIGNED_SUPERVISOR } from "../../../redux/actions/actionTypes";
const TrackSection = ({
  track,
  handleTrackFeedback,
  trackImg,
  audioSrc,
  mediaRef,
  handleTimeUpdate,
  handleTogglePlay,
  isPlaying,
  progress,
  handleShowAssignPopup,
}) => {
  const trackID = track._id;
  const status = track.status.code;
  const isMaster = localStorage.getItem("isMaster");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentSup = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );
  const handleAssignMyslef = (trackID) => {
    dispatch(
      assignSuperVisorRequest({ trackId: trackID, supervisor: currentSup })
    );
  };
  const assigned = useSelector((state) => state.assignSupervisor.assigned);
  useEffect(() => {
    if (assigned && Object.keys(assigned).length > 0 && isMaster === "false") {
      navigate(`/TrackFeedback/${trackID}`);
      dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    }
  }, [assigned]);
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent the click from bubbling up
          handleTrackFeedback(trackID, status);
        }}
        className="trackSectionMain"
      >
        <Row className="mb-3 trackRow">
          <Col style={{ maxWidth: "111px", marginTop: "15px" }}>
            <div className="video-player">
              <img
                src={trackImg}
                className="trackImg"
                width={82}
                height={82}
                alt="Track"
              />
              <audio
                ref={mediaRef}
                src={audioSrc}
                onTimeUpdate={handleTimeUpdate}
                style={{ width: "100%" }}
                onClick={handleTogglePlay}
              />
              <div className="controls">
                {isPlaying ? (
                  <img src={pauseIcon} alt="Pause" onClick={handleTogglePlay} />
                ) : (
                  <img src={playIcon} alt="Play" onClick={handleTogglePlay} />
                )}
              </div>
            </div>
          </Col>
          <Col className="mt-3">
            <Row>
              <Col>
                <div>
                  <SMLLabel style="title1" value={track.title} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <SMLLabel style="float-right" value="0.00" />
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="sub-subtitle2" value={"Genre"} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="title" value={track.genre} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="sub-subtitle2 float-right" value="Status" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex align-items-end justify-content-end">
                <div>
                  <SMLLabel style="float-right" value={track.status.label} />
                </div>
              </Col>
            </Row>
          </Col>
          {track?.assigned_supervisor?.name && isMaster === "true" && (
            <Col
              className="mt-3 col-auto"
              style={{ marginLeft: "20px", marginRight: "20px" }}
            >
              <Row>
                <Col className="d-flex align-items-end justify-content-end">
                  <div>
                    <SMLLabel style="sub-subtitle2" value={"Assigned to"} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-end justify-content-end">
                  <div>
                    <SMLLabel
                      style="title"
                      value={track?.assigned_supervisor?.name}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              {!track?.assigned_supervisor?.name &&
                isMaster === "true" &&
                status === "submitted" && (
                  <SMLButton
                    style="SMLAssignBtn"
                    value="Assign"
                    handleClickEvent={(e) => {
                      e.stopPropagation(); // Prevent the click from bubbling up
                      handleShowAssignPopup(trackID);
                    }}
                    textColor={"#ff4405"}
                  />
                )}
            </Row>
          </Col>
          <Col
            className="mt-3 col-auto"
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <Row>
              {track?.assigned_supervisor?.name &&
                status === "submitted" &&
                isMaster === "false" && (
                  <SMLButton
                    style="SMLAssignBtn"
                    value="Start Assign"
                    handleClickEvent={(e) => {
                      e.stopPropagation(); // Prevent the click from bubbling up
                      // handleShowAssignPopup(trackID);
                      handleAssignMyslef(trackID);
                    }}
                    textColor={"#ff4405"}
                  />
                )}
            </Row>
          </Col>
        </Row>
        <Row>
          {/* <div className="progress">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div> */}
        </Row>
      </div>
    </>
  );
};

export default TrackSection;
