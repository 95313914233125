import React from 'react';
import back from '../../../assets/Images/backButton.svg';
import FbIcon from '../../../assets/Images/facebookIcon.svg';
import Google from '../../../assets/Images/googleIcon.svg';
import '../SMLButtonWithIcon/SMLButtonWithIcon.css';


const SMLButtonWithIcon = (props) => {
  var icons = "";
  switch (props.icon) {
    case "Google":
      icons = Google;
      break;
    case "Facebook":
      icons = FbIcon;
      break;
    case "Back":
      icons = back;
      break;
    default:
  }

  const handleClick = () => {
    // Call the onClick function passed from props, if provided
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <button className={`${props.className ? props.className : 'SMLSignupbutton'} ${props.gap?"gap":""}`} type={props.type ? props.type : 'Submit'} onClick={handleClick}>
      {icons && (
        <span className='sml-iconbtn fontcolorblack'>
          <img src={icons} alt="icon" />
        </span>
      )}
      <span className='sml-fontIcon'> {props.value}</span>
    </button>
  )
}

export default SMLButtonWithIcon
