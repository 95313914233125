import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev"; // Use the proxy base URL

// Function to fetch artist data
export const putArtist = async ({ updates }) => {
  try {
    // Retrieve the access token from cookies
     // const accessToken = Cookies.get('token');
     const accessToken = localStorage.getItem('token');
    // const accessToken = Cookies.get('token');
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    const artistId = decodedToken["custom:artist_id"];

    // Define the API endpoint for fetching artists
    const url = `${BASE_URL}/profiles/artists/${artistId}`;

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`, //
        "Content-Type": "application/json",
      },
      data: JSON.stringify(updates),
    };

    // Make the API request
    const response = await axios.request({ url, ...config });
    return response?.data; // Return artist's data
  } catch (error) {
    // Log and throw error
    console.error(
      "Error updating",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
