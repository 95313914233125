
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode'; // Corrected import
import api from '../api';

const BASE_URL = 'https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev';

export const postTrackApi = async ({
  audio,
  trackName,
  genreName,
  selectedRoles,
  workStatus,
  syncedBefore,
  samplesUsed,
  sampleSources,
  feedbackOptions,
  targetOptions,
  additionalInfo,
}) => {
  try {
    console.log('Track data:', { audio, trackName, genreName });

    // Retrieve the access token from cookies
    // const accessToken = Cookies.get('token');
    const accessToken = localStorage.getItem('token');

    if (!accessToken) {
      console.error('No token found. Please log in.');
      return;
    }

    // Decode the token to get artistId
    const decodedToken = jwtDecode(accessToken); // Fixed variable name

    const artistId = decodedToken['custom:artist_id'];

    let data = new FormData();
    data.append("artist_id", artistId);
    data.append('title', trackName);
    data.append('genre', genreName);
    data.append('status', workStatus);
    data.append('roles', selectedRoles);
    data.append('work_status', workStatus);
    data.append('synced_before', syncedBefore);
    data.append('samples_used', samplesUsed);
    data.append('sample_sources', sampleSources);
    data.append('feedback_services', feedbackOptions);
    data.append('targets', targetOptions);
    data.append('additional_info', additionalInfo);
    data.append('file', audio);

    console.log(accessToken, data); // Log token for debugging

    const url = `${BASE_URL}/tracks`;

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    console.log('Config:', config); // Log the config to inspect it

    const response = await api.post(url, data, config);

    console.log(response.data);

    if (response.status !== 201) {
      console.error(
        'Failed to upload the track. Status code:',
        response.status
      );
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request data:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    console.error('Error config:', error.config);
    throw error;
  }
};
