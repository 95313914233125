import React, { useEffect, useState } from 'react';

const TawkChat = () => {
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    // Function to minimize the chat window once
    const minimizeChat = () => {
      if (typeof Tawk_API !== 'undefined' && Tawk_API.minimize && !isMinimized) {
        Tawk_API.minimize(); // Minimize the chat window
        setIsMinimized(true); // Set flag to prevent future minimizations
      } else if (!isMinimized) {
        // Retry after a short delay if Tawk_API is not ready
        setTimeout(minimizeChat, 500);
      }
    };

    // Load the Tawk.to script
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();

    (function () {
      var s1 = document.createElement('script');
      var s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66dba20450c10f7a00a506f9/1i74v1o58';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();

    // Minimize chat once after the script loads
    minimizeChat();
  }, [isMinimized]);

  return null;
};

export default TawkChat;
