import React, { useState } from 'react'
import TrackCard from './TrackCard'
import Styles from './TracksViewStyles.module.css'
import NoTracks from './NoTracks'

export default function TracksViewList({ tracks }) {
  // const trackList = Array.isArray(tracks) ? tracks : [];
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState()

  const onTrackPlay = (index) => {
    setCurrentPlayingIndex(index)
  }
  console.log(tracks)
  return (
    <div className={Styles.trackListContainer}>
      <>
        {
          tracks?.map((item, index) => {
            return (
              <TrackCard
                key={index}
                track={item}
                onPlay={() => onTrackPlay(index)}
                isStopped={currentPlayingIndex !== index}
              />
            )
          })}
      </>
    </div>
  )
}
