import React from "react";
import "./uploadYourTracks.css";
import musicComposer from "../../../assets/Images/musicComposer.svg";

const UploadYourTracks = () => {
    return (
        <div className="uploadTracks-wrapper">
            <div className="uploadTracks-rectangle">
                <div className="uploadTracks-group">
                    <img src={musicComposer} className="uploadTracks-img" alt="Music Composer" />
                    <div className="uploadTracks-container">
                        <button className="uploadTracks-btn">Step 1</button>
                        <div className="uploadTracks-title">Upload Your Tracks.</div>
                        <div className="uploadTracks-content subtitle3">
                            Submit your demos or masters each month. If your tracks are on point, we’ll pitch them for
                            free, or we will do what it takes to get you sync-ready.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadYourTracks;
