import React from "react";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "./SMLTextBox.css";
function SMLTextBox(props) {
  return (
    <div className="sml-textbox-wrapper" style={{width:props.width,left:props.left}}>
      <input
        className="form-control SMLTextbox"
        value={props.value}
        type={props.type}
        onChange={props.onChange}
        placeholder={props.placeHolder ? props.placeHolder : ""}
        style={{width:props.widthInput}}
      />
      {props.imgSrc && (
        <img
          src={props.imgSrc}
          alt="Remove"
          onClick={props.onImgClick}
          className="remove-link-icon" // Ensure you have appropriate styling for this class
        />
      )}
    </div>
  );
}

export default SMLTextBox;
