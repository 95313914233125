import { call, put, takeLatest } from 'redux-saga/effects';
import { DOWNLOAD_TRACK_LOADING, DOWNLOAD_TRACK_REQUEST } from '../actions/actionTypes';
import {
  downloadTrackFailure,
  downloadTrackSuccess,
} from '../actions/downloadTrackActions';
import { downloadTrackApi } from '../../api/track/downloadTrackApi';
function* downloadTrack(action) {
  try {
    const trackId = action.payload;
    yield put({ type: DOWNLOAD_TRACK_LOADING });
    console.log('calling download track');
    const result = yield call(downloadTrackApi, trackId); // Pass the function and its argument separately
    yield put(downloadTrackSuccess(result));
  } catch (error) {
    yield put(downloadTrackFailure(error.message));
  }
}
export function* downloadTrackSaga() {
    yield takeLatest(DOWNLOAD_TRACK_REQUEST, downloadTrack);
  }
  