import { SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE } from './actionTypes';

export const searchRequest = (query, status) => {
  return {
    type: SEARCH_REQUEST,
    payload: { query, status },
  };
};
// export const searchSuccess = (result) => {
//   return {
//     type: SEARCH_SUCCESS,
//     payload: result,
//   };
// };
// export const searchFailure = (error) => {
//   return {
//     type: SEARCH_FAILURE,
//     payload: error,
//   };
// };
