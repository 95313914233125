import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import 'react-toastify/dist/ReactToastify.css';

import SMLLabel from '../../components/Shared/SMLLabel/SMLLabel';
import '../../styles/Fonts.scss';

import SMLTextBoxWithLabel from '../../components/Shared/SMLTextBoxWithLabel/SMLTextBoxWithLabel';
import SMLTextBox from '../../components/Shared/SMLTextBox/SMLTextBox';
import SMLButton from '../../components/Shared/SMLButton/SMLButton';
import { Button } from '@mui/material';
import SMLButtonWithIcon from '../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon';
import SMLLinkButton from '../../components/Shared/SMLLinkButton/SMLLinkButton';
import { BiQuestionMark } from 'react-icons/bi';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { MdOutlineClose, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { loginRequest, socialLoginRequest, supSignupRemove } from '../../redux/actions/authActions';
import SignUpPage from '../../pages/SignUpPage/SignUpPage';
import closeIcon from "../../assets/Images/closeIcon.svg"

import '../SupervisorLogin/SupervisorLogin.css';
import SMLErrorBox from '../../components/Shared/SMLErrorBox/SMLErrorBox';
import { Modal } from '@mui/material';
import { Bars } from 'react-loader-spinner';
import SupervisiorPage from '../SupervisorPage';
import { GoogleLogin } from '@react-oauth/google';

const SupervisionLogin = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [signUpShow, setSignUpShow] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile_type = 'supervisor';

  const state = useSelector((state) => state);
  // console.log(state);

  const { user, loading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && user.profile_type === "supervisor") {
      localStorage.setItem('username', user.name);
      localStorage.setItem('emails', user.email);
      navigate('/supervisorDashboard', { state: { username: user.name } });
    }
  }, [user, navigate]);
  const handleGoogleLogin = (credentialResponse) => {
    // Dispatch action with Google credential
    console.log(credentialResponse.credential);
    dispatch(socialLoginRequest(
      credentialResponse.credential,
      'google',
      'supervisor',
    ));
  }
  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);

  useEffect(() => {
    setLoginError(false);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    // Validate email
    if (!email.trim()) {
      setEmailError('Email is required');
      return;
    } else {
      setEmailError('');
    }

    // Validate password
    if (!password.trim()) {
      setPasswordError('Password is required');
      return;
    } else {
      setPasswordError('');
    }

    if (email.toLowerCase() == 'sml' && password.toLowerCase() == 'sml') {
      navigate('/supervisorDashboard');
    }

    if (error === 'Login failed') setLoginError(true);
    else setLoginError(false);

    dispatch(loginRequest(email, password, profile_type));
  };

  const handleSignUpClick = () => {
    dispatch(supSignupRemove())
    setSignUpShow(true);
  };

  return signUpShow ? (
    <Modal open={true}>
      <SupervisiorPage onClose={onClose} />
    </Modal>
  ) : (
    <Modal open={true}>
      <div className="superVisor-login-container effects-container content">
        <div className="superVisor-login-card">
          <div className="superVisor-Login-close-div" onClick={onClose}>
            <img
              src={closeIcon}
              style={{ cursor: "pointer" }}
              alt="Close"
            />
          </div>
          <div className="superVisor-login-main">
            <div className="superVisor-login-label-div">
              <SMLLabel value=" Supervisor Log In" style="Mainheader sup" />
            </div>
            <form className="superVisor-Login-content" onSubmit={handleLogin}>
              {loading && (
                <div className="spinner-overlay">
                  <Bars
                    height="80"
                    width="80"
                    color="#23f0c7"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
              <div className="superVisor-login-controls">
                <div className="superVisor-login-fields">
                  {loginError && <SMLErrorBox message={error}></SMLErrorBox>}
                  <SMLLabel value="Email" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <span className="button1 error">{emailError}</span>
                  )}
                </div>
                {/* <div className="superVisor-login-fields">
                  <div className="superVisor-login-groupby">
                    <label className="title2">Password</label>
                    <SMLLinkButton
                      value={
                        <span className="forgotPassword">
                          Forgot Password{" "}
                          <BiQuestionMark className="questionMark" />
                        </span>
                      }
                    ></SMLLinkButton>
                  </div>
                  <SMLTextBox
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && (
                    <span className="button1 error">{passwordError}</span>
                  )}

                  <SMLLinkButton value="remember me"></SMLLinkButton>
                </div> */}

                {/* BEGIN */}
                <div className="superVisor-login-fields">
                  <div className="superVisor-login-groupby">
                    <label className="title2">Password</label>
                    <SMLLinkButton
                      value={
                        <span className="forgotPassword">
                          Forgot Password{' '}
                          <BiQuestionMark className="questionMark" />
                        </span>
                      }
                    ></SMLLinkButton>
                  </div>
                  <div className="passwordfield">
                    <SMLTextBox
                      value={password}
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="toggle-password-visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    </span>
                  </div>
                  {passwordError && (
                    <span className="button1 error">{passwordError}</span>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    {' '}
                    <input
                      style={{ height: '14px', width: '14px' }}
                      type="checkbox"
                      id="Remember"
                    ></input>
                    <label
                      className="forgotPassword sub-subtitle2"
                      for="Remember"
                    >
                      Remember Me
                    </label>
                  </div>
                </div>

                {/* END */}
              </div>
              <div className="superVisor-login-controls1">
                <div className="superVisor-login-fields1">
                  <SMLButton style="SMLButton1" value="Log In" />
                  <div className="superVisor-login-groupby">
                    <div className="div-line"></div>
                    <p>or</p>
                    <div className="div-line"></div>
                  </div>
                </div>

                <div className="superVisor-login-fields1">
                  <div className="superVisor-login-groupbyBtns">
                    {/* <SMLButtonWithIcon
                      type="button"
                      value="Log in with Google"
                      icon="Google"
                    /> */}
                    <GoogleLogin
                      onSuccess={codeResponse => {
                        handleGoogleLogin(codeResponse)
                      }}
                      onError={() => console.log('Login Failed')}
                      setOneTap={false}
                      text="signup_with"
                      size="large"
                      width="191"
                    />
                    <SMLButtonWithIcon
                      type="button"
                      value="Sign up with Facebook"
                      icon="Facebook"
                    />
                  </div>

                  <div className="supervisor-login-card-end">
                    <div className="supervisor-login-groupbyBtns">
                      <span>Not a member?</span>

                      <button
                        className="btn-no-margin button1"
                        type="button"
                        onClick={handleSignUpClick}
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SupervisionLogin;
