
// import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import api from "../api";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const getTrackStatsApi = async (type) => {
  try {
    // const accessToken = Cookies.get("token");
    const accessToken = localStorage.getItem("token");

    if (!accessToken) {
      console.error("No access token found");
      return;
    }

    const decodedToken = jwtDecode(accessToken);
    const artistId = decodedToken["custom:artist_id"];
    const url = `${BASE_URL}/tracks/stats/${type}`;

    // const url = ${BASE_URL}/tracks/stats?artist_id=507f1f77bcf86cd799439011;

    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
    };

    const response = await api.get(url, config);

    console.log("Response Data from tracks:", response.data);

    return response.data;
  } catch (error) {
    console.error(
      "Error fetching tracks:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};