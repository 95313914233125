import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SMLButton from '../../../components/Shared/SMLButton/SMLButton'
import SMLButtonWithIcon from '../../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon'
import SMLCheckbox from '../../../components/Shared/SMLCheckbox/SMLCheckbox'
import SMLLabel from '../../../components/Shared/SMLLabel/SMLLabel'
import SMLTextBox from '../../../components/Shared/SMLTextBox/SMLTextBox'
import '../../../styles/Fonts.scss'
import '../../SignUpPage/SignUpPage.css'
import ProgressSteps from '../ProgressSteps/ProgressSteps'
import { MdOutlineClose, MdVisibility, MdVisibilityOff } from 'react-icons/md'
import Backbutton from '../../../components/Shared/BackButton/BackButton'
import TermsAndConditions from '../../../components/UI/TermsAndConditions/TermsAndCondition'
import { signupRequest, socialSignupRequest } from '../../../redux/actions/authActions'
import Login from '../../LoginPage/LoginPage';
import Cookies from 'js-cookie';
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from '@greatsumini/react-facebook-login'
import { APPCONFIG } from '../../../config/APPConfig'
import closeIcon from "../../../assets/Images/closeIcon.svg"
const ContactInformation = (props) => {
  const [fullName, setFullName] = useState(props.contactData.fullName)
  const [email, setEmail] = useState(props.contactData.email)
  const [createPassword, setCreatePassword] = useState(
    props.contactData.createPassword
  )
  const [verifyPassword, setVerifyPassword] = useState(
    props.contactData.verifyPassword
  )
  const [agreeConditions, setAgreeConditions] = useState(props.contactData.agreeConditions)
  const [fullNameError, setFullNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [createPasswordError, setCreatePasswordError] = useState('')
  const [verifyPasswordError, setVerifyPasswordError] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showCreatePassword, setShowCreatePassword] = useState(false) // State for toggling create password visibility
  const [showVerifyPassword, setShowVerifyPassword] = useState(false) // State for toggling verify password visibility
  const [contactData, setContactData] = useState([])
  const [showLogin, setShowLogin] = useState(false)

  // const dispatch = useDispatch();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  // const auth = useSelector((state) => state.auth);
  // useEffect(() => {
  //   if (auth.user && !auth.loading && !auth.error) {
  //     props.onContactInformationClick(fullName, email, createPassword);
  //   }
  // }, [auth.user, auth.loading, auth.error]);

  const handleContactInformation = (e) => {
    e.preventDefault()

    // Validate Full Name
    if (!fullName.trim()) {
      setFullNameError('Full Name is required')
      return
    } else {
      setFullNameError('')
    }

    // Validate email
    if (!email.trim()) {
      setEmailError('Email is required')
      return
    } else if (!validateEmail(email)) {
      setEmailError('Invalid email format')
      return
    } else {
      setEmailError('')
    }
    // Validate create password
    if (!createPassword.trim()) {
      setCreatePasswordError('Create Password is required')
      return
    } else {
      setCreatePasswordError('')
    }

    // Validate verify password
    if (!verifyPassword.trim()) {
      setVerifyPasswordError('Verify Password is required')
      return
    } else {
      setVerifyPasswordError('')
    }

    // Check if passwords match
    if (createPassword !== verifyPassword) {
      setVerifyPasswordError('Passwords do not match')
      return
    } else {
      setVerifyPasswordError('')
    }

    const contactData = {
      fullName,
      email,
      createPassword,
      verifyPassword,
      agreeConditions,
    }
    // setContactData(fullName, email, createPassword);
    // dispatch(signupRequest(fullName, email, createPassword));
    console.log('Contact data', contactData)
    console.log('Contact Information ...')
    console.log(props)
    props.onContactInformationClick(contactData)
  }

  const handleLoginClick = () => {
    // navigate("/login");
    setShowLogin(true)
  }
  // useEffect(() => {
  //   const hash = window.location.hash

  //   if (hash && (hash.includes('access_token') || hash.includes('id_token'))) {
  //     const params = new URLSearchParams(hash.replace('#', '?'))
  //     const idToken = params.get('id_token')
  //     const accessToken = params.get('access_token')

  //     if (idToken) {
  //       // Set the id_token in a cookie
  //       Cookies.set('id_token', idToken, {
  //         path: '/',
  //         secure: true,
  //         sameSite: 'Strict',
  //       })
  //       console.log('ID Token stored in cookie:', idToken)
  //     }
  //     if (accessToken) {
  //       // Set the access_token in a cookie
  //       Cookies.set('access_token', accessToken, {
  //         path: '/',
  //         secure: true,
  //         sameSite: 'Strict',
  //       })
  //       console.log('Access Token stored in cookie:', accessToken)
  //     }

  //     // Redirect to /dashboard
  //     props.onContactInformationClick(contactData)
  //   }
  // }, [props.onContactInformationClick])
  const handleFacebookSignup = (credentialResponse) => {
    // Dispatch action with Google credential
    console.log(credentialResponse.credential);
    const token = {
      credential: credentialResponse.credential,
      provider: "facebook",
      profile_type: "artist"
    };
    if (token) {
      props.onContactInformationClick(token);
    }
  }
  const handleGoogleSignup = (credentialResponse) => {
    console.log("new", credentialResponse.credential);
    const token = {
      credential: credentialResponse.credential,
      provider: "google",
      profile_type: "artist"
    };
    if (token) {
      props.onContactInformationClick(token);
    }
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: handleGoogleSignup,
  //   onError: () => console.log('Login Failed'),
  //   flow: 'implicit',
  // });

  const signup = () => {
    const callbackUrl = `${window.location.origin}/login`
    const googleClientId =
      '874371419611-9brm4hqjvlscomnub6dtr8b01r60ta44.apps.googleusercontent.com'
    const targetUrl = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${encodeURIComponent(
      callbackUrl
    )}&response_type=token%20id_token&client_id=${googleClientId}&scope=openid%20email%20profile`
    window.location.href = targetUrl
  }
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return showLogin ? (
    <Login onClose={props.closePopup} />
  ) : (
    <div className="signUp-container">
      <div className="signup-card">
        <div className="signUp-groupby">
          <div className="signUp-progress-steps">
            <ProgressSteps step="1" />
          </div>
          <div className="signUp-close-div" onClick={props.closePopup}>
            <img
              src={closeIcon}
              style={{ cursor: "pointer" }}
              alt="Close"
            />
          </div>
        </div>
        <div className="signUp-main">
          <div className="signUp-label-div">
            <SMLLabel value="Contact Information" style="Mainheader" />
          </div>
          <form className="signUp-grid" onSubmit={handleContactInformation}>
            <div className="signUp-content">
              <div className="signUp-controls">
                <div className="signUp-fields">
                  <SMLLabel value="Full Name" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  {fullNameError && (
                    <span className="button1 error">{fullNameError}</span>
                  )}
                </div>
                <div className="signUp-fields">
                  <SMLLabel value="Email" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <span className="button1 error">{emailError}</span>
                  )}
                </div>
                <div className="signUp-fields">
                  <SMLLabel value="Create Password" style="title2"></SMLLabel>
                  <div className="password-container">
                    <SMLTextBox
                      type={showCreatePassword ? 'text' : 'password'}
                      value={createPassword}
                      onChange={(e) => setCreatePassword(e.target.value)}
                    />
                    <span
                      className="toggle-password-visibility"
                      onClick={() => setShowCreatePassword(!showCreatePassword)}
                    >
                      {showCreatePassword ? (
                        <MdVisibilityOff />
                      ) : (
                        <MdVisibility />
                      )}
                    </span>
                  </div>
                  {createPasswordError && (
                    <span className="button1 error">{createPasswordError}</span>
                  )}
                </div>
                <div className="signUp-fields">
                  <SMLLabel value="Verify Password" style="title2"></SMLLabel>
                  <div className="password-container">
                    <SMLTextBox
                      type={showVerifyPassword ? 'text' : 'password'}
                      value={verifyPassword}
                      onChange={(e) => setVerifyPassword(e.target.value)}
                    />
                    <span
                      className="toggle-password-visibility"
                      onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                    >
                      {showVerifyPassword ? (
                        <MdVisibilityOff />
                      ) : (
                        <MdVisibility />
                      )}
                    </span>
                  </div>
                  {verifyPasswordError && (
                    <span className="button1 error">{verifyPasswordError}</span>
                  )}
                </div>
                <div className="signUp-fields">
                  <div className="signUp-groupby">
                    <SMLCheckbox
                      checked={agreeConditions} // updated to reflect the boolean state
                      onChange={(e) => setAgreeConditions(e.target.checked)}
                    />
                    <div className="title2">
                      I accept all{' '}
                      <a className="link" onClick={openModal}>
                        terms and conditions
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div onClick={handleBackClick}><Backbutton /></div> */}
              </div>
            </div>
            <div className="signUp-content">
              <div className="signUp-controls">
                <div className="divider"></div>
                <span>or</span>
                <div className="divider"></div>
              </div>
            </div>
            <div className="signUp-right-content">
              <div className="signUp-controls">
                <div className="signUp-fields" style={{ "padding": "0", "textAlign": "center" }}>
                  {/* <SMLButtonWithIcon
                    type="button"
                    value="Sign up with Google"
                    icon="Google"
                    onClick={() => googleLogin()}
                  /> */}
                  <GoogleLogin
                    className="google-login-button"
                    onSuccess={codeResponse => {
                      console.log(codeResponse);
                      handleGoogleSignup(codeResponse)

                    }}
                    onError={() => console.log('Login Failed')}
                    // text='signup_with'
                    width="232"
                    setOneTap={false}
                  />
                  {/* <SMLButtonWithIcon
                    type="button"
                    value="Sign up with Google"
                    icon="Google"
                    onClick={() => signup()}
                  /> */}
                </div>
                {/* <div className="signUp-fields">
                  <SMLButtonWithIcon
                    type="button"
                    value="Sign up with Facebook"
                    icon="Facebook"
                  />
                </div> */}
                <FacebookLogin
                  appId={APPCONFIG.FACEBOOK_APP_ID}
                  fields="name,email,picture"
                  callback={handleFacebookSignup}
                  render={(renderProps) => (
                    <SMLButtonWithIcon
                      type="button"
                      value="Sign up with Facebook"
                      icon="Facebook"
                      onClick={() => {
                        renderProps.onClick();
                      }}
                      gap="gap"
                    />
                  )}
                />
                <div className="signUp-fields">
                  <div className="signUp-groupby">
                    <SMLLabel
                      value="Already a member?"
                      style="title2"
                    ></SMLLabel>
                    <button
                      type="button"
                      className="btn-no-margin button1"
                      onClick={handleLoginClick}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </div>
              <div className="signUp-footer">
                <div style={{ marginLeft: '220px' }}>
                  <SMLButton
                    style={`SMLButton2 SMLButton-Orange ${agreeConditions ? '' : 'disabled'
                      }`}
                    value="Next"
                    handleDisable={!agreeConditions} // Disabling button based on checkbox state
                  />
                </div>
              </div>
            </div>
          </form>
          <TermsAndConditions
            isOpen={isModalOpen}
            onClose={closeModal}
            title="Terms and Conditions"
          >
            {/* Modal content */}
          </TermsAndConditions>
        </div>
      </div>
    </div>
  )
}

export default ContactInformation
