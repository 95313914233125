import React, { useEffect, useState } from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import StyledHeader from "../../components/common/styled-components/StyledHeader";
import Styles from "./Dashboard.module.css";
import BannerImage from "../../assets/Images/banner-image.svg";
import RightArrow from "../../assets/Icons/right-arrow.svg";
import UploadIcon from "../../assets/Icons/upload-icon.svg";
import SMLCard from "../../components/common/styled-components/SMLCard";
import SMLCard2 from "../../components/common/styled-components/SMLCard2";
import TrackIcon from "../../assets/Icons/track-icon.svg";
import FeedbackIcon from "../../assets/Icons/feedback.svg";
import ReviewTrackIcon from "../../assets/Icons/review-tracks.svg";
import UploadedTracksIcon from "../../assets/Icons/uploaded-tracks.svg";
import UnderReviewIcon from "../../assets/Icons/under-review.svg";
import UploadTrack from "../../components/Shared/UploadTrack/UploadTrack";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import ReactPlayer from "react-player";
import video from "../../assets/video/ShannonIntro12.mp4";
import thumnail from "../../assets/Images/thumbnail.png";
import {
  feedbackReceivedFetch,
  getAllArtistFetch,
  // getFeedBackReceivedTracks,
  // getUnderReviewTracks,
  inReviewTracksFetch,
} from "../../redux/actions/smlActions";
import { updateArtistRequest } from "../../redux/actions/smlActions";
import { getTrackStats } from "../../redux/actions/trackStats";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { getAllTracksFetch } from "../../redux/actions/smlActions";
import { RESET_SEARCH } from "../../redux/actions/actionTypes";
import { useNavigate } from "react-router-dom";
import { artistSignupRemove } from "../../redux/actions/authActions";
export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [track, setTrack] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  dispatch(artistSignupRemove())
  const handleTrackChange = (e) => {
    setTrack(e.target.files[0]);
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  useEffect(() => {
    dispatch({ type: RESET_SEARCH });
    dispatch(getAllTracksFetch("submitted", "artist_id"));
    dispatch(inReviewTracksFetch("in_review", "artist_id"));
    dispatch(feedbackReceivedFetch("final_feedback_submitted", "artist_id"));
  }, [dispatch]);

  const submittedTracks = useSelector(
    (store) => store.sml.allTracksFetched.tracks
  );
  const inReviewTracks = useSelector(
    (store) => store.sml.underReviewTracks?.tracks
  );
  const feedbackReceievedTracks = useSelector(
    (store) => store.sml.feedbackReceivedTracks?.tracks
  );
  // useEffect(() => {
  // }, [dispatch]);
  // const inReviewTracks = useSelector(
  //   (store) => store.sml.allTracksFetched.tracks
  // );
  console.log("tracks11", submittedTracks);

  const sml = useSelector((store) => store.sml);
  const trackStats = useSelector(
    (store) => store.trackStats.statsFetched.stats
  );
  const loading = trackStats ? false : true;

  const { underReviewTracks, feedbackReceivedTracks } = sml;

  useEffect(() => {
    // dispatch(getFeedBackReceivedTracks());
    // dispatch(getUnderReviewTracks());
    dispatch(getTrackStats("artist"));
    // dispatch(updateArtistRequest());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch({ type: RESET_SEARCH });
  //   dispatch(getAllTracksFetch('in_review', 'artist_id'));
  // }, [dispatch]);

  const tracksUnderReview = useSelector((store) => store.sml);
  console.log("tracks12", tracksUnderReview);

  const artistId = useSelector((state) => state.auth.artistId);
  const artists =
    useSelector((store) => store.sml.allArtistFetched?.artists) || [];
  console.log("artists : ", artists);

  useEffect(() => {
    if (artists.length === 0) {
      dispatch(getAllArtistFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    if (trackStats) {
      setStats(trackStats);
      console.log(stats);
    }
  }, [trackStats]);
  const handleUploadedTrackClick = () => {
    navigate("/uploadedTrack");
  };
  const handleInReviewClick = () => {
    navigate("/Review");
  };
  const handleFeedbackSubmittedClick = () => {
    navigate("/FeedbackReceived");
  };
  return (
    <HomeLayout applyScroll = {true} >
      <StyledHeader>Dashboard</StyledHeader>
      <div className={Styles.dashboardContainer}>
        <BannerCard artists={artists} />
        <UploadCard onClick={togglePopup} />
        {isPopupOpen && <UploadTrack onPopupClose={togglePopup} />}

        <VideoCard />

        <div className={Styles.dashboardSubContainer}>
          <StatusCard
            onClick={handleUploadedTrackClick}
            imageSrc={UploadedTracksIcon}
            label="Uploaded Tracks"
            stats={stats?.submitted}
          />
          <TracksCard
            tracks={submittedTracks}
            status="submitted"
            // secondaryLabel={'Submit more tracks for Review!'}
          />
          <StatusCard
            onClick={handleInReviewClick}
            imageSrc={ReviewTrackIcon}
            label="Tracks Under Review !"
            stats={stats?.under_review}
          />
          <TracksCard
            tracks={inReviewTracks}
            status="under review"
            // secondaryLabel={'Submit more tracks for review'}
          />
          <StatusCard
            onClick={handleFeedbackSubmittedClick}
            imageSrc={FeedbackIcon}
            label="Feedback Received"
            stats={stats?.feedback_received}
          />
          <TracksCard
            tracks={feedbackReceievedTracks}
            status="feedback received"
            // secondaryLabel={'Submit a track for review'}
          />
        </div>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </HomeLayout>
  );
}

const BannerCard = ({ artists }) => {
  return (
    <SMLCard className={Styles.bannerContainer}>
      <img
        alt="banner"
        src={BannerImage}
        className={Styles.bannerBackgroundImage}
      />
      <div className="bannerContainerHeading">
        <h3>
          {artists?.name}
          {" hub"}
        </h3>
        {/* <h5> Banner Subtitle Here</h5> */}
      </div>
      <div>
        <img alt="right arrow" src={RightArrow} />
      </div>
    </SMLCard>
  );
};

const UploadCard = ({ onClick }) => {
  return (
    <SMLCard className={Styles.uploadContainer} onClick={onClick}>
      <div onClick={onClick}>
        {/* <input type="file" accept="audio/*" onChange={handleTrackChange} /> */}
        <img alt="upload" src={UploadIcon} onClick={onClick} />
      </div>
      <div>
        <h2>Upload New Track</h2>
      </div>
    </SMLCard>
  );
};

const VideoCard = () => {
  const playerWrapperRef = React.useRef(null);

  React.useEffect(() => {
    const resizePlayerWrapper = () => {
      if (playerWrapperRef.current) {
        const width = playerWrapperRef.current.offsetWidth;
        playerWrapperRef.current.style.height = `${width * (9 / 16)}px`;
      }
    };

    resizePlayerWrapper();
    window.addEventListener('resize', resizePlayerWrapper);

    return () => {
      window.removeEventListener('resize', resizePlayerWrapper);
    };
  }, []);

  return (
    <SMLCard className={Styles.videoContainer}>
      <div ref={playerWrapperRef} className={Styles.playerWrapper} >
        <ReactPlayer
          playing={true}
          url={video}
          light={thumnail}
          className={Styles.reactPlayer}
          width="100%"
          height="100%"
          controls
        />
      </div>
      <div className={Styles.videoInfo}>
        <h6>Introduction</h6>
        <h4>SML in a Nutshell</h4>
      </div>
    </SMLCard>
  );
};

const RecentActivity = () => {
  return (
    <SMLCard className={Styles.recentActivityContainer}>
      <div>
        <h6>Recent Activity</h6>
        <h2>You Submitted a Task For Review.</h2>
      </div>
      <div>
        <div>
          <h6>Track</h6>
          <h4>Name of Track</h4>
        </div>
        <div>
          <h6>Status</h6>
          <h4>
            Under Review <img src={UnderReviewIcon} alt="under review"></img>
          </h4>
        </div>
      </div>
    </SMLCard>
  );
};

const StatusCard = ({ imageSrc, label, stats, onClick }) => {
  return (
    <SMLCard className={Styles.statusCard} onClick={onClick}>
      <img src={imageSrc} alt="statsImage" />
      <h2>{label}</h2>
      <h3>{stats}</h3>
    </SMLCard>
  );
};

const TracksCard = ({ secondaryLabel, tracks, status }) => {
  const navigate = useNavigate();
  const handleTrackClick = (trackId, status) => {
    navigate(`/TrackDetails/${trackId}`, {
      state: { fromUserPortal: true, status, fromDashboard: true },
    });
  };
  return (
    <SMLCard2 className={Styles.tracksContainer}>
      {Array.isArray(tracks) && tracks.length > 0 ? (
        <>
          {tracks.map((item, index) => {
            return (
              <div
                className={Styles.trackRow}
                key={item?.key || index}
                onClick={() => {
                  handleTrackClick(item._id, item.status.code);
                }}
              >
                <img src={TrackIcon} alt="trackIcon" />
                <h2>{item?.title}</h2>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <h5 className={Styles.tracksEmptyPlaceholder}>
            You don't not have any {status} tracks.
          </h5>
        </>
      )}

      <h6 className={Styles.tracksSecondaryText}>{secondaryLabel}</h6>
    </SMLCard2>
  );
};
