import React, { useEffect, useState } from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import StyledHeader from "../../components/common/styled-components/StyledHeader";
// import Styles from "../dashboard/Dashboard.module.css";
import closeIcon from "../../assets/Images/closeIcon.svg";
import UserAvatar from "../../assets/Images/UserAvatar.svg";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLToggleButton from "../../components/Shared/SMLToggleButton/SMLToggleButton";
import Styles from "./MyProfile.module.css";
import "./MyProfile.css";
import GoogleIcon from "../../assets/Images/googleIcon.svg";
import FacebookIcon from "../../assets/Images/facebookIcon.svg";
import EditIcon from "../../assets/Images/editIon.svg";
import { useNavigate } from "react-router-dom";
import ViewPlans from "./ViewPlan/ViewPlan";
import { useGoogleLogin } from "@react-oauth/google";
import { APPCONFIG } from "../../config/APPConfig";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllArtistFetch,
  updateArtistRequest,
} from "../../redux/actions/smlActions";
import { Password, Send } from "@mui/icons-material";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";

export default function MyProfile() {
  const dispatch = useDispatch();

  const [isPopupOpen, setPopupOpen] = useState(false);

  var userAvatar = UserAvatar;

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const [optionSelected, setOptionSelected] = useState([
    false,
    false,
    false,
    false,
  ]);
  // const [optionSelected0, setoptionSelected0] = React.useState(false);
  // const [optionSelected1, setoptionSelected1] = React.useState(false);
  // const [optionSelected2, setoptionSelected2] = React.useState(false);
  // const [optionSelected3, setoptionSelected3] = React.useState(false);
  const [viewplanSelected, setviewplanSelected] = React.useState(false);
  const [connectActive, setconnectActive] = React.useState(false);
  const [disconnectActive, setdisconnectActive] = React.useState(false);
  const [addLinkActive, setaddLinkActive] = React.useState(false);
  const [addPhotoActive, setaddPhotoActive] = React.useState(false);
  const [email, setEmail] = useState("");
  const [editFlag, setEditFlag] = React.useState(false);
  const [username, setUsername] = useState("");
  const [editUsername, setEditUsername] = useState(false);
  // const [viewPlanModalOpen, setViewPlanModalOpen] = React.useState(false);
  const [avatar, setAvatar] = useState(
    localStorage.getItem("avatar") || UserAvatar
  );
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");

  const [role, setRole] = useState([]);

  const [isEditingExperience, setIsEditingExperience] = useState(false);
  const [experienceText, setExperienceText] = useState("");
  const [guidanceText, setGuidanceText] = useState("");
  const [isEditingGuidance, setIsEditingGuidance] = useState(false);
  const [hasSyncExperience, setHasSyncExperience] = useState(false);
  const [showViewPlan, setShowViewPlan] = useState(false);
  const [links, setLinks] = useState([]);
  const [isEditingLinks, setIsEditingLinks] = useState([false]);
  const [linkErrors, setLinkErrors] = useState([]);

  var userAvatar = UserAvatar;
  var googleIcon = GoogleIcon;
  var facebookIcon = FacebookIcon;
  var editIcon = EditIcon;
  const sml = useSelector((store) => store.sml);
  const artists = useSelector((store) => store.sml.allArtistFetched?.artists);

  console.log(artists);

  const artistResponsse = useEffect(() => {
    dispatch(getAllArtistFetch());
  }, [dispatch]);
  useEffect(() => {
    if (artists) {
      setExperienceText(artists?.music_experience_info || "");
      setGuidanceText(artists?.guidance_needed_info || "");
      setUsername(artists?.name || "");
      setEmail(artists?.email || "");
      setLinks(artists?.personal_links || []);
      setIsEditingLinks(Array(links.length).fill(false));
      setRole(artists?.role || []);
      setHasSyncExperience(artists?.experience_with_sync || false);

      // Map roles to optionSelected array
      const updatedOptionSelected = [
        artists?.role?.includes("Vocalist"),
        artists?.role?.includes("Musician"),
        artists?.role?.includes("Songwriter"),
        artists?.role?.includes("Producer"),
        artists?.role?.includes("Composer"),
        artists?.role?.includes("Lyricists"),
      ];
      setOptionSelected(updatedOptionSelected);
    }
  }, [artists, links.length]);

  useEffect(() => {
    const savedAvatar = localStorage.getItem("avatar");
    if (savedAvatar) {
      setAvatar(savedAvatar);
    }
  }, []);

  const handleRolesOption = (index, option) => {
    const newOptionSelected = [...optionSelected];
    newOptionSelected[index] = !newOptionSelected[index];
    setOptionSelected(newOptionSelected);

    if (!newOptionSelected[index]) {
      // Remove the role if deselected
      setRole((prevRoles) => prevRoles.filter((el) => el !== option));
    } else {
      // Add the role if selected
      setRole((prevRoles) => [...prevRoles, option]);
    }
  };

  useEffect(() => {
    console.log("Updated roles:", role);
  }, [role]); // This runs whenever `role` is updated

  const handleClickOption = (option) => {
    // if (option === "option") {
    //   setoptionSelected0(!optionSelected0);
    // }
    // if (option === "option1") {
    //   setoptionSelected1(!optionSelected1);
    // }
    // if (option === "option2") {
    //   setoptionSelected2(!optionSelected2);
    // }
    // if (option === "option3") {
    //   setoptionSelected3(!optionSelected3);
    // }
    if (option === "viewPlan") {
      setviewplanSelected(!viewplanSelected);
    }
    if (option === "addLink") {
      setaddLinkActive(!addLinkActive);
      setLinks([...links, ""]); // Add a new empty link to the array
      setIsEditingLinks([...isEditingLinks, true]); // Set the new link to be in edit mode
    }
    if (option === "connect") {
      setconnectActive(!connectActive);
    }
    if (option === "disconnect") {
      setdisconnectActive(!disconnectActive);
    }
    if (option === "addPhoto") {
      setaddPhotoActive(!addPhotoActive);
    }
  };
  const navigate = useNavigate();

  const handleViewPlanClick = () => {
    navigate("/viewPlan");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isEmailValid) {
      // Save the email
      console.log("Email saved:", email);
      setEditFlag(false); // Exit edit mode
    }
  };
  const handleViewPlan = () => {
    setShowViewPlan(true);
  };
  const handleCloseViewPlan = () => {
    setShowViewPlan(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader(); // Initialize FileReader
      reader.onloadend = () => {
        const result = reader.result; // Get the file content as base64
        setAvatar(result); // Set the avatar (or do anything else with the result)
        localStorage.setItem("avatar", result); // Store it in localStorage
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const validateEmailFormat = (email) => {
    const re = /^[a-zA-Z0-9]+@gmail\.(com|in)$/;
    return re.test(email);
  };

  const handleExperienceEdit = () => {
    setIsEditingExperience(true);
  };

  const handleExperienceSave = () => {
    setIsEditingExperience(false);
  };

  const handleExperienceBlur = () => {
    setIsEditingExperience(false);
  };

  const handleKeysDown = (event, func, funcParams = []) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent adding a new line
      func && func(...funcParams);
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
    // props.handleSampleSources(updatedSources);
  };

  const handleUsernameSave = () => {
    setEditUsername(false);
  };

  const handleExperienceTextChange = (event) => {
    const text = event.target.value;
    if (text.length <= 500) {
      setExperienceText(text);
    }
  };
  const handleGuidanceTextChange = (event) => {
    const text = event.target.value;
    if (text.length <= 500) {
      setGuidanceText(text);
    }
  };

  const handleGuidanceEdit = () => {
    setIsEditingGuidance(true);
  };

  const handleGuidanceSave = () => {
    setIsEditingGuidance(false);
  };

  // const handleGuidanceBlur = () => {
  //   setIsEditingGuidance(false);
  // };

  const handlekey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent adding a new line
      handleGuidanceSave();
    }
  };
  const handleEdit = (event) => {
    const newEmail = event.target.value;
    console.log(newEmail);
    setEmail(event.target.value);

    // if (!validateEmailFormat(newEmail)) {
    //   setIsEmailValid(false);
    //   setEmailError("Invalid email format.");
    // } else {
    //   setIsEmailValid(true);
    //   setEmailError("");
    //   setEmail(newEmail);
    // }
  };

  // To fetch ME data
  let sendData = {
    _id: artists?._id,
    email: artists?.email,
    name: artists?.name,
    role: artists?.role,
    experience_with_sync: artists?.experience_with_sync,
    sml_lead_info: artists?.sml_lead_info,
    personal_links: artists?.personal_links,
    music_experience_info: artists?.experienceText,
    guidance_needed_info: artists?.guidance_needed_info,
    created_at: artists?.created_at,
    updated_at: artists?.updated_at,
    _v: artists?._v,
  };
  const updatedId = useSelector((state) => state.sml.updates?.updates?._id);
  useEffect(() => {
    if (updatedId && updatedId.length > 0) {
      window.location.reload();
    }
  }, [updatedId]);
  const handleProfileUpdate = (e) => {
    sendData = {
      _id: sendData._id,
      email: email, //
      name: username, //
      role: role, //
      experience_with_sync: hasSyncExperience, //
      sml_lead_info: sendData.sml_lead_info,
      personal_links: links, //
      music_experience_info: experienceText, //
      guidance_needed_info: guidanceText, //
      created_at: sendData.created_at,
      updated_at: sendData.updated_at,
      _v: sendData._v,
    };

    console.log(sendData);

    dispatch(updateArtistRequest(sendData));
  };
  // LINKS
  const handleSaveLink = (index) => {
    const link = links[index];
    const newLinkErrors = [...linkErrors];
    if (validateLinkFormat(link)) {
      newLinkErrors[index] = "";
      const newIsEditingLinks = [...isEditingLinks];
      newIsEditingLinks[index] = false;
      setIsEditingLinks(newIsEditingLinks);
      // Save the link here if needed
    } else {
      newLinkErrors[index] = "Invalid link format";
    }
    setLinkErrors(newLinkErrors);
  };

  const handleLinkSave = (value, index) => {
    setLinks((prevstate) => {
      const newState = [...prevstate];
      newState[index] = value;
      return newState;
    });

    console.log(links);

    setIsEditingLinks((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const handleDown = (event, index, saveFunction) => {
    if (event.key === "Enter") {
      saveFunction(index);
    }
  };

  const validateLinkFormat = (link) => {
    const regex = /^www\.[a-zA-Z]+\.[cC][oO][mM]$/;
    return regex.test(link);
  };

  const handleEditLink = (index) => {
    // debugger;
    setIsEditingLinks((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
    // setIsEditingLinks(() => isEditingLinks.map(el, i) => {
    //   if(i === index)
    // });
    //     links.map((el, i) => {
    //       if (i === index) {
    //         setIsEditingLinks(true);
    //       }
    //     });
    // const newIsEditingLinks = true;
  };

  // const username = localStorage.getItem("username");
  // const emails = localStorage.getItem("emails");
  const isFacebookLoggedIn =
    localStorage.getItem("isFacebookLoggedIn") === "true";
  const isGoogleLoggedIn = localStorage.getItem("isGoogleLoggedIn") == "true";

  const disconnectFromFacebook = () => {
    localStorage.removeItem("isFacebookLoggedIn");
    localStorage.removeItem("emails");

    // navigate("/HomePage");
    navigate("/");
  };
  const disconnectFromGoogle = () => {
    localStorage.removeItem("isGoogleLoggedIn");
    localStorage.removeItem("emails");

    // navigate("/HomePage");
    navigate("/");
  };
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      try {
        const res = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `
              `,
            },
          }
        );
        const profile = await res.json();
        console.log(profile);
        localStorage.setItem("username", profile.name);
        localStorage.setItem("emails", profile.email);
        localStorage.setItem("isGoogleLoggedIn", "true");

        navigate("/dashboard");
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    },
    // flow: 'implicit', // use 'implicit' if you don't need an auth code, or 'auth-code' if you do
  });
  const responseFacebook = (response) => {
    console.log(response);
    const profile = { name: response.name }; // Replace this with actual user information extraction

    // Save username to local storage
    localStorage.setItem("username", profile.name);
    localStorage.setItem("emails", profile.email);
    localStorage.setItem("isFacebookLoggedIn", "true");

    navigate("/dashboard");
  };
  const handleFacebookLogin = () => {
    // Add any pre-login logic here
    disconnectFromGoogle();
  };

  const handleGoogleLogin = () => {
    disconnectFromFacebook();
    // localStorage.removeItem('emails');
    login();
  };

  return (
    <HomeLayout applyScroll={true}>
      <div className="myProfileContainer-Header">
        <StyledHeader>My Profile</StyledHeader>
        <SMLButton
          value="Update profile"
          valueStyle="3"
          style="SMLSecondaryButton saveBtn"
          handleClickEvent={handleProfileUpdate}
        ></SMLButton>
      </div>

      <div className={Styles.myProfileContainer}>
        <div className={Styles.myProfileSubContainer}>
          <div className={Styles.statusCard}>
            <div
              style={{
                display: "flex",
                gap: 15,
                alignItems: "center",
                flex: 1,
              }}
            >
              <img style={{ width: "100px" }} src={avatar} alt="User Avatar" />
              {editUsername ? (
                <div>
                  <textarea
                    className="textarea no-border-input"
                    style={{ resize: "none" }}
                    value={username}
                    onChange={handleUsernameChange}
                    // onBlur={handleExperienceSave}
                    onKeyDown={(e) => handleKeysDown(e, handleUsernameSave)}
                    autoFocus
                  />

                  {/* <input type="text" value={email} onChange={handleEdit} /> */}
                </div>
              ) : (
                <div>
                  <label
                    className="title2"
                    style={{ marginBottom: "0px", marginRight: "6px" }}
                  >
                    {username}
                  </label>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => setEditUsername(true)}
                  />
                </div>
              )}
              {/* <label className="title2">{artists?.name}</label>
              <img
                src={editIcon}
                alt="edit"
                onClick={() => setEditFlag(true)}
              /> */}
            </div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="fileInput"
              onChange={handleFileChange}
            />
            <SMLButton
              valueStyle="Button3"
              style="SMLSecondaryButton"
              value="Add Photo"
              handleClickEvent={() =>
                document.getElementById("fileInput").click()
              }
            />
          </div>

          <div className={Styles.statusCard}>
            <div
              style={{
                display: "flex",
                gap: 15,
                alignItems: "center",
                flex: 1,
              }}
            >
              <SMLLabel style="title2" value="Email" />
            </div>
            <div>
              {editFlag ? (
                <div>
                  <input
                    type="text"
                    value={email}
                    onChange={handleEdit}
                    className="no-border-input"
                    style={{ borderColor: isEmailValid ? "" : "red" }}
                  />

                  {!isEmailValid && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </div>
              ) : (
                <div>
                  <label style={{ marginRight: "8px" }}>{artists?.email}</label>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => setEditFlag(true)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={Styles.statusCard}>
            <div className="profile-password-card">
              <div className="profile-fields row">
                <div className="col-10">
                  <div className="form-group">
                    <SMLLabel style="title2" value="Password" />
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group profile-password-card-value">
                    <SMLLabel value="" />
                    {/* <img src={editIcon} alt="edit" /> */}
                  </div>
                </div>
              </div>
              <div
                className="profile-fields"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                  marginBottom: "12px",
                }}
              >
                <div className="col-4"></div>
                <div className="col-5" style={{ flexShrink: "1" }}>
                  <label>
                    {isGoogleLoggedIn
                      ? "Signed-in with Google"
                      : "Sign in with Google"}
                  </label>
                  <img
                    src={googleIcon}
                    alt="Google"
                    style={{ marginLeft: "10px" }}
                  />
                </div>

                <div>
                  {isGoogleLoggedIn ? (
                    <button
                      className={
                        connectActive
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      type="button"
                      onClick={disconnectFromGoogle}
                    >
                      Disconnect
                    </button>
                  ) : (
                    <button
                      className="SMLSecondaryButton"
                      type="button"
                      // onClick={handleGoogleLogin}
                    >
                      Connect
                    </button>
                  )}
                </div>
              </div>
              <div
                className="profile-fields"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <div className="col-4"></div>
                <div className="col-5" style={{ flexShrink: "1" }}>
                  <label>
                    {isFacebookLoggedIn
                      ? "Signed-in with Facebook"
                      : "Sign in with Facebook"}
                  </label>
                  <img
                    src={facebookIcon}
                    alt="Facebook"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
                {/* <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    
                                </div> */}
                <div>
                  {isFacebookLoggedIn ? (
                    <button
                      className={
                        connectActive
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      style={{ marginBottom: "0px" }}
                      type="button"
                      onClick={disconnectFromFacebook}
                    >
                      Disconnect
                    </button>
                  ) : (
                    <FacebookLogin
                      appId={APPCONFIG.FACEBOOK_APP_ID}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <button
                          className="SMLSecondaryButton"
                          // onClick={() => {
                          //   handleFacebookLogin();
                          //   renderProps.onClick();
                          // }}
                        >
                          Connect
                        </button>
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={Styles.statusCard}>
            <div className="profile-links-card">
              <div className="form-group" style={{ gridColumn: "1/2" }}>
                <SMLLabel style="title2" value="Links" />
              </div>

              {/* LINKS */}
              <div style={{ gridColumn: "2/3" }}>
                {links.map((link, index) => (
                  <div
                    className="profile-fields row"
                    key={index}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div className="col-12">
                      <div className="form-group profile-password-card-value">
                        {isEditingLinks[index] ? (
                          <>
                            <SMLTextBox
                              type="text"
                              value={link}
                              onChange={(e) => {
                                const newLinks = [...links];
                                newLinks[index] = e.target.value;
                                setLinks(newLinks);
                              }}
                              onKeyDown={(e) => {
                                handleKeysDown(e, handleLinkSave, [
                                  link,
                                  index,
                                ]);
                              }}
                              // imgSrc={closeIcon}
                              // onImgClick={() => handleRemoveLink(index)}
                            />
                            {/* <div>
                            <input
                              type="text"
                              value={link}
                              onKeyDown={(e) => {
                                handleKeysDown(e, handleLinkSave, [
                                  link,
                                  index,
                                ]);
                              }}
                              onChange={(e) => {
                                const newLinks = [...links];
                                newLinks[index] = e.target.value;
                                setLinks(newLinks);
                              }}
                              // onKeyDown={(e) =>
                              //   handleDown(e, index, handleSaveLink)
                              // }
                              className={`no-border-input ${
                                linkErrors[index] ? "invalid-input" : ""
                              }`}
                            />
                            {linkErrors[index] && (
                              <div style={{ color: "red" }}>
                                {linkErrors[index]}
                              </div>
                            )}
                          </div> */}
                          </>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <SMLLabel value={link} />
                            <img
                              src={editIcon}
                              alt="Edit"
                              onClick={() => handleEditLink(index)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* ADD LINK */}
              <div
                className="profile-fields row"
                style={{ gridColumn: "2/3", justifyContent: "flex-end" }}
              >
                <div className="col-9"></div>
                <div
                  className="col-3"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <SMLOptionQuestion
                    type="button"
                    style={
                      addLinkActive
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleClickOption("addLink")}
                    value="Add Link"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.statusCard}>
            <div className="profile-plan-card">
              <div className="row profile-plan-card-wrapper">
                <div
                  className="col-7"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="form-group">
                    <SMLLabel style="title2" value="Plan" />
                  </div>
                </div>
                <div
                  style={{
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <div className="col-2">
                    <SMLLabel value="Basic" />
                  </div>
                  <div
                    className="col-3"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <SMLOptionQuestion
                      type="button"
                      style={
                        viewplanSelected
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      handleClickEvent={handleViewPlan}
                      value="View Plan"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECOND CONTAINER */}
        <div className={Styles.myProfileSubContainer1}>
          <div className={Styles.profileCard}>
            <div className="profile-buttons profile-fields">
              <div className="row profile-fields">
                <SMLLabel value="Who are you?" style="title2"></SMLLabel>
                <div className="form-group col-12">
                  <SMLOptionQuestion
                    type="button"
                    index="0"
                    style={
                      optionSelected[0]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(0, "Vocalist")}
                    value="Vocalist"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="1"
                    style={
                      optionSelected[1]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(1, "Musician")}
                    value="Musician"
                  />

                  <SMLOptionQuestion
                    type="button"
                    index="2"
                    style={
                      optionSelected[2]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(2, "Songwriter")}
                    value="Songwriter"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="3"
                    style={
                      optionSelected[3]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(3, "Producer")}
                    value="Producer"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="4"
                    style={
                      optionSelected[4]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(4, "Composer")}
                    value="Composer"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="5"
                    style={
                      optionSelected[5]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(5, "Lyricists")}
                    value="Lyricists"
                  />
                </div>
              </div>
            </div>
            <div class="profile-experience profile-fields">
              <div className="row profile-fields">
                <div className="col-2">
                  <SMLToggleButton
                    value="Yes"
                    checked={hasSyncExperience}
                    onChange={() => setHasSyncExperience(!hasSyncExperience)}
                  />
                </div>
                <div className="col-10">
                  <SMLLabel
                    value="Do you have experience with sync?"
                    style="title2"
                  ></SMLLabel>
                </div>
              </div>
            </div>
            <div className="profile-aboutYouSelf ">
              <div className="row profile-fields">
                <div className="col-8">
                  <SMLLabel
                    value="Tell us about your music experience."
                    style="title2"
                  ></SMLLabel>
                </div>
                <div className="col-4 text-end">
                  <img
                    src={editIcon}
                    alt="Edit"
                    onClick={handleExperienceEdit}
                  />
                </div>
              </div>

              <div className="row profile-fields">
                <div className="col-12">
                  {isEditingExperience ? (
                    <>
                      <textarea
                        className="textarea no-border-input"
                        value={experienceText}
                        onChange={handleExperienceTextChange}
                        // onBlur={handleExperienceSave}
                        onKeyDown={(e) =>
                          handleKeysDown(e, handleExperienceSave)
                        }
                        autoFocus
                        maxLength="500"
                      />
                      <div>
                        {500 - experienceText?.length} characters remaining
                      </div>
                    </>
                  ) : (
                    <p className={Styles.paragraph}>{experienceText}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="profile-aboutYouSelf">
              <div className="row profile-fields">
                <div className="col-8">
                  <SMLLabel
                    value="What kind of guidance are you looking for?"
                    style="title2"
                  ></SMLLabel>
                </div>
                <div className="col-4 text-end">
                  <img src={editIcon} alt="Edit" onClick={handleGuidanceEdit} />
                </div>
              </div>
              <div className="row profile-fields">
                <div className="col-12">
                  {isEditingGuidance ? (
                    <>
                      <textarea
                        className="textarea no-border-input"
                        value={guidanceText}
                        onChange={handleGuidanceTextChange}
                        onBlur={handleGuidanceSave}
                        onKeyDown={handlekey}
                        autoFocus
                        maxLength="500"
                      />
                      <div>
                        {500 - experienceText.length} characters remaining
                      </div>
                    </>
                  ) : (
                    <p className={Styles.paragraph}>{guidanceText}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showViewPlan && (
          <div className="popups-blur">
            <div className="Viewplan-popup">
              <ViewPlans viewPlanClose={handleCloseViewPlan} />
            </div>
          </div>
        )}
      </div>
    </HomeLayout>
  );
}
