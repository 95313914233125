import axios from 'axios';

const BASE_URL = 'https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev';

export const signup = async ({
  fullName,
  email,
  password,
  profileType,
  roles,
  experienceWithSync,
  smlLeadInfo,
  personalLinks,
  musicExperienceInfom,
  guidanceNeededInfo,
}) => {
  try {
    let obj = {
      email: email,
      password: password,
      full_name: fullName,
      // profile_type: profileType,
      profile_type: 'artist',
      profile: {
        role: roles,
        experience_with_sync: experienceWithSync,
        sml_lead_info: smlLeadInfo,
        personal_links: personalLinks,
        music_experience_info: musicExperienceInfom,
        guidance_needed_info: guidanceNeededInfo,
      },
    };
    // Make a POST request to the signup endpoint with email and password
    console.log('ye bheja', obj);
    const response = await axios.post(`${BASE_URL}/auth/register`, obj);

    // Return the data from the response
    if (response.status === 200) return response.data;
    
    // Otherwise, throw an error with the response message
    throw new Error(response.data.message || 'Signup failed');
  } catch (error) {
    // Log the error to the console
    console.error('Error signing up:', error);

    // Throw the error to be handled by the caller (e.g., in the saga)
    throw error;
  }
};

export const supSignupApi = async ({
  email,
  password,
  fullName,
  profileType,
  name,
  company,
  profession,
  venmo,
  zelle,
}) => {
  try {
    // Create the object in the desired format
    const obj = {
      email: email,
      password: password,
      full_name: fullName,
      profile_type: profileType,
      profile: {
        name: name,
        company: company,
        profession: profession,
        payment_info: {
          venmo: venmo,
          zelle: zelle,
        },
      },
    };

    // Make a POST request to the signup endpoint
    console.log('Data being sent:', obj);
    const response = await axios.post(`${BASE_URL}/auth/register`, obj);

    // Return the data from the response
    return response.data;
  } catch (error) {
    // Log the error to the console
    console.error('Error signing up:', error);

    // Throw the error to be handled by the caller
    throw error;
  }
};
