
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // Corrected import
import api from "../api";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const putFeedbackMsApi = async (data) => {
  try {
    // Extract properties from data
    // const {
    //   trackId, // Ensure trackId is a single string
    //   ratings,
    //   syncPotential,
    //   suggestedTargets,
    //   notes,
    //   downloadTrack, // Changed to match naming convention
    // } = data;

    // console.log('Data received:', data);
    console.log("data", data);
    // console.log(object)
    const feedbackData = {
      track_id: data.track_id, // Ensure this is a string
      master_feedback: {
        ratings: {
          production: data.supervisor_feedback.ratings.production,
          production_notes: data.supervisor_feedback.fieldNotes.production,
          vocals: data.supervisor_feedback.ratings.vocals,
          vocals_notes: data.supervisor_feedback.fieldNotes.vocals,
          lyrics: data.supervisor_feedback.ratings.lyrics,
          lyrics_notes: data.supervisor_feedback.fieldNotes.lyrics,
          dynamics: data.supervisor_feedback.ratings.dynamics,
          dynamics_notes: data.supervisor_feedback.fieldNotes.dynamics,
          build: data.supervisor_feedback.ratings.build,
          build_notes: data.supervisor_feedback.fieldNotes.build,
          editor_hit_points: data.supervisor_feedback.ratings.editor_hit_points,
          editor_hit_points_notes:
            data.supervisor_feedback.fieldNotes.editor_hit_points,
          stop_downs: data.supervisor_feedback.ratings.stop_downs,
          stop_downs_notes: data.supervisor_feedback.fieldNotes.stop_downs,
          back_end: data.supervisor_feedback.ratings.back_end,
          back_end_notes: data.supervisor_feedback.fieldNotes.back_end,
        }, // Ratings object
        sync_potential: data.supervisor_feedback.sync_potential, // Boolean value
        suggested_targets: data.supervisor_feedback.suggestedTargets, // Array of suggested targets
        notes: data.supervisor_feedback.notes, // Notes string
        download_track: data.supervisor_feedback.download_track, // Boolean value
      },
    };
    const trackId = data.track_id;
    console.log("track id", trackId);
    console.log("Formatted feedbackData:", feedbackData);

   // const accessToken = Cookies.get('token');
   const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }

    // Decode the token to get any necessary information (if needed)
    const decodedToken = jwtDecode(accessToken);

    const url = `${BASE_URL}/feedback/${trackId}/master-supervisor`;

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      url: url,
      data: feedbackData,
    };

    const response = await api.request(config);

    console.log("Response data:", response);

    if (response.status !== 200) {
      console.error("Failed to post feedback. Status code:", response.status);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);
    throw error;
  }
};
