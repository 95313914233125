import React from 'react';
import './parallaxCertified.css';
import getCertified from '../../../assets/Images/getCertified.svg';

const ParallaxCertified = () => {
  return (
    <div className="ParallaxCertified-wrapper">
        <div className="ParallaxCertified-rectangle">
          <div className="ParallaxCertified-group">
            <img src={getCertified} className="ParallaxCertified-img" alt="Get Certified" />
            <div className="ParallaxCertified-container">
              <button className="ParallaxCertified-btn">Step 3</button>
              <div className="ParallaxCertified-title">Get Certified & Recognized.</div>
              <div className="ParallaxCertified-content subtitle3">
                Tracks that are Sync Music Lab-Certified get distributed to contacts actively looking for music.
                We actively pitch your tracks to our network of key players in the world of global media.
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ParallaxCertified;
