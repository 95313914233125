import { React } from "react";
import "./parallaxForm.css";
import "../../../styles/Fonts.scss";
import SMLTextArea from "../../Shared/SMLTextArea/SMLTextArea";
import SMLLabel from "../../Shared/SMLLabel/SMLLabel";
import SMLTextBox from "../../Shared/SMLTextBox/SMLTextBox";

const ParallaxForm = () => {
    const handleLogin = () => {};

    return (
        <div className="Parallax" onClick={handleLogin}>
            <div className="Parallax-Form-container">
                <div className="Parallax-Form-content">
                    <div className="Parallax-Question-content">GOT A QUESTION?</div>
                    <div className="Body">Send us a message and we will respond shortly.</div>
                </div>
                <div className="Parallax-Form">
                    <div className="Parallax-Form-Contents">
                        <SMLLabel value="Your name" />
                        <SMLTextBox />
                    </div>
                    <div className="Parallax-Form-Content">
                        <SMLLabel value="Your Email" />
                        <SMLTextBox />
                    </div>
                    <div className="Parallax-Form-Content">
                        <SMLLabel value="Your Message" />
                        <SMLTextArea />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParallaxForm;
