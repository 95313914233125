import {
  GET_TRACK_REQUEST,
  GET_TRACK_SUCCESS,
  GET_TRACK_FAILURE,
  POST_TRACK_REQUEST,
  POST_TRACK_SUCCESS,
  POST_TRACK_FAILURE,
  UPDATE_TRACK_REQUEST,
  UPDATE_TRACK_SUCCESS,
  UPDATE_TRACK_FAILURE,
  DELETE_TRACK_REQUEST,
  DELETE_TRACK_SUCCESS,
  DELETE_TRACK_FAILURE,
  GET_SINGLE_TRACK_REQUEST,
  GET_SINGLE_TRACK_SUCCESS,
  GET_SINGLE_TRACK_FAILURE,
  RESET_UPLOAD_TRACK,
  GET_ALL_TRACK_SUCCESS,
  GET_ALL_TRACK_LOADING,
  RESET_GET_SINGLE_TRACK,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  tracks: [],
  singleTrack: null,
  error: null,
};

const uploadTrackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRACK_REQUEST:
    case GET_SINGLE_TRACK_REQUEST:
    case POST_TRACK_REQUEST:
    case UPDATE_TRACK_REQUEST:
    case DELETE_TRACK_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
      };
    case RESET_GET_SINGLE_TRACK:
      return {
        isLoading: false,
        isError: false,
        tracks: [],
        singleTrack: null,
        error: null,
      };
    case RESET_UPLOAD_TRACK:
      return {
        tracks: [],
        isLoading: false,
        error: null,
        isError: false,
        singleTrack: null,
      };
    case GET_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tracks: action.payload,
      };

    case GET_SINGLE_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        singleTrack: action.payload,
      };
    case GET_ALL_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tracks: action.payload,
      };
    case GET_ALL_TRACK_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case POST_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tracks: action.payload,
      };

    case UPDATE_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tracks: state.tracks.map((track) =>
          track.id === action.payload.id ? action.payload : track
        ),
      };

    case DELETE_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tracks: state.tracks.filter((track) => track.id !== action.payload),
      };

    case GET_TRACK_FAILURE:
    case POST_TRACK_FAILURE:
    case UPDATE_TRACK_FAILURE:
    case DELETE_TRACK_FAILURE:
    case GET_SINGLE_TRACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default uploadTrackReducer;
