import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RateSlider from "../../components/UI/RateBar/RateBar";
import SMLToggleButton from "../../components/Shared/SMLToggleButton/SMLToggleButton";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLTextArea from "../../components/Shared/SMLTextArea/SMLTextArea";
import { RESET_GET_FEEDBACK } from "../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeft from "../../assets/Icons/arrow-left.svg";
import "./TrackEvaluation.css";
import { getFeedbackRequest } from "../../redux/actions";
import Union from "../../assets/Images/Union.png";
import { Bars } from "react-loader-spinner";
import HomeLayout from "../../components/Layout/HomeLayout";
import RateBar from "../../components/UI/RateBar/RateBar";

// Define fields for which ratings are expected
const showingFields = [
  "Production",
  "Vocals",
  "Lyrics",
  "Dynamics",
  "Build",
  "Editor Hit Points",
  "Stop-downs",
  "Back End",
];
const fields = [
  "production",
  "vocals",
  "lyrics",
  "dynamics",
  "build",
  "editor_hit_points",
  "stop_downs",
  "back_end",
];

const TrackEvaluationArtist = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackId } = useParams();
  const status = location.state?.status;
  const fromUserPortal = location.state?.fromUserPortal;
  const feedback = useSelector((state) => state.getFeedback?.feedback);
  const loading = useSelector((state) => state.getFeedback?.isLoading);

  useEffect(() => {
    dispatch({ type: RESET_GET_FEEDBACK });
    dispatch(getFeedbackRequest(trackId));
  }, []);

  const [rating, setRatings] = useState({});
  const [syncPotential, setSyncPotential] = useState(true);
  const [suggestedTargets, setSuggestedTargets] = useState([]);
  const [notes, setNotes] = useState("");
  const [downloaded, setDownloaded] = useState(false);

  useEffect(() => {
    if (feedback && feedback.length > 0) {
      const masterFeedback = feedback[0]?.master_feedback || {};
      setDownloaded(masterFeedback?.download_track);
      setRatings(masterFeedback?.ratings || {});
      setSyncPotential(masterFeedback?.sync_potential || true);
      setSuggestedTargets(masterFeedback?.suggested_targets || []);
      setNotes(masterFeedback?.notes || "");
    }
  }, [feedback]);

  const handleChangePages = () => {
    navigate(-1);
  };

  return (
    <HomeLayout applyScroll={true}>
      {loading ? (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            visible={true}
          />
        </div>
      ) : (
        <div
          className="vh-100 align-items-center"
          style={{ overflow: "auto", color: "#333" }}
        >
          <div className="track-bg-main-div d-flex align-items-end my-5 mx-auto px-2">
            <div className="me-auto">
              <img src={ArrowLeft} alt="Go Back" onClick={handleChangePages} />
            </div>
            <div className="flex-grow-1 d-flex justify-content-center">
              <SMLLabel style="Heading1" value="Sync Music Lab Evaluation" />
            </div>
          </div>

          <div style={{ backgroundColor: "#f8f9fa" }}>
            <div
              className="track-bg-main-div track-rate-container mt-3 mb-3"
              style={{ backgroundColor: "#ffffff" }}
            >
              <Col className="my-5">
                <Row>
                  <div className="trackEvalHeaderBox">
                    <SMLLabel style="title1" value="Track Evaluation" />
                  </div>
                </Row>
                <Row className="my-3">
                  <span className="Subtitle1">{notes}</span>
                </Row>
              </Col>
              {downloaded && (
                <div>
                  <Row className="mb-3">
                    <Col className="trackEvalBtnBox">
                      <img src={Union} className="" />
                      <SMLLabel value="Track Certified" style="title2" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SMLLabel
                        value="Your track has been highly rated! Music supervisors have downloaded it for potential placement. It is now eligible for distribution by Sync Music Lab."
                        style="notes"
                      />
                    </Col>
                  </Row>
                </div>
              )}
              <Row>
                <Col>
                  {suggestedTargets.map((target, index) => (
                    <SMLOptionQuestion
                      key={index}
                      type="button"
                      style="SMLSecondaryButtonSelected"
                      value={target}
                      disabled={true} // Disabled as no editing is needed
                    />
                  ))}
                </Col>
              </Row>
            </div>
            <div
              className="track-bg-main-div track-rate-container mt-3 mb-3"
              style={{ backgroundColor: "#ffffff" }}
            >
              <Row className="mb-3">
                <Col>
                  <SMLLabel style="title1" value="Track Ratings" />
                </Col>
              </Row>
              {fields.map((field, index) => (
                <div key={index} className="my-4">
                  <Row>
                    <Col>
                      <SMLLabel
                        value={showingFields[index]}
                        style="rating-title2"
                      />
                    </Col>
                  </Row>
                  <div className="ratebar">
                    <Col className="track-rate-slider-div">
                      <Row className="d-flex align-items-center">
                        <Col className="ratesliderBar col-11 my-2">
                          {rating[field] !== undefined && (
                            <RateSlider
                              value={rating[field]}
                              disabled={true}
                              fromUserPortal={true}
                            />
                          )}
                        </Col>
                        <Col className="col-1">
                          <SMLLabel
                            value={rating[field] === 0 ? "N/A" : rating[field]}
                            style={rating[field] === 0 ? "black" : "orange"}
                          />
                        </Col>
                      </Row>
                    </Col>

                    {/* Render notes if they exist for this field */}
                    {rating?.[`${field}_notes`] && (
                      <Row>
                        <Col>
                          <div className="notes-line">
                            <span style={{ fontWeight: "bold" }}> Note:&nbsp;</span>
                            <SMLLabel
                              value={`${
                                feedback[0].master_feedback.ratings[
                                  `${field}_notes`
                                ]
                              }`}
                              style="notes"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </HomeLayout>
  );
};

export default TrackEvaluationArtist;
