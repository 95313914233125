import React from "react";
import Styles from "./HomeLayout.module.css";
import SideBar from "../common/side-bar/SideBar";
import Header from "../common/header/Header";
import { useLocation } from "react-router-dom";

export default function HomeLayout({
  children,
  isPopupOpen,
  togglePopup,
  status,
  applyScroll,
}) {
  const location = useLocation();
  const email = location?.state?.username;

  return (
    <div className={Styles.container}>
      <div>
        <SideBar />
      </div>
      <div className={Styles.contentContainer}>
        <Header
          isPopupOpen={isPopupOpen}
          togglePopup={togglePopup}
          username={email}
          status={status}
        />
        <div className={`${Styles.childrenContainer} ${applyScroll ? Styles.scrollableContainer : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
}
