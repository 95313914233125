import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../../styles/Fonts.scss";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../assets/Images/closeIcon.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import successIcon from "../../assets/Images/SuccessCheckmark.svg";
import ViewSubmission from "../../components/UI/viewSubmission/viewSubmission";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { resetUploadTrack } from "../../redux/actions/uploadTrackActions";
import "./SupAssignPopup.css";
import SupAssignPopup from "./SupAssignPopup";
import { RESET_ASSIGNED_SUPERVISOR } from "../../redux/actions/actionTypes";

const SupAssignSuccess = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showFeedback, setShowFeedBack] = useState(false);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (e) => {
    // Or navigate to another page
    e.preventDefault();
    // debugger;
    // navigate('/Dashboard');
  };

  const handleViewSubmissionClick = () => {
    console.log("view submission clicked");
    // setShowFeedBack(true);
    // debugger;
    // dispatch(resetUploadTrack());
    // window.location.reload();
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    setShowAssignPopup(true);
  };

  const handleDashboard = (e) => {
    // debugger;
    e.preventDefault();
    navigate("/supervisorDashboard");
    // window.location.reload();
    // console.log("dashboard clikced");
    dispatch(resetUploadTrack());
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    props.onClose();
  };

  const onCloseViewSubmission = () => {
    // dispatch(resetUploadTrack());
    console.log("from vs close");
    console.log(showFeedback);
    console.log(props.currentStatus, "current status");
    props.handleStatus();
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    props.onClose();
  };
  return showAssignPopup ? (
    <SupAssignPopup
      handleStatus={props.handleStatus}
      onClose={props.onClose}
      trackId={props.trackId}
      mandate = {true}
    />
  ) : (
    <div className="supAssign-container">
      <div className="feedback-card">
        <div className="feedback-close-div" onClick={onCloseViewSubmission}>
          <img src={closeIcon} />
        </div>
        <Row className="justify-content-center align-items-center h-100">
          <Col xs={12} md={8}>
            <div className="text-center">
              <form onSubmit={handleClick}>
                <div className="feedback-groupby-bottom-margin">
                  <img src={successIcon}></img>
                </div>
                <div className="feedback-groupby-bottom-margin">
                  <SMLLabel
                    value="Track Assigned"
                    style="Mainheader Heading2"
                  />
                </div>
                 
                  {/* <div className="feedback-groupby-middel-btn">
                    <SMLButton
                      style="SMLButton2 SMLButton-Orange"
                      value="Dashboard"
                      handleClickEvent={handleDashboard}
                    />
                  </div> */}
                 
                <div className="feedback-groupby-middel-btn">
                  <SMLButton
                    style="SMLButton3"
                    valueStyle="Button2"
                    value="Change Assignee"
                    handleClickEvent={handleViewSubmissionClick}
                  />
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default SupAssignSuccess;
