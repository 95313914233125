import { all } from 'redux-saga/effects';
// import submissionsSaga from './submissionsSaga';
// import reviewsSaga from './reviewsSaga';
import authSaga from './authSaga';
import searchSaga from './searchSaga';
import trackStatsSaga from './trackStatsSaga';
import smlSaga from './smlSagas';
import assignSupervisorSaga from './assignSupervisorSaga';
import {
  postFeedbackSaga,
  putFinalFeedbackMsSaga,
  putFeedbackMsSaga,
  getFeedbackSaga,
} from './postFeedbackSaga';
import { downloadTrackSaga } from './downloadTrackSaga';
import { getSingleArtistSaga } from './artistSaga';
import supervisorSaga from './supervisorSaga';
// import  from './postFeedbackSaga';
export default function* rootSaga() {
  yield all([
    // submissionsSaga(),
    // reviewsSaga(),
    authSaga(),
    trackStatsSaga(),
    smlSaga(),
    assignSupervisorSaga(),
    postFeedbackSaga(),
    putFeedbackMsSaga(),
    putFinalFeedbackMsSaga(),
    downloadTrackSaga(),
    getSingleArtistSaga(),
    getFeedbackSaga(),
    supervisorSaga()
  ]);
}
