import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_TRACK_STATS,
  GET_TRACK_STATS_SUCCESS,
  GET_TRACK_STATS_FAILURE,
} from "../actions/actionTypes";
import { getTrackStatsApi } from "../../api/track/getTrackStatsApi";

function* getTrackStatsSaga(action) {
  try {
    const type = action.payload
    const stats = yield call(getTrackStatsApi, type);

    yield put({ type: GET_TRACK_STATS_SUCCESS, stats: stats });
  } catch (error) {
    yield put({ type: GET_TRACK_STATS_FAILURE, payload: error.message });
  }
}

export default function* trackStatsSaga() {
  yield all([takeLatest(GET_TRACK_STATS, getTrackStatsSaga)]);
}
