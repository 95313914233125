import React, { useEffect } from 'react';
import HomeLayout from '../../components/Layout/HomeLayout';
import TracksView from '../../components/common/tracks-view/TracksView';
import UnderReviewIcon from '../../assets/Icons/under-review.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTracksFetch } from '../../redux/actions/smlActions';
import { Bars } from 'react-loader-spinner';
import { RESET_SEARCH } from '../../redux/actions/actionTypes';
export default function Review() {
  const dispatch = useDispatch();
  const tracksfromsearch = useSelector(
    (store) => store.search.searchedResult.result
  );
  const searchText = useSelector((store) => store.search.searchText);
  const tracks = useSelector(
    (state) => state.sml.allTracksFetched?.tracks || state.uploadTrack?.tracks
  );
  const loading = useSelector((store) => store.sml.allTracksFetched?.isLoading);
  useEffect(() => {
    dispatch({ type: RESET_SEARCH });
    dispatch(getAllTracksFetch('in_review', 'artist_id'));
  }, [dispatch]);

  // const underReviewTracks = Array.isArray(tracks)
  //   ? tracks.filter((track) => track.status.code === 'under_review')
  //   : [];
  const hasSearchResults = tracksfromsearch && tracksfromsearch?.length > 0;
  const hasSearched = searchText && searchText.trim() !== ''; // Check if the search box is not empty
  return (
    <HomeLayout status={'in_review'}>
      <TracksView
        headerLabel={'Under Review'}
        headerLabelIcon={UnderReviewIcon}
        tracks={hasSearchResults ? tracksfromsearch : hasSearched ? [] : tracks}
      />
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </HomeLayout>
  );
}
