
// import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode"; // Corrected import
import api from "../api";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const assignSupervisorApi = async ({ trackId, supId }) => {
  try {
    // const accessToken = Cookies.get('token');
    const accessToken = localStorage.getItem("token");

    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }

    // Decode the token to get the supervisor ID
    const decodedToken = jwtDecode(accessToken);
    const supervisorId = decodedToken["custom:supervisor_id"]; // Extract supervisor ID from the token
    console.log("supId", trackId, "wow", supId);
    // if (!supervisorId) {
    // console.error('No supervisor ID found in the token.');
    // return;
    // }

    // Create FormData and append the supervisor_id
    let data = new FormData();
    data.append("supervisor_id", supId);
    let url = ``;
    if (supervisorId === supId) {
      url = `${BASE_URL}/tracks/${trackId}/me/assign`;
    } else {
      url = `${BASE_URL}/tracks/${trackId}/supervisors/assign`;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    console.log("Config:", config); // Log the config to inspect it

    const response = await api.post(url, data, config);

    console.log(response.data);

    if (response.status !== 200) {
      console.error(
        "Failed to assign supervisor. Status code:",
        response.status
      );
    }

    return response.data;
  } catch (error) {
    console.log(
      "Error message in assigning supervisor to track by id",
      error.message
    );
  }
};
