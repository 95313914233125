import * as actionTypes from '../actions/actionTypes';

const initialState = {
  submissions: [],
  loading: false,
  error: null,
};

const submissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        submissions: action.payload,
      };
    case actionTypes.FETCH_SUBMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.ADD_SUBMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        submissions: [...state.submissions, action.payload],
      };
    case actionTypes.ADD_SUBMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default submissionsReducer;

