import React from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import TrackDetailsForFeedback from "../../components/common/track-details/TrackDetailsForFeedback";

export default function TrackCheck() {
  return (
    <HomeLayout>
      <TrackDetailsForFeedback />
    </HomeLayout>
  );
}
