import {
  GET_SINGLE_ARTIST_FAILURE,
  GET_SINGLE_ARTIST_LOADING,
  GET_SINGLE_ARTIST_REQUEST,
  GET_SINGLE_ARTIST_SUCCESS,
} from './actionTypes';

export const getSingleAritstRequest = (artistId) => {
  return {
    type: GET_SINGLE_ARTIST_REQUEST,
    payload: artistId,
  };
};
export const getSingleAritstSuccess = (details) => {
  return {
    type: GET_SINGLE_ARTIST_SUCCESS,
    payload: details,
  };
};
export const getSingleAritstFailure = (error) => {
  return {
    type: GET_SINGLE_ARTIST_FAILURE,
    payload: error,
  };
};
export const getSingleAritstLoading = () => {
  return {
    type: GET_SINGLE_ARTIST_LOADING,
  };
};
