
import React, { useState } from "react";
import AboutPlanComponent from '../SignUpPlanPages/PlanAboutYou/PlanAboutYou';
import PaymentPlanComponent from '../SignUpPlanPages/PlanPayment/PlanPayment';
import ContactInformationComponent from '../SignUpPlanPages/PlanContactInfo/PlanContactInfo';

import WelcomeComponent from '../SignUpPlanPages/PlanSuccess/PlanSuccess';

function SignUpPlanPage() {
  const [currentComponent, setCurrentComponent] = useState("contact");

  const handleContactInformationClick = () => {
    debugger;
    setCurrentComponent("AboutPlan");
  };

  const handleAboutYouClick = () => {
    setCurrentComponent("PaymentPlan");
  };
   const handleAboutPayment = () => {
    setCurrentComponent("welcome");
   }
  const onBackClick= () => {
    setCurrentComponent("contact");
  }
  
  
return (
    <div>
      {currentComponent === "contact" && (
        <ContactInformationComponent onContactInformationClick={handleContactInformationClick} />
      )}
      {currentComponent === "AboutPlan" && (
        <AboutPlanComponent onAboutClick={handleAboutYouClick} onBackClick={onBackClick} />
      )}
      {currentComponent === "PaymentPlan" && (
       <PaymentPlanComponent onpaymentClick={handleAboutPayment} onBackClick={onBackClick} /> )}
      {currentComponent === "welcome" && (
        <WelcomeComponent onBackClick={onBackClick}  />
      )}
    </div>
  );
}

export default SignUpPlanPage;
