import React, { useEffect, useState } from 'react';
import SMLLabel from '../../components/Shared/SMLLabel/SMLLabel';
import SMLButton from '../../components/Shared/SMLButton/SMLButton';
import { useDispatch, useSelector } from 'react-redux';
import { postTrackRequest } from '../../redux/actions/uploadTrackActions';
import '../../styles/Fonts.scss';
import '../../pages/Feedback/feedback.css';
import '../../pages/LoginPage/LoginPage.css';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../assets/Images/closeIcon.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Bars } from 'react-loader-spinner';

const FeedbackStageFour = ({
  onFeedbackClick,
  onCloseTrack,
  audio,
  trackName,
  genreName,
  selectedRoles,
  workStatus,
  syncedBefore,
  samplesUsed,
  sampleSources,
  feedbackOptions,
  targetOptions,
  additionalInfo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  useEffect(() => {
    console.log(sampleSources);
  }, []);
  const trackState = useSelector((state) => state.uploadTrack);
  console.log(trackState);
  useEffect(() => {
    if (trackState.tracks && !trackState.isLoading && !trackState.isError) {
      console.log('Triggering onFeedbackClick'); // Add this log
      onFeedbackClick();
    } else {
      console.log('Condition not met for triggering onFeedbackClick'); // Add this log
    }
  }, [trackState.tracks, trackState.isLoading, trackState.isError]);
  useEffect(() => {
    if (trackState.error) setLoginError(true);
  }, [trackState.error]);

  const handleSubmitTrack = () => {
    dispatch(
      postTrackRequest(
        audio,
        trackName,
        genreName,
        selectedRoles,
        workStatus,
        syncedBefore,
        samplesUsed,
        sampleSources,
        feedbackOptions,
        targetOptions,
        additionalInfo
      )
    );
  };

  return (
    <div className="feedback-container">
      <div className="feedback-card">
        <div className="feedback-close-div">
          <img src={closeIcon} onClick={onCloseTrack} alt="Close" />
        </div>
        <Row className="justify-content-center align-items-center h-100">
          <Col xs={12} md={12}>
            <div className="text-center">
              <div className="feedback-groupby-bottom-margin">
                <SMLLabel
                  value="Submit Track For Evaluation?"
                  style="Mainheader"
                />
              </div>
              <div className="feedback-groupby-middel-btn">
                <SMLButton
                  style="SMLButton2 SMLButton-Orange"
                  value="Submit Track"
                  handleClickEvent={handleSubmitTrack}
                />
              </div>
              {trackState?.isLoading && (
                <div className="spinner-overlay">
                  <Bars
                    height="80"
                    width="80"
                    color="#23f0c7"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
              <div className="feedback-groupby-middel-btn">
                <SMLButton
                  style="SMLButton3"
                  valueStyle="Button2"
                  value="Cancel"
                  handleClickEvent={onCloseTrack}
                />
              </div>
                {/* HOW AARERASFDASDFASDFASDFASDF */}
                { loginError &&(
                  <span className='SMLError'>{trackState.error}</span>
                  )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FeedbackStageFour;
