
import api from "../api";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const getSupervisorApi = async () => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }
    console.log("sml")
    const url = `${BASE_URL}/profiles/supervisors`;
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.get(url, config);
    console.log("Response data", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error in getting supervisors : ",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
