import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../styles/Fonts.scss";
import "../Supervisor-Dashboard/TrackFeedback.css";
import "../../styles/Fonts.scss";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import { Row, Col } from "react-bootstrap";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import ArtistProfile from "../../components/UI/artistProfile/artistProfile";
import AboutTrack from "../../components/UI/aboutTrack/aboutTrack";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTrackRequest } from "../../redux/actions/uploadTrackActions";
import {
  assignSuperVisorRequest,
  getSingleAritstRequest,
} from "../../redux/actions";

import { useWavesurfer } from "@wavesurfer/react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Box, Stack, IconButton } from "@mui/material";
import { Edit, Close, ArrowBack } from "@mui/icons-material";

import TrackPlayer from "../../components/common/track-details/TrackPlayer";
import CommentIcon from "../../assets/Icons/comment.svg";
import CommentGrayIcon from "../../assets/Icons/comment-grey.svg";
import AddNoteIcon from "../../assets/Icons/addNote.svg";
import EnhancedCustomTimePicker from "../../components/Shared/TimeInput/EnhancedCustomTimePicker";

import {
  addCommentApi,
  deleteCommentApi,
  getAllCommentsApi,
  updateCommentApi,
} from "../../api/supervisor/comment";
import { downloadTrackApi } from "../../api/track/downloadTrackApi";

import "../Reviews/AudioGraph.css";
import { Bars } from "react-loader-spinner";
import { formatTime, parseTime } from "../Reviews/TrackUnderReview";
import SupAssignPopup from "./SupAssignPopup";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_GET_FEEDBACK,
  RESET_GET_SINGLE_TRACK,
} from "../../redux/actions/actionTypes";

const TrackFeedback = (props) => {
  const location = useLocation();
  const status = location.state?.status;
  const fromUserPortal = location.state?.fromUserPortal;
  const fromDashboard = location.state?.fromDashboard;
  const isMaster = localStorage.getItem("isMaster");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { trackId } = useParams();
  const track = useSelector((state) => state.uploadTrack?.singleTrack);
  const artistId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.artist_id
  );
  const artist = useSelector((state) => state.singleArtist?.details);
  // const assigned = useSelector(
  //   (state) => state.uploadTrack?.singleTrack?.assigned_supervisor_id
  // );
  const assigned = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor_id ?? null
  );
  const [showArtistProfile, setShowArtistProfile] = useState(false);
  const [showAboutTrack, setShowAboutTrack] = useState(false);
  const [isCompleteEnabled, setIsCompleteEnabled] = useState(false);
  const [isAssignEnabled, setIsAssignEnabled] = useState(true);
  const [buttonText, setButtonText] = useState("Complete Evaluation");
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [selfAssign, setSelfAssign] = useState(false);
  const supId = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );
  const assignedSupId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor_id
  );
  const assignedStatus = useSelector(
    (state) => state.assignSupervisor?.assigned?.status?.code
  );
  const handleAssign = () => {
    // if(isMaster === "true" && supId === )
    setShowAssignPopup(!showAssignPopup);
  };
  const handleStartAssign = () => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    dispatch(assignSuperVisorRequest({ trackId: trackId, supervisor: supId }));
    // setIsCompleteEnabled(true)
    console.log("new status", assignedStatus);
    setSelfAssign(true);
    // setCurrentStatus(assignedStatus)
  };
  useEffect(() => {
    if (
      assignedSupId &&
      // Object.keys(assignedSupId).length > 0 &&
      isMaster === "false" &&
      selfAssign === true
    ) {
      // navigate(`/TrackFeedback/${trackId}`);
      // window.location.reload()
      console.log("assigned", assignedStatus);
      setCurrentStatus(assignedStatus);
      // window.location.reload();
      // if(assignedSupId)
      setIsCompleteEnabled(true);
      // dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    }
  }, [assignedStatus]);
  const handleTrackEvaluation = () => {
    if (
      (buttonText === "View Evaluation" &&
        status === "final_feedback_submitted") ||
      (buttonText === "View Evaluation" &&
        status === "feedback_submitted" &&
        isMaster === "false")
    ) {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluatedPage/${trackId}`, {
        state: { fromUserPortal, status },
      });
    } else if (buttonText === "View Evaluation" && fromUserPortal) {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluated/${trackId}`, {
        state: { fromUserPortal, status },
      });
    } else {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluationPage/${trackId}`, {
        state: { fromUserPortal, status, assignedSupId },
      });
    }
  };

  useEffect(() => {
    // const isAssignedEmpty = assigned.length === ;
    // console.log("Assgined ? ", assigned);
    console.log("a", assignedSupId, user);
    if (assigned && assigned.length > 0) {
      console.log("assigned", assigned);
      if (isMaster === "true" && currentStatus !== "submitted") {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      }
      // setCurrentStatus("in_review");
      else if (isMaster === "false" && currentStatus !== "submitted") {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
        console.log("i");
        // setIsCompleteEnabled(true);
      } else if (
        assignedSupId === supId &&
        isMaster === "true" &&
        currentStatus === "submitted"
      ) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      }
      console.log("sup id", assignedSupId, supId)
      // setButtonText("no");
    }
  }, [assigned]);
  const statusFromTrack = useSelector(
    (state) => state.uploadTrack?.singleTrack?.status?.code
  );
  useEffect(() => {
    // setCurrentStatus(statusFromTrack);
  }, []);
  useEffect(() => {
    // setCurrentStatus(statusFromTrack);
    console.log("shalgam1", isCompleteEnabled, currentStatus);
    // if (status === 'in_review' && fromUserPortal) {
    //   setIsCompleteEnabled(true);
    //   setButtonText('View Evaluation');
    //   } else if (status === 'in_review' && !fromUserPortal) {
    //     setIsCompleteEnabled(true);
    //     setButtonText('Complete Evaluation');
    // }
    if (currentStatus === "final_feedback_submitted") {
      // setIsAssignEnabled(false);
      console.log("shalgam", isCompleteEnabled, currentStatus);
      setIsCompleteEnabled(true);
      setButtonText("View Evaluation");
    } else if (!fromUserPortal && currentStatus === "in_review") {
      if (assignedSupId === supId) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
        console.log("i");
        // setButtonText("")
      }
    } else if (currentStatus === "feedback_submitted") {
      if (isMaster === "false") {
        setButtonText("View Evaluation");
      } else {
        setIsCompleteEnabled(true);
        setButtonText("View Evaluation");
      }
      // setIsAssignEnabled(false);
      // setButtonText("")
    }
    console.log("see", assignedSupId, supId);
  }, [
    currentStatus,
    fromUserPortal,
    isAssignEnabled,
    isCompleteEnabled,
    showAssignPopup,
  ]);

  const handleAboutTrack = () => {
    setShowAboutTrack(true);
  };

  const handleArtistProfile = () => {
    setShowArtistProfile(true);
  };

  const handleCloseArtistProfile = () => {
    setShowArtistProfile(false);
  };

  const handleCloseAboutTrack = () => {
    setShowAboutTrack(false);
  };

  useEffect(() => {
    dispatch(getSingleTrackRequest(trackId));
  }, [dispatch, fromUserPortal, trackId]);

  useEffect(() => {
    if (artistId) {
      dispatch(getSingleAritstRequest(artistId));
    }
  }, [artistId, dispatch]);

  const [trackUrl, setTrackUrl] = useState();
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const wavesurfRef = useRef(null);
  const regions = useMemo(() => RegionsPlugin.create(), []);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: wavesurfRef,
    waveColor: "#CBCBCB",
    progressColor: "#FF4405",
    cursorColor: "#FF4405",
    selectionColor: "#FF4405",
    barWidth: 3,
    url: trackUrl,
    height: 150,
    plugins: useMemo(() => [regions], [regions]),
  });
  async function fetchTrackUrl(trackId) {
    try {
      const { audio_signed_url } = await downloadTrackApi({ trackId });
      setTrackUrl(audio_signed_url); // Set the URL for Wavesurfer
      setIsLoading(false); // Stop showing loader after URL is fetched
    } catch (error) {
      console.error("Error fetching track URL:", error);
      setIsLoading(false); // Stop loader in case of an error
    }
  }

  useEffect(() => {
    fetchTrackUrl(trackId);
  }, [trackId]);

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("ready", () => {
        console.log("Wavesurfer is ready");
        setIsLoading(false); // Hide the loader once Wavesurfer is ready
      });

      wavesurfer.on("error", (error) => {
        if (error.name === "AbortError") {
          console.warn("Audio loading aborted");
        } else {
          console.error("Error loading the audio", error);
        }
        setIsLoading(false); // Stop loader in case of error
      });
    }

    return () => {
      // Ensure Wavesurfer is properly destroyed only if it exists
      if (wavesurfer && wavesurfer.isReady && wavesurfer.destroy) {
        wavesurfer.destroy(); // Cleanup Wavesurfer instance only when ready
      }
    };
  }, [wavesurfer]);

  useEffect(() => {
    // downloadTrackApi({ trackId }).then(async ({ audio_signed_url }) => {
    //   setTrackUrl(audio_signed_url);
    // });
    fetchTrackUrl(trackId);
    getAllCommentsApi(trackId).then((uploadedComments) => {
      setComments(
        uploadedComments.map(
          ({ _id, start_time, end_time, comment }, index) => ({
            id: _id,
            time: start_time === end_time ? start_time : [start_time, end_time],
            transcript: comment,
            idSuffix: `${index}-${start_time}`,
          })
        )
      );
    });
  }, [trackId]);

  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const inputRef = useRef(null);
  const [commentPosition, setCommentPosition] = useState(0);
  const commentBoxPosition = useMemo(() => {
    if (!wavesurfRef.current) return 0;
    const rect = wavesurfRef.current.getBoundingClientRect();
    const x = (commentPosition / wavesurfer.getDuration()) * rect.width;
    return Math.min(x, rect.width - 300);
  }, [commentPosition, wavesurfer, wavesurfRef]);

  const [focusedComment, setFocusedComment] = useState(null);
  const focusedCommentContainer = useRef(null);

  useEffect(() => {
    if (!!wavesurfer && focusedComment !== null) {
      if (typeof comments[focusedComment].time === "number") {
        wavesurfer.setTime(comments[focusedComment].time);
      } else {
        regions.clearRegions();
        regions.addRegion({
          start: comments[focusedComment].time[0],
          end: comments[focusedComment].time[1],
          color: "#ff440540",
          resize: false,
        });
      }
    }
  }, [focusedComment, wavesurfer, regions, comments]);

  const handleClickOutside = (event) => {
    if (
      focusedCommentContainer.current &&
      !focusedCommentContainer.current.contains(event.target)
    ) {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setActivePosition(null);
      setActiveRegion(null);
      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // useEffect(() => {
  //   if (comments.length > 0) setIsCompleteEnabled(true);
  // }, [comments]);

  const [selcetedPart, setSelectedPart] = useState();
  const [activePosition, setActivePosition] = useState();
  const [activeRegion, setActiveRegion] = useState();

  useEffect(() => {
    if (currentStatus !== "final_feedback_submitted" && !!wavesurfer) {
      regions.enableDragSelection({
        color: "#ff440540",
      });

      regions.on("region-created", (newRegion) => {
        const allRegions = regions.getRegions();
        allRegions.forEach((region) => {
          if (newRegion !== region) region.remove();
        });
        // newRegion.play();

        setSelectedPart("region");
        setActiveRegion([newRegion.start, newRegion.end]);
        setCommentPosition(newRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-updated", (updatedRegion) => {
        // updatedRegion.play();

        setSelectedPart("region");
        setActiveRegion([updatedRegion.start, updatedRegion.end]);
        setCommentPosition(updatedRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-clicked", (region, e) => {
        e.stopPropagation();
        region.play();
      });
    }
  }, [wavesurfRef, wavesurfer, regions]);

  useEffect(() => {
    if (!!wavesurfer)
      wavesurfRef.current.addEventListener("click", (event) => {
        if (selcetedPart === "region") regions.clearRegions();
        setSelectedPart("point");
        setActivePosition(wavesurfer.getCurrentTime());
        setCommentPosition(wavesurfer.getCurrentTime());
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });
  }, [
    wavesurfRef,
    wavesurfer,
    regions,
    setSelectedPart,
    setActivePosition,
    selcetedPart,
  ]);

  useEffect(() => {
    if (!showCommentBox) {
      setActivePosition(undefined);
      setActiveRegion(undefined);
      setSelectedPart(undefined);
      setCurrentComment("");
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCommentBox]);

  const handleCommentSubmit = (event) => {
    if (event.key === "Enter" && currentComment.trim()) {
      let newComment;
      setFocusedComment(null);
      if (selcetedPart === "point") {
        newComment = {
          time: activePosition,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
        addCommentApi({
          trackId,
          startTime: activePosition,
          endTime: activePosition,
          comment: currentComment,
        });
      } else if (selcetedPart === "region") {
        newComment = {
          time: activeRegion,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
        addCommentApi({
          trackId,
          startTime: activeRegion[0],
          endTime: activeRegion[1],
          comment: currentComment,
        });
      }

      setComments((prevSentiments) => {
        const updatedSentiments = [...prevSentiments, newComment];
        return updatedSentiments;
      });

      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };
  const reDirectToComment = (timeInSeconds) => {
    if (wavesurfer) {
      wavesurfer.seekTo(timeInSeconds / wavesurfer.getDuration());
      // wavesurfer.play();
    }
  };
  const user = useSelector((state) => state.sml.allArtistFetched?.artists);
  const handleCloseAssignPopup = () => {
    setShowAssignPopup(false);
    // setIsCompleteEnabled(true)
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    dispatch(getSingleTrackRequest(trackId));
    // window.location.reload();
  };
  const handleStatus = () => {
    if (currentStatus === "submitted") {
      setCurrentStatus("in_review");
    }
    // if (assignedSupId === supId) {
    //   console.log("supid", supId, "assigned", assignedSupId);
    //   setIsAssignEnabled(false);
    //   setIsCompleteEnabled(true);
    // } else {
    //   setIsAssignEnabled(true);
    //   setIsCompleteEnabled(false);
    // }
  };
  return (
    <>
      {showAssignPopup && (
        <SupAssignPopup
          trackId={trackId}
          onClose={handleCloseAssignPopup}
          currentStatus={currentStatus}
          handleStatus={handleStatus}
          assignedSupervisor={assigned ? true : false}
        />
      )}
      <div
        className="full-screen-background vh-100 px-3"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {!fromUserPortal && <SUPHeader />}
        <div
          className="padding-0"
          style={{ display: "flex", flexDirection: "column", flex: "1 1" }}
        >
          <div className="back-to-dashboard">
            <IconButton
              sx={
                {
                  // padding: 0,
                  // position: "absolute",
                  // width: 30,
                  // height: 30,
                  // top: 20,
                  // left: -35,
                  // cursor: "pointer",
                }
              }
              onClick={() => {
                dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
                if (fromDashboard) {
                  navigate("/dashboard");
                } else if (!fromUserPortal) {
                  navigate("/supervisorDashboard");
                } else {
                  if (status === "submitted") navigate("/uploadedTrack");
                  else if (status === "in_review") navigate("/Review");
                  else if (status === "final_feedback_submitted")
                    navigate("/FeedbackReceived");
                  else navigate("/dashboard");
                }
              }}
            >
              <ArrowBack style={{ cursor: "pointer" }} />
            </IconButton>
            <SMLLabel value="Back to Dashboard" />
          </div>
          <div
            className="track-main-div"
            style={{
              paddingTop: "15px",
              position: "relative",
              marginLeft: "4rem",
            }}
          >
            <Row>
              <Col
                className="d-flex align-items-end"
                style={{ maxWidth: "111px" }}
              >
                <img
                  src={trackImg}
                  alt="Track"
                  width="111"
                  height="111"
                  className="img-fluid"
                />
              </Col>
              <Col className="d-flex align-items-end">
                <div className="mt-auto">
                  <SMLLabel style="title1 mb-1" value={track?.title} />
                  <SMLOptionQuestion
                    style="SMLSecondaryButton1"
                    type="button"
                    value="About Track"
                    handleClickEvent={handleAboutTrack}
                  />
                  {!fromUserPortal && (
                    <SMLOptionQuestion
                      style="SMLSecondaryButton1"
                      type="button"
                      value="Artist Profile"
                      handleClickEvent={handleArtistProfile}
                    />
                  )}
                </div>
              </Col>
              <Col className="d-flex align-items-end justify-content-end">
                <div className="d-flex mt-auto align-items-end gap-1">
                  {(currentStatus === "submitted" ||
                    currentStatus === "in_review") &&
                    isMaster === "true" && (
                      <SMLButton
                        style={`SMLAssignBtn SMLAssignBtn-disabled ${
                          isAssignEnabled ? "" : "disabled"
                        }`}
                        value={"Assign"}
                        handleClickEvent={handleAssign}
                        // handleDisable={!isCompleteEnabled}
                        // handleDisable={!isAssignEnabled}
                        textColor={"#ff4405"}
                      />
                    )}
                  {currentStatus === "submitted" && isMaster === "false" && (
                    <SMLButton
                      style={`SMLAssignBtn SMLAssignBtn-disabled ${
                        isAssignEnabled ? "" : "disabled"
                      }`}
                      value={"Start Assign"}
                      handleClickEvent={handleStartAssign}
                      // handleDisable={!isCompleteEnabled}
                      // handleDisable={!isAssignEnabled}
                      textColor={"#ff4405"}
                    />
                  )}
                  {(currentStatus === "final_feedback_submitted" ||
                    (!fromUserPortal && currentStatus === "submitted") ||
                    (!fromUserPortal &&
                      currentStatus === "feedback_submitted") ||
                    (!fromUserPortal && currentStatus === "in_review")) && (
                    <SMLButton
                      style={`SMLButton2 SMLButton-Orange ${
                        isCompleteEnabled ? "" : "disabled"
                      }`}
                      value={buttonText}
                      handleClickEvent={handleTrackEvaluation}
                      // handleDisable={!isCompleteEnabled}
                      handleDisable={!isCompleteEnabled}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="track-main-div"
            style={{ flex: "1 1", paddingTop: "20px" }}
          >
            {track && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "100%",
                      overflow: "hidden",
                      paddingTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginLeft: "5rem",
                    }}
                  >
                    <div style={{ position: "relative", height: "30px" }}>
                      {!fromUserPortal &&
                        (showCommentBox || showAddCommentButton) &&
                        focusedComment === null &&
                        (selcetedPart === "point" ? (
                          <div
                            className="time-box"
                            style={{ left: commentBoxPosition }}
                          >
                            <EnhancedCustomTimePicker
                              time={formatTime(commentPosition)}
                              setTime={(timeString) => {
                                const newPoint = parseTime(timeString);
                                setCommentPosition(newPoint);
                                if (!!wavesurfer) wavesurfer.setTime(newPoint);
                              }}
                            />
                          </div>
                        ) : selcetedPart === "region" ? (
                          <div
                            className="time-box"
                            style={{ left: commentBoxPosition }}
                          >
                            <EnhancedCustomTimePicker
                              time={formatTime(activeRegion[0])}
                              setTime={(timeString) => {
                                const newPoint = parseTime(timeString);
                                setActiveRegion([newPoint, activeRegion[1]]);
                                const allRegions = regions.getRegions();
                                if (allRegions.length > 0) {
                                  allRegions[0].setOptions({
                                    start: newPoint,
                                  });
                                  setCommentPosition(newPoint);
                                }
                              }}
                            />{" "}
                            <EnhancedCustomTimePicker
                              time={formatTime(activeRegion[1])}
                              setTime={(timeString) => {
                                const newPoint = parseTime(timeString);
                                setActiveRegion([activeRegion[0], newPoint]);
                                const allRegions = regions.getRegions();
                                if (allRegions.length > 0) {
                                  allRegions[0].setOptions({
                                    end: newPoint,
                                  });
                                }
                              }}
                            />
                          </div>
                        ) : null)}
                      {wavesurfer &&
                        comments.map((comment, index) => (
                          <div
                            onClick={() => {
                              document
                                .getElementById(
                                  `transcript-${comment.idSuffix}`
                                )
                                .scrollIntoView({ behavior: "smooth" });
                              setFocusedComment(index);
                              regions.clearRegions();
                            }}
                            key={`comment-${comment.idSuffix}`}
                            style={{
                              position: "absolute",
                              left: `${
                                ((typeof comment.time === "object"
                                  ? comment.time[0]
                                  : comment.time) /
                                  wavesurfer.getDuration()) *
                                100
                              }%`,
                              top: "3px",
                              height: "24px",
                              width: " 24px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                focusedComment === index
                                  ? CommentIcon
                                  : CommentGrayIcon
                              }
                              alt="Comment"
                            />
                          </div>
                        ))}
                    </div>

                    <div id="waveform" ref={wavesurfRef}></div>

                    <div style={{ position: "relative", height: "20px" }}>
                      {showCommentBox && focusedComment === null && (
                        <div
                          className="comment-box"
                          style={{ left: commentBoxPosition }}
                        >
                          <div className="comment-header">
                            <span className="timestamp">
                              <div style={{ height: "24px", width: " 24px" }}>
                                <img src={CommentIcon} alt="Comment" />
                              </div>
                              {selcetedPart === "point"
                                ? `${formatTime(activePosition)}`
                                : selcetedPart === "region"
                                ? `${formatTime(
                                    activeRegion[0]
                                  )} - ${formatTime(activeRegion[1])}`
                                : ""}
                            </span>
                            {currentStatus !== "final_feedback_submitted" && (
                              <IconButton
                                sx={{ padding: 0.7 }}
                                onClick={() => {
                                  if (selcetedPart === "region")
                                    regions.clearRegions();
                                  setShowCommentBox(false);
                                  setShowAddCommentButton(false);
                                }}
                              >
                                <Close sx={{ height: 20, width: 20 }} />
                              </IconButton>
                            )}
                          </div>
                          <label>{user?.name}</label>
                          <textarea
                            ref={inputRef}
                            type="text"
                            value={currentComment}
                            onChange={(e) => setCurrentComment(e.target.value)}
                            onKeyPress={handleCommentSubmit}
                            placeholder="Type your note..."
                          />
                        </div>
                      )}
                      {currentStatus !== "final_feedback_submitted" &&
                        showAddCommentButton &&
                        focusedComment === null && (
                          <div
                            className="add-note"
                            style={{ left: commentBoxPosition - 8 }}
                            onClick={() => {
                              setShowAddCommentButton(false);
                              setShowCommentBox(true);
                            }}
                          >
                            <img src={AddNoteIcon} alt="Comment" width={20} />
                            Add Note
                          </div>
                        )}
                    </div>
                    <div id="descriptions" style={{ paddingTop: "30px" }}>
                      {comments.map((comment, index) =>
                        focusedComment !== index ? (
                          <div
                            id={`transcript-${comment.idSuffix}`}
                            key={`transcript-${comment.idSuffix}`}
                            className="input-track-note"
                            style={{
                              opacity: focusedComment !== null ? 0.25 : 1,
                            }}
                            onClick={() =>
                              reDirectToComment(
                                typeof comment.time === "number"
                                  ? comment.time
                                  : comment.time[0]
                              )
                            }
                          >
                            <div className="comment-header">
                              <span className="timestamp">
                                <div style={{ height: "24px", width: " 24px" }}>
                                  <img src={CommentIcon} alt="Comment" />
                                </div>
                                {typeof comment.time === "object"
                                  ? `${formatTime(
                                      comment.time[0]
                                    )} - ${formatTime(comment.time[1])}`
                                  : `${formatTime(comment.time)}`}
                              </span>
                              {currentStatus !== "final_feedback_submitted" && (
                                <div>
                                  <IconButton
                                    sx={{ padding: 1 }}
                                    onClick={() => setFocusedComment(index)}
                                  >
                                    <Edit
                                      sx={{ width: "16px", height: "16px" }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    sx={{ padding: 0.7 }}
                                    onClick={() => {
                                      if (comment.id)
                                        deleteCommentApi(comment.id);
                                      setComments([
                                        ...comments.slice(0, index),
                                        ...comments.slice(index + 1),
                                      ]);
                                    }}
                                  >
                                    <Close sx={{ width: 20, height: 20 }} />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                            <div className="content">
                              <div className="author">{user?.name}</div>
                              <div className="comment-content">
                                {comment.transcript}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            ref={focusedCommentContainer}
                            id={`transcript-${comment.idSuffix}`}
                            key={`transcript-${comment.idSuffix}`}
                            className="input-track-note"
                          >
                            <div className="comment-header">
                              <span className="timestamp">
                                <div style={{ height: "24px", width: " 24px" }}>
                                  <img src={CommentIcon} alt="Comment" />
                                </div>
                                {typeof comment.time === "object"
                                  ? `${formatTime(
                                      comment.time[0]
                                    )} - ${formatTime(comment.time[1])}`
                                  : `${formatTime(comment.time)}`}
                              </span>
                              <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => {
                                  regions.clearRegions();
                                  setFocusedComment(null);
                                }}
                              >
                                <Close sx={{ height: 20, width: 20 }} />
                              </IconButton>
                            </div>
                            <label>{user?.name}</label>
                            <textarea
                              ref={inputRef}
                              type="text"
                              value={comment.transcript}
                              onChange={(e) => {
                                setComments([
                                  ...comments.slice(0, index),
                                  {
                                    ...comment,
                                    transcript: e.target.value,
                                  },
                                  ...comments.slice(index + 1),
                                ]);
                              }}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  setActiveRegion(null);
                                  setFocusedComment(null);
                                  updateCommentApi({
                                    commentId: comment.id,
                                    comment: comment.transcript,
                                  });
                                }
                              }}
                              placeholder="Type your note..."
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Stack>
                <Stack
                  sx={{
                    margin: "0 -80px",
                  }}
                >
                  <TrackPlayer
                    track={track}
                    isPlaying={isPlaying}
                    wavesurfer={wavesurfer}
                  />
                </Stack>
              </Box>
            )}
            {(!wavesurfer || wavesurfer.getDuration() === 0) && (
              <div className="spinner-overlay">
                <Bars
                  height="80"
                  width="80"
                  color="#23f0c7"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>

        {showArtistProfile && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <ArtistProfile
                onArtistProfileClose={handleCloseArtistProfile}
                artist={artist}
              />
            </div>
          </div>
        )}

        {showAboutTrack && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <AboutTrack
                onAboutTrackClose={handleCloseAboutTrack}
                track={track}
                fromArtistPortal={false}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TrackFeedback;
