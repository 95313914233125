// import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import api from './api'
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev"; // Use the proxy base URL

// Function to fetch artist data
export const getArtist = async () => {
  try {
    // Retrieve the access token from cookies
    // const accessToken = Cookies.get("token");
    const accessToken = localStorage.getItem("token");
    // const accessToken =
    //   'eyJraWQiOiJ6QmNLWWkzVkNWQzF0cGpEWEF3Y2tnaHFjTzZiOTRZQVlBNnplMjB1QnpZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJiOGMxZjNiMC05MDIxLTcwY2QtMzI5OC05NDFhNWU3NzJhNmMiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9uQ3l1bmo1MTAiLCJjbGllbnRfaWQiOiJqdGRxZDY4bjdwcWY4ZTh0Z2g1aGtndWw3Iiwib3JpZ2luX2p0aSI6ImIzOTI2NDQyLWE3NzgtNDNkMi05MDM2LWE3Y2RiMTJlNThiNSIsImN1c3RvbTphcnRpc3RfaWQiOiI2NmJkYTI5NzRhNjc4MDRjNDg3MjE2MDAiLCJldmVudF9pZCI6ImJjNDAwNWNmLTJmMDgtNDZmMS05ZjViLWQ5NjQ3MjBjNjBhNCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MjM4MjI3NTYsImV4cCI6MTcyMzgyNjM1NiwiaWF0IjoxNzIzODIyNzU2LCJqdGkiOiI4Y2U5MGRjOC04NTIwLTRkZDktYTI4Mi1hYmQ3OWE2MjdkMjkiLCJ1c2VybmFtZSI6ImI4YzFmM2IwLTkwMjEtNzBjZC0zMjk4LTk0MWE1ZTc3MmE2YyJ9.yhzHqrwZZ1qxToTLU1Rg0dxit5F2oIYjg-Paun_-6VzN3l1vjd7usOGToVzczgAs4XrYWs2HF-CUkTRSURcmwdT2Oa2r0MYe2S31IoFoqX1BE03VPbfKWiMnIM2mOz-e5Td9KcnyxgSFY9YHk_uUby6KpJa_cIPhal7voxgexNeIUAMXgmwAmAck9E8ruqw9B5w0OIu1f2GIvg5pCJ7A2bF0u3WJxj7Ax7c1YDi09c9IIy8E3F9NUAiZYZd-Hf4KHuNs27U2H_lAHUaf9U47c_FS4Y3pH75JWLWuMSiI3CMdTm3kej_dJuOZlEVngNAJqkYtRdqqeBoSwf_MGSiqaQ'
    // const token = Cookies.get('token')
    const token = localStorage.getItem("token");
    console.log(token);
    const decodedToken = jwtDecode(token);

    const artistId = decodedToken["custom:artist_id"];
    // Define the API endpoint for fetching artists
    const url = `https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev/profiles/me`;

    // Log the URL and access token for debugging
    console.log("Request URL:", url);
    console.log("Access Token:", accessToken);

    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const response = await api.get(url, config);

    console.log("Response Data:", response.data); // Log the artist's data
    console.log(response?.data?.music_experience_info);
    return response?.data; // Return artist's data
  } catch (error) {
    // Log and throw error
    console.error(
      "Error fetching artists:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
