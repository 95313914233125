import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import '../LoginPage/LoginTest.css';
import "../../styles/Fonts.scss";
import SMLButton from '../../components/Shared/SMLButton/SMLButton';
import SMLButtonWithIcon from '../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon';



const LoginTest = () => {
    return (
        <div className='main-container'>
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100dvh' }}>
                <div style={{ width: '588px', height: '688px' }}>
                    <Container className="d-flex flex-column justify-content-center align-items-center h-100 Login-container">
                        <h1 className="mb-4 Mainheader">Log In</h1>
                        <div className="w-100 ">
                            <div className='form-margin'>
                                <Form >
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
                                        <Form.Label className='title2'>Email</Form.Label>
                                        <Form.Control className="SMLTextbox"  type="email"  />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                        <Form.Label className='title2'>Password</Form.Label>
                                        <Form.Control className="SMLTextbox"  type="password"  />
                                    </Form.Group>
                                    {/* <Button className="SMLButton1" variant="primary" type="submit">
                                Log In
                                </Button> */}
                                    <SMLButton style="SMLButton1" value="Log In" />
                                </Form>
                                <Row>
                                    <Col>
                                        <SMLButtonWithIcon type="button" value="Sign up with Google" icon="Google" />
                                    </Col>
                                    <Col>
                                        <SMLButtonWithIcon type="button" value="Sign up with Facebook" icon="Facebook" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default LoginTest;
