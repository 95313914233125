import React, { useEffect, useState } from "react";
import "./SupHeader.css";
import { Container, Row, Col } from "react-bootstrap";
import SMlLogo from "../../../assets/Images/SMLLogo.svg";
import ArrowDown from "../../../assets/Images/Arrow-Down.svg";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import dropdDown from "../../../assets/Images/dropDown.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllArtistFetch } from "../../../redux/actions/smlActions";
import ProfileDropdown from "../../common/header/ProfileDropdown";

const SUPHeader = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllArtistFetch());
  }, [dispatch]);

  const user = useSelector((state) => state.sml.allArtistFetched?.artists);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    navigate("/");
  };

  const handleDashboard = () => {
    navigate("/supervisorDashboard");
  };

  return (
    <div className="sup-header-div">
      <Row className="py-4">
        <Col>
          <img className="headerLogo" src={SMlLogo} alt="Image" />
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <div className="label-container d-flex" style={{ gap: "20px" }}>
            <div
              className={
                "col d-flex align-items-center justify-content-end cursor-pointer dashboard-link "
              }
              onClick={handleDashboard}
            >
              <SMLLabel
                style="Button6
              "
                value="Dashboard"
              />
            </div>
            <div className="profile-btn">
              <ProfileDropdown name={user?.name} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SUPHeader;
