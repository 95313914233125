
// import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode'; // Corrected import
import api from "../api"
const BASE_URL = 'https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev';

export const searchApi = async (query, status) => {
  try {
    console.log('query:', query);

    // Retrieve the access token from cookies
    // const accessToken = Cookies.get('token');
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      console.error('No token found. Please log in.');
      return;
    }

    // Decode the token to get artistId (if needed)
    const decodedToken = jwtDecode(accessToken);

    // Prepare the data to be sent as JSON
    const data = {
      title: query,
      status: status,
    };

    console.log('AccessToken:', accessToken); // Log token for debugging

    const url = `${BASE_URL}/tracks/search`;

    const config = {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    console.log('Config:', config); // Log the config to inspect it

    // Send the POST request with JSON data
    const response = await api.post(url, data, config);

    console.log('Response Data:', response.data);

    if (response.status !== 200) {
      console.error(
        'Failed to search the tracks. Status code:',
        response.status
      );
    }
    if (Array.isArray(response.data) && response.data.length === 0) {
      return null;
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request data:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    console.error('Error config:', error.config);
    throw error;
  }
};
